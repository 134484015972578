var AdbannerComponent = /** @class */ (function () {
    function AdbannerComponent() {
    }
    Object.defineProperty(AdbannerComponent.prototype, "hostClass", {
        get: function () {
            return 'jf-' + this.format;
        },
        enumerable: true,
        configurable: true
    });
    return AdbannerComponent;
}());
export { AdbannerComponent };
