
<div class="jf-overlay"></div>

<ng-container *ngIf="step.value !== 2">
  <div class="jf-video jf-static" *ngIf="data?.image" [ngStyle]="{'background-image': 'url(/assets/mood/' + data?.image + ')'}"></div>
  <div class="jf-video" *ngIf="data?.video">
    <video
      #video
      autoplay
      playsinline
      (waiting)="videoState = 'waiting'"
      (ended)="videoState = 'ended'"
      (playing)="videoState = 'playing'"
      (pause)="videoState = 'pause'"
      (click)="toggleVideo()"
    >
      <source src="/assets/mood/{{ data?.video }}" type="video/mp4">
    </video>
    <div class="jf-replay" *ngIf="videoState === 'ended'" (click)="play()">
      <mat-icon>replay</mat-icon>
    </div>
    <div class="jf-play" *ngIf="videoState === 'pause'" (click)="play()">
      <mat-icon>play_arrow</mat-icon>
    </div>
    <div class="jf-loading" *ngIf="videoState === 'waiting'"></div>
  </div>
</ng-container>

<input *ngIf="step.value === 1" type="file" #input accept="video/*" (change)="check()">

<div class="jf-video" *ngIf="step.value === 2">
  <video
    #video
    autoplay
    playsinline
    (waiting)="videoState = 'waiting'"
    (ended)="videoState = 'ended'"
    (playing)="videoState = 'playing'"
    (pause)="videoState = 'pause'"
    (click)="toggleVideo()"
  >
    <source src="{{ video_url}}" type="video/mp4">
  </video>
  <div class="jf-replay" *ngIf="videoState === 'ended'" (click)="play()">
    <mat-icon>replay</mat-icon>
  </div>
  <div class="jf-play" *ngIf="videoState === 'pause'" (click)="play()">
    <mat-icon>play_arrow</mat-icon>
  </div>
  <div class="jf-loading" *ngIf="videoState === 'waiting'"></div>
</div>

<jf-slidenav [loading]="uploading" [data]="navData" (prev)="back()" (next)="forward()"></jf-slidenav>
