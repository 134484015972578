import { Component, ChangeDetectorRef, OnDestroy, ViewChild, AfterViewInit, ElementRef, Input, Output, EventEmitter } from '@angular/core'
import { NgForm } from '@angular/forms'
import { OrderService } from 'app/service/order.service'
import { environment } from 'environments/environment'
import { forEach } from '@angular/router/src/utils/collection'
import { HeaderService } from 'app/service/header.service'

@Component({
  selector: 'jf-cc-payment',
  templateUrl: './cc-payment.component.html',
  styleUrls: ['./cc-payment.component.sass']
})
export class CcPaymentComponent implements AfterViewInit, OnDestroy {
  @ViewChild('cardNumber') cardNumber: ElementRef
  @ViewChild('cardExpiry') cardExpiry: ElementRef
  @ViewChild('cardCvc') cardCvc: ElementRef
  @ViewChild('cardZip') cardZip: ElementRef
  @Input() data
  @Input() client_id: string
  @Output() paid: EventEmitter<boolean> = new EventEmitter<boolean>(true)

  cardHandler = this.onChange.bind(this)
  stripe: any
  error: string
  fieldCardNumber: any
  fieldCardExpiry: any
  fieldCardCvc: any
  fieldCardZip: any
  terms: any
  loading: any

  fieldValidation: any = {
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    postalCode: false,
  }

  constructor (
    private cd: ChangeDetectorRef,
    private orderService: OrderService,
    private headerService: HeaderService,
  ) {
    // @ts-ignore
    this.stripe = Stripe(environment.stripe_token)
  }

  ngAfterViewInit() {
    const that = this
    const elements = this.stripe.elements()

    const elementClasses = {
      focus: 'jf-focused',
      empty: 'jf-empty',
      invalid: 'jf-invalid',
    }

    const style = {
      base: {
        lineHeight: '30px',
        backgroundColor: '#efefef',
        color: '#181818',
        fontSize: '16px',
      }
    }

    that.fieldCardNumber = elements.create('cardNumber', {
      style: style,
      classes: elementClasses,
    })
    that.fieldCardNumber.mount(this.cardNumber.nativeElement)
    that.fieldCardNumber.on('change', data => {
      that.fieldValidation.cardNumber = data.complete
    })

    that.fieldCardExpiry = elements.create('cardExpiry', {
      style: style,
      classes: elementClasses,
    })
    that.fieldCardExpiry.mount(this.cardExpiry.nativeElement)
    that.fieldCardExpiry.on('change', data => {
      that.fieldValidation.cardExpiry = data.complete
    })

    that.fieldCardCvc = elements.create('cardCvc', {
      style: style,
      classes: elementClasses,
    })
    that.fieldCardCvc.mount(this.cardCvc.nativeElement)
    that.fieldCardCvc.on('change', data => {
      that.fieldValidation.cardCvc = data.complete
    })

    that.fieldCardZip = elements.create('postalCode', {
      style: style,
      classes: elementClasses,
    })
    that.fieldCardZip.mount(this.cardZip.nativeElement)
    that.fieldCardZip.on('change', data => {
      that.fieldValidation.postalCode = data.complete
    })
  }

  ngOnDestroy() {
    // this.card.removeEventListener('change', this.cardHandler)
    this.fieldCardNumber.destroy()
    this.fieldCardExpiry.destroy()
    this.fieldCardCvc.destroy()
    this.fieldCardZip.destroy()
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message
    } else {
      this.error = null
    }
    this.cd.detectChanges()
  }

  showTerms () {
    this.headerService.setShow(true)
    this.headerService.setTab('terms')
  }

  canSubmit (): boolean {
    if (!this.terms) {return false}

    let valid = true
    Object.keys(this.fieldValidation).forEach(key => {
      valid = valid && this.fieldValidation[key]
    })

    return valid
  }

  async onSubmit () {
    if (!this.canSubmit()) {return}
    const that = this
    that.loading = true

    const {token, error} = await that.stripe.createToken(that.fieldCardNumber, that.data.payment)

    if (error) {
      console.log('Something is wrong:', error)
      that.loading = false
    } else {
      that.orderService.purchase('cc', {
        id: token.id,
        payer: that.data.payer,
      }, that.client_id)
        .subscribe(() => {
          that.paid.emit()
        }, err => {
          console.log('Something is wrong (api):', err)
          that.error = err.error.error
          that.loading = false
        })
    }
  }
}
