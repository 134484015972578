
<div class="jf-inputframe" [ngClass]="{'jf-length-limit': maxLength}">
	<input
    *ngIf="type !== 'textarea'"
		#input
    [type]="type"
    [ngClass]="{
			'jf-invalid': !control?.valid,
			'jf-touched': control?.touched,
			'jf-show-errors': showErrors,
			'jf-lowercase': lowercase
		}"
		[formControl]="control"
		placeholder="{{ placeholder }}"
	/>
	<textarea
    *ngIf="type === 'textarea'"
		#textarea
    [ngClass]="{
			'jf-invalid': !control?.valid,
			'jf-touched': control?.touched,
			'jf-show-errors': showErrors,
			'jf-lowercase': lowercase
		}"
		[formControl]="control"
		placeholder="{{ placeholder }}"
  ></textarea>
	<div class="jf-maxlength" [ngClass]="{'jf-show': maxLength - inputLength < 11, 'jf-invalid': maxLength < inputLength}" *ngIf="maxLength">
		<span class="jf-current">{{ inputLength }}</span> <span class="jf-limit"> / {{ maxLength }}</span>
	</div>
</div>

<!--
<div class="jf-errors">
	<ng-container *ngIf="control?.errors">
		<ng-container *ngFor="let error of keys(control?.errors)">
			<p *ngIf="control?.errors[error].message">
				{{ control?.errors[error].message }}
			</p>
		</ng-container>
	</ng-container>
</div>
-->
