import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { animate, animateChild, style, transition, trigger } from '@angular/animations'

@Component({
  selector: 'jf-client-contacts',
  templateUrl: './client-contacts.component.html',
  styleUrls: ['./client-contacts.component.sass'],
  animations: [
    trigger('parent', [
      transition(':leave', [
        animateChild(),
      ]),
    ]),
    trigger('show', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px) scale(.98)' }),
        animate('.25s', style({ opacity: 1, transform: 'translateY(0) scale(1)' })),
      ]),
      transition(':leave', [
        animate('.3s', style({ opacity: 0, transform: 'translateY(30px) scale(.98)' }))
      ])
    ]),
  ]
})
export class ClientContactsComponent implements OnInit, OnDestroy {
  @HostBinding('@parent')
  @Output() close: EventEmitter<void> = new EventEmitter<void>()
  @Input() contacts: any

  constructor () {}

  ngOnInit () {

  }

  ngOnDestroy () {

  }
}
