
import { Injectable, Inject } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { WINDOW } from 'app/service/window.service'

@Injectable()
export class ScrollToService {
  constructor (
    @Inject(WINDOW) public window
  ) {}

  public scrollTo(element: string | HTMLElement, duration: number = 500, offset: number = 0): Observable<any> {
    const subject: Subject<any> = new Subject<any>()
    if (typeof element === 'string') {
      const el = document.querySelector(element as string)
      this.scrollToElement(el as HTMLElement, duration, offset, subject)
    } else if (element instanceof HTMLElement) {
      this.scrollToElement(element, duration, offset, subject)
    } else {
      subject.error('I don\'t find element')
    }
    return subject
  }

  private scrollToElement(el: HTMLElement, duration: number, offset: number, subject) {
    if (el) {
      const viewportOffset = el.getBoundingClientRect()
      const offsetTop = viewportOffset.top + window.pageYOffset
      this.doScrolling(offsetTop + offset, duration, subject)
    } else {
      subject.error('I don\'t find element')
    }
    return subject
  }

  private doScrolling(elementY, duration, subject: Subject<any>) {
    const that = this
    const startingY = that.window.pageYOffset
    const diff = elementY - startingY
    let start

    that.window.requestAnimationFrame(function step(timestamp) {
      start = (!start) ? timestamp : start

      const time = timestamp - start
      const percent = Math.min(time / duration, 1)

      that.window.scrollTo(0, startingY + diff * percent)

      if (time < duration) {
        that.window.requestAnimationFrame(step)
        subject.next({})
      } else {
        subject.complete()
      }
    })
  }
}
