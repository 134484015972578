
import { Injectable, Inject } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { WINDOW } from 'app/service/window.service'
import { OrderInterface } from 'app/api/interfaces/order.interface'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'

@Injectable()
export class OrderService {
  private client_id: string
  private inited = new BehaviorSubject<boolean>(false)
  private formContent: FormGroup = new FormGroup({
    // logoselect
    'company_logo': new FormControl('', [Validators.required]),

    // jobdetails
    'job_position': new FormControl('', [Validators.required]),
    'job_sector': new FormControl('', [Validators.required]),
    'job_description': new FormControl('', [Validators.required]),

    // selfdesc
    'job_benefits_1': new FormControl('', [Validators.required]),
    'job_benefits_2': new FormControl('', [Validators.required]),
    'job_benefits_3': new FormControl('', [Validators.required]),
    'wanted_attributes_1': new FormControl('', [Validators.required]),
    'wanted_attributes_2': new FormControl('', [Validators.required]),
    'wanted_attributes_3': new FormControl('', [Validators.required]),
    'company_name': new FormControl('', [Validators.required]),

    // contactdetails
    'company_person': new FormControl('', [Validators.required]),
    'company_mail': new FormControl('', [Validators.required]),
    'company_phone': new FormControl('', [Validators.required]),
    'company_website': new FormControl('', [Validators.required]),

    // designselect
    'design_id': new FormControl('', [Validators.required]),

    // legalchecks
    'anonymous_statistics': new FormControl(true, []),

    'recipient_name': new FormControl('', [Validators.required]),
    'recipient_mail': new FormControl('', [Validators.required]),
    'video_who_are_you': new FormControl('', [Validators.required]),
    'video_which_position': new FormControl('', [Validators.required]),
    'video_intro': new FormControl('', [Validators.required]),
    'video_requirements': new FormControl('', [Validators.required]),
    'video_benefits': new FormControl('', [Validators.required]),
    'video_contacts': new FormControl('', [Validators.required]),
    'video_look_inside': new FormControl('', [Validators.required]),
    'render_status': new FormControl('', [Validators.required]),
    'payment_status': new FormControl('', [Validators.required]),
    'client_id': new FormControl('', [Validators.required]),
  })

  constructor (
    @Inject(WINDOW) public window,
    private router: Router,
    private orderInterface: OrderInterface,
  ) {}

  getBootState () {
    return this.inited
  }

  private getStepId (id) {
    let index = 0

    const that = this
    const steps = that.router.config[0].children
    steps.forEach((step, n) => {
      if (step.data && step.data.step_key === id) {
        index = n
      }
    })

    return index
  }

  submitStep (key: string, data: any): Promise<any> {
    const that = this
    return new Promise((resolve, reject) => {
      that.orderInterface.saveStep({
        client_id: that.client_id,
        index: that.getStepId(key),
        step: {
          id: key,
          data: data,
        },
      }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  getClientID () {
    return this.client_id
  }

  removeImage (step: string) {
    const that = this
    return new Promise((resolve, reject) => {
      const data = {
        step_id: step,
        client_id: that.client_id
      }

      that.orderInterface.removeImage(data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  submitImage (key: string, file: any): Promise<any> {
    const that = this
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('image', file, 'image.' + file.name.split('.').pop())
      data.append('step_id', key)
      data.append('client_id', that.client_id)
      data.append('index', that.getStepId(key) + '')

      that.orderInterface.saveImage(data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  submitVideo (key: string, file): Promise<any> {
    const that = this

    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('video', file, 'video.' + file.name.split('.').pop())
      data.append('step_id', key)
      data.append('client_id', that.client_id)
      data.append('index', that.getStepId(key) + '')

      that.orderInterface.saveVideo(data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  getFormGroup () {
    return this.formContent
  }

  initVideoCreation () {
    const that = this
    that.inited.next(false)

    try {
      that.client_id = localStorage.getItem('client_id')
    } catch (e) {
      that.client_id = ''
    }

    const flatten = (obj, parent, res = {}) => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === 'object') {
            flatten(obj[key], key, res)
          } else {
            res[key] = obj[key]
          }
        }
      }
      return res
    }

    let client_id = ''
    try {
      client_id = localStorage.getItem('increation')
    } catch (e) {}

    that.orderInterface.initClient(that.client_id, Boolean(client_id))
      .subscribe(data => {
        that.formContent.patchValue(flatten(data, undefined))
        that.inited.next(true)
        that.client_id = data.client.id

        try {
          localStorage.setItem('client_id', that.client_id)
        } catch (e) {}

        if (data.order.step) {
          const step = that.router.config[0].children[data.order.step]
          that.router.navigate(['/erstellen/' + step.path])
        } else {
          that.router.navigate(['/erstellen'])
        }
      })
  }

  purchase (type: string, data: any, client_id: string) {
    const that = this

    let request
    const reqData = {
      type: type,
      amount: 2490,
      stripe_token: data.id,
      client_id: client_id,
      payer: data.payer,
    }

    switch (type) {
      case 'cc':
        request = that.orderInterface.placeOrder(reqData)
        break
      case 'iban':
        reqData['client_email'] = data.email
        reqData['client_name'] = data.name
        request = that.orderInterface.placeOrder(reqData)
        break
    }

    return request
  }
}
