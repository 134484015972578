
<div class="jf-overlay"></div>

<div class="jf-video">
  <video
    #video
    autoplay
    playsinline
    (waiting)="videoState = 'waiting'"
    (ended)="videoState = 'ended'"
    (playing)="videoState = 'playing'"
    (pause)="videoState = 'pause'"
    (click)="toggleVideo()"
    poster="/assets/mood/intro-cover.jpg"
  >
    <source src="/assets/mood/intro.mp4" type="video/mp4">
  </video>
  <div class="jf-replay" *ngIf="videoState === 'ended'" (click)="play()">
    <mat-icon>replay</mat-icon>
  </div>
  <div class="jf-play" *ngIf="videoState === 'pause'" (click)="play()">
    <mat-icon>play_arrow</mat-icon>
  </div>
  <div class="jf-loading" *ngIf="videoState === 'waiting'"></div>
</div>

<jf-slidenav [data]="navData" (prev)="back()" (next)="forward()"></jf-slidenav>
