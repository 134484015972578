
<div class="jf-content" [formGroup]="formData">
  <h2>
    Eigenschaften<br>
    <small>Stichpunkte!</small>
  </h2>

  <label>Gründe bei Euch zu arbeiten?</label>
  <jf-input type="text" [showErrors]="showErrors" [control]="formData.get('job_benefits_1')" [maxLength]="20" [validators]="validators.job_benefits_1" placeholder="z.B. super Kollegen"></jf-input>
  <jf-input type="text" [showErrors]="showErrors" [control]="formData.get('job_benefits_2')" [maxLength]="20" [validators]="validators.job_benefits_2" placeholder="z.B. 37,5 Stunden/Woche"></jf-input>
  <jf-input type="text" [showErrors]="showErrors" [control]="formData.get('job_benefits_3')" [maxLength]="20" [validators]="validators.job_benefits_3" placeholder="..."></jf-input>

  <label>Eigenschaften des Bewerbers</label>
  <jf-input type="text" [showErrors]="showErrors" [control]="formData.get('wanted_attributes_1')" [maxLength]="20" [validators]="validators.wanted_attributes_1" placeholder="z.B. leistungsorientiert"></jf-input>
  <jf-input type="text" [showErrors]="showErrors" [control]="formData.get('wanted_attributes_2')" [maxLength]="20" [validators]="validators.wanted_attributes_2" placeholder="z.B. selbständig"></jf-input>
  <jf-input type="text" [showErrors]="showErrors" [control]="formData.get('wanted_attributes_3')" [maxLength]="20" [validators]="validators.wanted_attributes_3" placeholder="z.B. teamfähig"></jf-input>

  <label>Wie heißt dein Unternehmen?</label>
  <jf-input type="text" [showErrors]="showErrors" [control]="formData.get('company_name')" placeholder="Name" [maxLength]="20" [validators]="validators.company_name"></jf-input>
</div>

<jf-slidenav [loading]="saving" [disableNext]="!isValid()" [data]="navData" (prev)="back()" (next)="forward()"></jf-slidenav>
