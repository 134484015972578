
<div class="jf-content">
  <div>
    <img src="/assets/icon/almost-done.svg" alt="Fast geschafft!">

    <h2>
      Du bist fast fertig!
    </h2>

    <p>
      Jetzt gib der/dem „Neuen“ schon mal einen kleien Einblick in dein Unternehmen.
    </p>
  </div>
</div>

<jf-slidenav [data]="navData" (prev)="back()" (next)="forward()"></jf-slidenav>
