
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { EditorComponent } from 'app/page/editor/editor.component'
import { NotfoundComponent } from 'app/page/notfound/notfound.component'
import { PublishedvideoComponent } from 'app/element/slide/publishedvideo/publishedvideo.component'
import { steps } from 'app/static/steps'
import { ViewresultComponent } from 'app/element/slide/viewresult/viewresult.component'
import { IndexComponent } from 'app/page/index/index.component'

const routes: Routes = [
  { path: 'erstellen',
    component: EditorComponent,
    children: steps
  }, {
    path: '',
    component: IndexComponent,
    data: {
      meta: {
        title: 'Finde die besten Mitarbeiterinnen und Mitarbeitern für dein Unternehmen!',
        desc: 'Der Arbeitsmarkt ist in stetigem Wandel und es wird für Unternehmen immer schwieriger gute Talente zu finden. ' +
          'Dadurch ändert sich auch die Richtung des Recruitments!',
      },
    },
  }, {
    path: 'ansehen/:id',
    component: PublishedvideoComponent,
    data: {
      meta: {
        title: 'Kostenloses JOBFLASH-Video auf Social Media oder Webseite teilen',
        desc: 'Fertiges Recruitment Video kostenlos teilen. Keine Fremdkosten. Reichweite nutzen und Stellenausschreibung bekannt machen.'
      }
    },
  }, {
    path: 'mein-video/:id/:key',
    component: ViewresultComponent,
    data: {
      meta: {
        title: 'Schnell & einfach eigenes Recruitment Video erstellen - JOBFLASH mit Tutorials',
        desc: 'Mit Step-by-step-Begleitung in nur wenigen Schritten zum eigenen, individuellen Recruitment Video.'
      },
    },
  }, {
    path: '**',
    component: NotfoundComponent,
    data: {
      meta: {
        title: 'Seite nicht gefunden',
        desc: 'Es liegt wirklich nicht an dir, wir sind das Problem. Aber vielleicht können wir trotzdem Freunde bleiben?',
      }
    },
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutesModule { }
