
import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { JFValidators } from 'app/static/validators'
import { Subject } from 'rxjs'
import { skip } from 'rxjs/operators'

@Component({
  selector: 'jf-videoplayer',
  templateUrl: './videoplayer.component.html',
  styleUrls: ['./videoplayer.component.sass']
})
export class VideoplayerComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() video_url
  @Input() autoplay = false
  @Input() poster: string
  @Input() cover = false
  @ViewChild('video') video
  public videoState = 'pause'

  constructor () {}

  ngOnInit() {

  }

  ngAfterViewInit () {

  }

  ngOnDestroy () {

  }

  init () {
    if (this.autoplay) {
      this.play()
    }
  }

  play () {
    this.video.nativeElement.play()
  }

  toggleVideo () {
    switch (this.videoState) {
      case 'playing':
        this.video.nativeElement.pause()
        break
      case 'ended':
      case 'pause':
        this.video.nativeElement.play()
        break
      case 'waiting':
        break
      default:
        this.video.nativeElement.play()
    }
  }
}
