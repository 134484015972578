
<div class="jf-inner">
	<div @show>
		<div class="jf-close" (click)="close.emit()" *ngIf="step !== 5"></div>
		<div class="jf-back" (click)="step = step - 1" *ngIf="step > 1 && step !== 5"></div>

		<ng-container *ngIf="step === 1">
			<h2>
				Download freischalten
			</h2>

			<p>
				Gratulation! Jetzt kannst du dir dein <strong style="font-style: italic;">JOBFLASH</strong>-Video freischalten und frei einsetzen.
        Die Zahlungsmöglichkeiten und dein Investition siehst du hier:
			</p>

			<div class="jf-prices">
				<div class="jf-total">
					Preis
					<div class="jf-payments">
						<img class="jf-payment" src="/assets/icon/payment/sepa.svg">
						<img class="jf-payment" src="/assets/icon/payment/visa.svg">
						<img class="jf-payment" src="/assets/icon/payment/mastercard.svg">
					</div>
				</div>
				<p class="jf-price">
					24,90 €
					<small>
						inkl. 3,98 € MwSt.
					</small>
				</p>
			</div>

			<div class="jf-btn jf-btn-next" (click)="nextStep()">
				Freischalten
			</div>
		</ng-container>

		<ng-container *ngIf="step === 2">
			<h3>
				Rechnungsempfänger
			</h3>

			<div class="jf-form" [formGroup]="payer">
				<jf-input type="text" [showErrors]="ir_showErrors" [control]="payer.get('company')" placeholder="Firma" [validators]="ir_validators.company" class="jf-company"></jf-input>
				<jf-input type="text" [showErrors]="ir_showErrors" [control]="payer.get('name')" placeholder="Vor- & Nachname" [validators]="ir_validators.name" class="jf-name"></jf-input>
				<jf-input type="text" [showErrors]="ir_showErrors" [control]="payer.get('street')" placeholder="Straße, Nr." [validators]="ir_validators.street" class="jf-street"></jf-input>
				<jf-input type="tel" [showErrors]="ir_showErrors" [control]="payer.get('postcode')" placeholder="PLZ" [validators]="ir_validators.postcode" class="jf-postcode"></jf-input>
				<jf-input type="text" [showErrors]="ir_showErrors" [control]="payer.get('city')" placeholder="Stadt" [validators]="ir_validators.city" class="jf-city"></jf-input>
				<jf-input type="email" [showErrors]="ir_showErrors" [control]="payer.get('mail')" placeholder="E-Mail-Adresse" [validators]="ir_validators.mail" class="jf-mail"></jf-input>
			</div>

			<div class="jf-btn jf-btn-next" (click)="nextStep()">
				Weiter zur Auswahl
			</div>

		</ng-container>

		<ng-container *ngIf="step === 3">
			<h3>
				Zahlungsart wählen
			</h3>

			<div class="jf-payment-list">
				<div class="jf-payment" (click)="selectPayment('iban')">
					<img src="/assets/icon/payment/sepa.svg">
					<p>Lastschrift</p>
				</div>
				<div class="jf-payment" (click)="selectPayment('cc')">
					<img src="/assets/icon/payment/creditcard.svg">
					<p>Kreditkarte</p>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="step === 4">
			<jf-cc-payment (paid)="nextStep()" [client_id]="client_id" [data]="{payment: paymentData, payer: payer.value}" *ngIf="payment_id === 'cc'"></jf-cc-payment>
			<jf-iban-payment [data]="{payment: paymentData, payer: payer.value}" [client_id]="client_id" (paid)="nextStep()" [email]="payer.value.mail" [invoice_recipient]="paymentData" *ngIf="payment_id === 'iban'"></jf-iban-payment>
		</ng-container>

		<div class="jf-success" *ngIf="step === 5">
			<mat-icon>done</mat-icon>

			<h2>
				Zahlung eingegangen
			</h2>

			<p>
        Vielen Dank für deinen Kauf. Deine Zahlung ist eingegangen.
        Eine Rechnung bekommst du an deine angegebene E-Mail-Adresse gesandt.
			</p>

			<a class="jf-btn jf-submit" (click)="finish()">
				Ok
			</a>
		</div>
	</div>
</div>
