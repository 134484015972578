
import { Injectable } from '@angular/core'
import { uniqueId } from 'lodash'

export class StructuredDataTag {

  private id: string = uniqueId()
  private script: HTMLScriptElement

  constructor (content) {
    if (typeof document !== 'undefined' && document.body && typeof document.body.appendChild === 'function') {
      this.script = document.createElement('script')

      this.script.type = 'application/ld+json'
      this.script.innerHTML = JSON.stringify(content)
      this.script.setAttribute('data-sd-id', this.id)

      document.body.appendChild(this.script)
    }
  }

  update (content) {
    this.script.innerHTML = content
  }

  destory () {
    if (document && typeof document.querySelector === 'function') {
      const script = document.querySelector(['[data-sd-id="', this.id, '"]'].join(''))
      document.body.removeChild(script)
    }
  }
}
