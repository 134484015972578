/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./legalchecks.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../slidenav/slidenav.component.ngfactory";
import * as i4 from "../../slidenav/slidenav.component";
import * as i5 from "./legalchecks.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../service/analytics.service";
import * as i8 from "../../../service/meta.service";
import * as i9 from "../../../service/order.service";
var styles_LegalchecksComponent = [i0.styles];
var RenderType_LegalchecksComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LegalchecksComponent, data: {} });
export { RenderType_LegalchecksComponent as RenderType_LegalchecksComponent };
export function View_LegalchecksComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["class", "jf-content"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(3, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Datenschutz "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "jf-subline"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Es ist deine Wahl, welche Daten du mit uns teilst. "])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "label", [["class", "jf-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "input", [["checked", ""], ["disabled", ""], ["type", "checkbox"], ["value", "1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "span", [["class", "jf-checkbox-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Cookies "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "span", [["class", "jf-required"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Zur Erleichterung und besseren Zuordnung brauchen wir ein paar Cookies. "])), (_l()(), i1.ɵeld(15, 0, null, null, 10, "label", [["class", "jf-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 5, "input", [["formControlName", "anonymous_statistics"], ["type", "checkbox"], ["value", "1"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 17).onTouched() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(19, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(21, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(22, 0, null, null, 3, "span", [["class", "jf-checkbox-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Anonyme Datensammlung "])), (_l()(), i1.ɵeld(24, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" D\u00FCrfen wir dir beim Erstellen deines Videos \u00FCber die Schulter schauen, um es dir in Zukunft noch leichter zu machen? "])), (_l()(), i1.ɵeld(26, 0, null, null, 1, "jf-slidenav", [], [[8, "className", 0]], [[null, "prev"], [null, "next"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("prev" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } if (("next" === en)) {
        var pd_1 = (_co.forward() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_SlidenavComponent_0, i3.RenderType_SlidenavComponent)), i1.ɵdid(27, 114688, null, 0, i4.SlidenavComponent, [], { data: [0, "data"], loading: [1, "loading"], disableNext: [2, "disableNext"] }, { prev: "prev", next: "next" })], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.formData; _ck(_v, 1, 0, currVal_7); var currVal_15 = "anonymous_statistics"; _ck(_v, 19, 0, currVal_15); var currVal_17 = _co.navData; var currVal_18 = _co.saving; var currVal_19 = !_co.isValid(); _ck(_v, 27, 0, currVal_17, currVal_18, currVal_19); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 3).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 3).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 3).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 3).ngClassValid; var currVal_5 = i1.ɵnov(_v, 3).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 3).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 21).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 21).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 21).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 21).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 21).ngClassValid; var currVal_13 = i1.ɵnov(_v, 21).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 21).ngClassPending; _ck(_v, 16, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_16 = i1.ɵnov(_v, 27).classes; _ck(_v, 26, 0, currVal_16); }); }
export function View_LegalchecksComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "jf-legalchecks", [], [[2, "jf-slide", null]], null, null, View_LegalchecksComponent_0, RenderType_LegalchecksComponent)), i1.ɵdid(1, 114688, null, 0, i5.LegalchecksComponent, [i6.ActivatedRoute, i6.Router, i7.AnalyticsService, i8.MetaService, i9.OrderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).slideClass; _ck(_v, 0, 0, currVal_0); }); }
var LegalchecksComponentNgFactory = i1.ɵccf("jf-legalchecks", i5.LegalchecksComponent, View_LegalchecksComponent_Host_0, {}, {}, []);
export { LegalchecksComponentNgFactory as LegalchecksComponentNgFactory };
