var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrderService } from 'app/service/order.service';
import { SlideComponent } from 'app/element/slide/slide.component';
import { ActivatedRoute, Router } from '@angular/router';
var QuestionslideComponent = /** @class */ (function (_super) {
    __extends(QuestionslideComponent, _super);
    function QuestionslideComponent(route, router, orderService) {
        var _this = _super.call(this, route, router) || this;
        _this.route = route;
        _this.router = router;
        _this.orderService = orderService;
        _this.step = new BehaviorSubject(0);
        _this.slideClass = true;
        _this.videoState = 'waiting';
        _this.navDataList = [{
                step: false,
                background: true,
                actions: 'request_record',
            }, {}, {
                step: false,
                background: false,
                actions: 'confirm',
            }];
        var that = _this;
        that.step.subscribe(function (n) {
            that.navData = that.navDataList[n === 2 ? 2 : 0];
            if (n === 1) {
                setTimeout(function () {
                    // tslint:disable-next-line:no-unused-expression
                    that.input && that.input.nativeElement.click();
                }, 150);
            }
        });
        return _this;
    }
    QuestionslideComponent.prototype.ngOnInit = function () {
        var that = this;
        if (that.data.video_number) {
            that.navDataList[0].video_number = 'Video ' + that.data.video_number + '/7';
        }
        else {
            that.navDataList[0].video_number = false;
        }
        that.navDataList[0].headline = that.data.headline;
        that.navDataList[0].text = that.data.text;
        that.video_url = that.orderService.getFormGroup().get(that.data.step_key).value;
        if (that.video_url) {
            that.step.next(2);
        }
    };
    QuestionslideComponent.prototype.ngAfterViewInit = function () {
        if (this.video) {
            this.video.nativeElement.play();
        }
    };
    QuestionslideComponent.prototype.check = function () {
        var that = this;
        if (that.uploading) {
            return;
        }
        if (!that.input) {
            return;
        }
        var file = that.input.nativeElement.files[0];
        if (['video/mp4', 'video/quicktime'].indexOf(file.type) === -1) {
            alert('Bitte lade eine videodatei hoch (keine ' + file.type + ')');
            return;
        }
        that.upload(file);
    };
    QuestionslideComponent.prototype.upload = function (file) {
        var that = this;
        that.uploading = true;
        that.orderService.submitVideo(that.data.step_key, file)
            .then(function (resp) {
            that.uploading = false;
            that.video_url = resp.path;
            that.forward();
            that.step.next(2);
            setTimeout(function () {
                if (that.video) {
                    that.video.nativeElement.play();
                }
            }, 150);
        })
            .catch(function () {
            that.uploading = false;
            console.error('file upload error');
        });
        return;
    };
    QuestionslideComponent.prototype.back = function () {
        var that = this;
        var s = this.step;
        switch (s.value) {
            case 0:
                that.switchPrev();
                break;
            default:
                s.next(0);
        }
    };
    QuestionslideComponent.prototype.play = function () {
        // tslint:disable-next-line:no-unused-expression
        this.video && this.video.nativeElement.play();
    };
    QuestionslideComponent.prototype.toggleVideo = function () {
        if (!this.video) {
            return;
        }
        switch (this.videoState) {
            case 'playing':
                this.video.nativeElement.pause();
                break;
            case 'ended':
            case 'pause':
                this.video.nativeElement.play();
                break;
            case 'waiting':
                break;
            default:
                this.video.nativeElement.play();
        }
    };
    QuestionslideComponent.prototype.forward = function () {
        var that = this;
        var s = this.step;
        if (this.uploading) {
            return;
        }
        switch (s.value) {
            case 2:
                var patch = {};
                patch[that.data.step_key] = that.video_url;
                that.orderService.getFormGroup().patchValue(patch);
                that.switchNext();
                break;
            default:
                if (this.video) {
                    this.video.nativeElement.pause();
                }
                s.next(1);
        }
    };
    return QuestionslideComponent;
}(SlideComponent));
export { QuestionslideComponent };
