
<ng-container *ngIf="initialized">
	<div class="jf-rotate-device" *ngIf="requestRotate">
		<div>
			<mat-icon>sync</mat-icon>
			<span>
				Halte dein Handy im Hochformat
			</span>
		</div>
	</div>
	<div class="jf-block-desktop" *ngIf="blockDesktop">
		<div>
			<mat-icon>smartphone</mat-icon>
			<span>
				Öffne <strong style="font-style: italic;">JOBFLASH</strong> auf deinem Handy
			</span>
		</div>
	</div>
	<jf-header></jf-header>
	<router-outlet></router-outlet>
</ng-container>

<ng-container *ngIf="!initialized">
	<img src="/assets/brand/logo.svg" alt="JOBFLASH Logo">
	<div class="jf-loader" *ngIf="showLoading"></div>
</ng-container>
