
<div class="jf-content">

  <div class="jf-preview-frame" (click)="reqUpload()">
    <div class="jf-upload-content" *ngIf="!validLogo">
      <mat-icon>arrow_upward</mat-icon>
      <p>
        Logo hochladen
      </p>
    </div>
    <div class="jf-preview" [ngStyle]="{'transform': currRotation, 'background-image': 'url(' + logoData + ')'}"></div>

    <div class="jf-btn jf-remove" *ngIf="validLogo" (click)="clear($event)">
      <mat-icon>delete</mat-icon>
    </div>
  </div>

	<h2>Lade dein Logo hoch</h2>

	<p>
		Personalisiere deine Suche und lade dein Logo hoch.
    Achte dabei auf eine gute Auflösung und das passende Format.<br>
		Mögliche Bildformate: jpg, png, gif<br>
    Bevorzugte Größe: 750x300px
	</p>

  <input style="left: -99999px; position: absolute; visibility: hidden;" accept="image/x-png,image/jpeg" type="file" #input (change)="check()">
</div>

<jf-slidenav [loading]="uploading" [disableNext]="false" [skip]="!validLogo" [data]="navData" (prev)="back()" (next)="forward()"></jf-slidenav>
