
<div class="checkout">
  <div class="jf-card">
    <label class="jf-cardnr">
      <span>Kartennummer</span>
      <div #cardNumber></div>
    </label>
    <label class="jf-expiry">
      <span>Ablauf</span>
      <div #cardExpiry></div>
    </label>
    <label class="jf-cvc">
      <span>CVC</span>
      <div #cardCvc></div>
    </label>
    <label class="jf-plz">
      <span>PLZ</span>
      <div #cardZip></div>
    </label>
  </div>

  <div class="form-row">
    <div id="card-errors" role="alert" *ngIf="error">{{ error }}</div>
  </div>

  <label class="jf-checkbox">
    <input [(ngModel)]="terms" type="checkbox">
    <span class="jf-checkbox-content">Ich bestätige hiermit die <a class="jf-terms-link" (click)="showTerms()">AGB</a>. </span>
  </label>


  <p class="jf-price">
    24,90 €
    <small>inkl. 3,98 € MwSt.</small>
  </p>
  <p class="jf-note">
    Im Anschluss erhältst du eine E-Mail mit dem Downloadlink zu deinem Video.
  </p>

  <div class="jf-submit" (click)="onSubmit()" [ngClass]="{'jf-disabled': !canSubmit(), 'jf-loading': loading}">Jetzt kaufen</div>
</div>

<div *ngIf="error" class="jf-error-message">
  Deine Karte wurde von der Bank abgelehnt ({{error}}).
</div>
