
import { Component, OnInit, OnDestroy, HostListener, NgZone } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { OrderService } from 'app/service/order.service'
import { takeUntil } from 'rxjs/operators'
import { throttle } from 'lodash'
import { type } from 'os'

@Component({
  selector: 'jf-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.sass'],
})
export class EditorComponent implements OnInit, OnDestroy {

  private step: BehaviorSubject<number> = new BehaviorSubject<number>(1)
  public initialized
  public showLoading: boolean
  public requestRotate: boolean
  public blockDesktop: boolean
  private reqSizeCheck = throttle(this.checkSize.bind(this), 250, {leading: false, trailing: true})
  private destroy: Subject<boolean> = new Subject<boolean>()

  public checkOrientation = function () {
    if (typeof window === 'undefined') {return}

    setTimeout(() => {
      if (typeof window.orientation !== 'undefined') {
        // noinspection JSPotentiallyInvalidUsageOfClassThis
        this.requestRotate = (window.orientation === 90 || window.orientation === -90)
      } else if (screen.orientation && typeof screen.orientation.angle !== 'undefined') {
        // noinspection JSPotentiallyInvalidUsageOfClassThis
        this.requestRotate = (screen.orientation.angle === 90 || screen.orientation.angle === -90)
      } else {
        // noinspection JSPotentiallyInvalidUsageOfClassThis
        this.requestRotate = window.innerWidth > window.innerHeight
      }
    })
  }

  @HostListener('window:resize', ['$event'])
    onResize() {
      this.reqSizeCheck()
    }

  constructor (
    private orderService: OrderService,
    private zone: NgZone,
  ) {
    this.orderService.getBootState()
      .pipe(takeUntil(this.destroy))
      .subscribe(state => {
        this.initialized = state
      })
    this.step.subscribe(n => {
      // this.steps[n - 1]
    })
    this.orderService.initVideoCreation()
    this.checkSize()
    window.addEventListener('orientationchange', this.checkOrientation.bind(this), false)

    setTimeout(() => {
      this.showLoading = true
    }, 1500)
  }

  private checkSize () {
    this.zone.run(() => {
      this.blockDesktop = window.innerWidth > 840 || window.innerHeight > 840
    })
  }

  ngOnInit() {
    this.requestRotate = window.innerWidth > window.innerHeight
  }

  ngOnDestroy () {
    window.removeEventListener('orientationchange', this.checkOrientation.bind(this), false)
    this.destroy.next(true)
  }
}
