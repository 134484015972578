var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, OnDestroy, AfterViewInit, ElementRef, EventEmitter } from '@angular/core';
import { OrderService } from 'app/service/order.service';
import { environment } from 'environments/environment';
import { HeaderService } from 'app/service/header.service';
var CcPaymentComponent = /** @class */ (function () {
    function CcPaymentComponent(cd, orderService, headerService) {
        this.cd = cd;
        this.orderService = orderService;
        this.headerService = headerService;
        this.paid = new EventEmitter(true);
        this.cardHandler = this.onChange.bind(this);
        this.fieldValidation = {
            cardNumber: false,
            cardExpiry: false,
            cardCvc: false,
            postalCode: false,
        };
        // @ts-ignore
        this.stripe = Stripe(environment.stripe_token);
    }
    CcPaymentComponent.prototype.ngAfterViewInit = function () {
        var that = this;
        var elements = this.stripe.elements();
        var elementClasses = {
            focus: 'jf-focused',
            empty: 'jf-empty',
            invalid: 'jf-invalid',
        };
        var style = {
            base: {
                lineHeight: '30px',
                backgroundColor: '#efefef',
                color: '#181818',
                fontSize: '16px',
            }
        };
        that.fieldCardNumber = elements.create('cardNumber', {
            style: style,
            classes: elementClasses,
        });
        that.fieldCardNumber.mount(this.cardNumber.nativeElement);
        that.fieldCardNumber.on('change', function (data) {
            that.fieldValidation.cardNumber = data.complete;
        });
        that.fieldCardExpiry = elements.create('cardExpiry', {
            style: style,
            classes: elementClasses,
        });
        that.fieldCardExpiry.mount(this.cardExpiry.nativeElement);
        that.fieldCardExpiry.on('change', function (data) {
            that.fieldValidation.cardExpiry = data.complete;
        });
        that.fieldCardCvc = elements.create('cardCvc', {
            style: style,
            classes: elementClasses,
        });
        that.fieldCardCvc.mount(this.cardCvc.nativeElement);
        that.fieldCardCvc.on('change', function (data) {
            that.fieldValidation.cardCvc = data.complete;
        });
        that.fieldCardZip = elements.create('postalCode', {
            style: style,
            classes: elementClasses,
        });
        that.fieldCardZip.mount(this.cardZip.nativeElement);
        that.fieldCardZip.on('change', function (data) {
            that.fieldValidation.postalCode = data.complete;
        });
    };
    CcPaymentComponent.prototype.ngOnDestroy = function () {
        // this.card.removeEventListener('change', this.cardHandler)
        this.fieldCardNumber.destroy();
        this.fieldCardExpiry.destroy();
        this.fieldCardCvc.destroy();
        this.fieldCardZip.destroy();
    };
    CcPaymentComponent.prototype.onChange = function (_a) {
        var error = _a.error;
        if (error) {
            this.error = error.message;
        }
        else {
            this.error = null;
        }
        this.cd.detectChanges();
    };
    CcPaymentComponent.prototype.showTerms = function () {
        this.headerService.setShow(true);
        this.headerService.setTab('terms');
    };
    CcPaymentComponent.prototype.canSubmit = function () {
        var _this = this;
        if (!this.terms) {
            return false;
        }
        var valid = true;
        Object.keys(this.fieldValidation).forEach(function (key) {
            valid = valid && _this.fieldValidation[key];
        });
        return valid;
    };
    CcPaymentComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var that, _a, token, error;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.canSubmit()) {
                            return [2 /*return*/];
                        }
                        that = this;
                        that.loading = true;
                        return [4 /*yield*/, that.stripe.createToken(that.fieldCardNumber, that.data.payment)];
                    case 1:
                        _a = _b.sent(), token = _a.token, error = _a.error;
                        if (error) {
                            console.log('Something is wrong:', error);
                            that.loading = false;
                        }
                        else {
                            that.orderService.purchase('cc', {
                                id: token.id,
                                payer: that.data.payer,
                            }, that.client_id)
                                .subscribe(function () {
                                that.paid.emit();
                            }, function (err) {
                                console.log('Something is wrong (api):', err);
                                that.error = err.error.error;
                                that.loading = false;
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return CcPaymentComponent;
}());
export { CcPaymentComponent };
