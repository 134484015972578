
export const priceList = [{
  key: 'standard',
  name: 'STANDARD',
  price: '0,00 €',
  desc: `<ul>
        <li>Teilen via Facebook, Xing, Whatsapp, E-Mail, Instagram uvm.</li>
        <li>Persönliche Video-Anleitung</li>
        <li>Mit Ihrem Logo im Video</li>
        <li>Mit Ihren Kontaktdaten im Video</li>
        <li>Keine versteckten Kosten</li>
        <li>90 Tage verfügbar</li>
        <li>Branchen individuell wählbar</li>
    </ul>`,
}, {
  key: 'premium',
  name: 'PREMIUM',
  price: '24,90 €<span>/ pro Video</span>',
  desc: `<ul>
      <li><strong>Alles aus dem Standard-Paket</strong></li>
      <li>Einmalige Kosten</li>
      <li>kein Abo!</li>
      <li>Video als Datei herunterladen</li>
      <li>Downloadlink per E-Mail</li>
      <li>Einbindung in die eigenen Webseite</li>
      <li>Direkter Upload in alle Social Media Kanäle</li>
      <li>Keine Werbung</li>
      <li>Keine Wasserzeichen</li>
    </ul>`,
}, {
  key: 'whitelabel',
  name: 'WHITELABEL',
  btn: {
    type: 'contact',
    text: 'Kontakt aufnehmen',
  },
  price: '<div class="jf-ondemand">AUF ANFRAGE</div>',
  desc: `Sprechen sie uns auf Ihre maßgeschneiderte Lösung an.
    Wir haben viele Möglichkeiten JobflashIhrem Unternehmen,
    technisch und visuell, ideal anzupassen.`,
}, ]
