import { Component, ChangeDetectorRef, OnDestroy, ViewChild, AfterViewInit, ElementRef, Input, Output, EventEmitter } from '@angular/core'
import { OrderService } from 'app/service/order.service'
import { environment } from 'environments/environment'
import { FormControl, Validators } from '@angular/forms'
import { JFValidators } from 'app/static/validators'
import { HeaderService } from 'app/service/header.service'

@Component({
  selector: 'jf-iban-payment',
  templateUrl: './iban-payment.component.html',
  styleUrls: ['./iban-payment.component.sass']
})
export class IbanPaymentComponent implements AfterViewInit, OnDestroy {
  @ViewChild('ibanInput') cardInfo: ElementRef
  @Output() paid: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Input() invoice_recipient
  @Input() client_id: string
  @Input() email: string
  @Input() data: any

  iban: any
  cardHandler = this.onChange.bind(this)
  loading: boolean
  error: string
  stripe: any
  terms: any

  iban_valid: boolean
  card_holder = new FormControl('')
  card_holder_validators = [Validators.required, JFValidators.person]

  constructor (
    private cd: ChangeDetectorRef,
    private orderService: OrderService,
    private headerService: HeaderService,
  ) {
    // @ts-ignore
    this.stripe = Stripe(environment.stripe_token)
  }

  ngAfterViewInit() {
    const style = {
      base: {
        lineHeight: '30px',
        backgroundColor: '#efefef',
        color: '#181818',
        fontSize: '16px',
      }
    }
    const options = {
      supportedCountries: ['SEPA'],
      placeholderCountry: 'DE',
      style: style,
    }

    this.iban = this.stripe.elements().create('iban', options)
    this.iban.mount(this.cardInfo.nativeElement)

    this.iban.addEventListener('change', this.cardHandler)
  }

  ngOnDestroy() {
    this.iban.removeEventListener('change', this.cardHandler)
    this.iban.destroy()
  }

  onChange({ error }) {
    this.iban_valid = !error
    this.cd.detectChanges()
  }

  canSubmit (): boolean {
    return this.terms && this.iban_valid && this.card_holder.valid
  }

  showTerms () {
    this.headerService.setShow(true)
    this.headerService.setTab('terms')
  }

  async onSubmit () {
    const that = this
    that.loading = true
    that.error = ''

    const sourceData = {
      type: 'sepa_debit',
      currency: 'eur',
      owner: {
        name: that.card_holder.value,
        email: that.email,
      },
      mandate: {
        notification_method: 'email',
      }
    }

    // @ts-ignore
    that.stripe.createSource(that.iban, sourceData).then(function(result) {
      that.orderService.purchase('iban', {
        id: result.source.id,
        name: that.card_holder.value,
        email: that.email,
        invoice_recipient: that.invoice_recipient,
        payer: that.data.payer,
      }, that.client_id)
        .subscribe(() => {
          that.paid.emit()
        }, err => {
          console.log('Something is wrong (api):', err)
          that.error = err.error.error
          that.loading = false
        })
    })
  }
}
