import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { copyToClipboard } from 'app/tools/javascript.tools';
var ShareComponent = /** @class */ (function () {
    function ShareComponent() {
        this.close = new EventEmitter();
        this.shareLinks = [{
                'label': 'Facebook',
                'icon': 'facebook.svg',
                'link': 'https://facebook.com/sharer/sharer.php?u={url}',
            }, {
                'label': 'Twitter',
                'icon': 'twitter.svg',
                'link': 'https://twitter.com/intent/tweet/?text={text};url={url}',
            }, {
                'label': 'Pinterest',
                'icon': 'pinterest.svg',
                'link': 'https://pinterest.com/pin/create/button/?url={url}&amp;media={url}&amp;description={text}',
            }, {
                'label': 'LinkedIn',
                'icon': 'linkedin.svg',
                'link': 'https://www.linkedin.com/shareArticle?mini=true&url={url}',
            }, {
                'label': 'Xing',
                'icon': 'xing.svg',
                'link': 'https://www.xing.com/app/user?op=share;url={url};title={text}',
            }, {
                'label': 'Reddit',
                'icon': 'reddit.svg',
                'link': 'https://reddit.com/submit/?url={url}&amp;resubmit=true&amp;title={text}',
            }, {
                'label': 'WhatsApp',
                'icon': 'whatsapp.svg',
                'link': 'https://api.whatsapp.com/send?text={text}%20{url}',
            }, {
                'label': 'Telegram',
                'icon': 'telegram.svg',
                'link': 'https://telegram.me/share/url?text={text}&url={url}',
            }, {
                'label': 'Mail',
                'icon': 'mail.svg',
                'link': 'mailto:?subject=Mein%20JOBFLASH%20Recruitment%20Video&body={text}{url}',
            }];
        this.shareText = 'Hey, ich habe grade mein eigenes Recruitment Video mit JOBFLASH erstellt. ';
    }
    Object.defineProperty(ShareComponent.prototype, "url", {
        set: function (url) {
            this._url = url;
            this.genereatePlatforms();
        },
        enumerable: true,
        configurable: true
    });
    ShareComponent.prototype.ngOnInit = function () {
    };
    ShareComponent.prototype.ngOnDestroy = function () {
    };
    ShareComponent.prototype.genereatePlatforms = function () {
        var _this = this;
        var that = this;
        that.platforms = [];
        that.shareLinks.forEach(function (elem) {
            var link = elem.link;
            link = link.replace(new RegExp('{text}', 'g'), encodeURI(_this.shareText));
            link = link.replace(new RegExp('{url}', 'g'), encodeURI(_this._url));
            that.platforms.push({
                'label': elem.label,
                'icon': elem.icon,
                'link': link,
            });
        });
    };
    ShareComponent.prototype.clipboard = function () {
        var _this = this;
        copyToClipboard(this._url);
        this.copied = true;
        setTimeout(function () {
            _this.copied = false;
        }, 750);
    };
    return ShareComponent;
}());
export { ShareComponent };
