/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./questionslide.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "../../slidenav/slidenav.component.ngfactory";
import * as i6 from "../../slidenav/slidenav.component";
import * as i7 from "./questionslide.component";
import * as i8 from "@angular/router";
import * as i9 from "../../../service/order.service";
var styles_QuestionslideComponent = [i0.styles];
var RenderType_QuestionslideComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuestionslideComponent, data: {} });
export { RenderType_QuestionslideComponent as RenderType_QuestionslideComponent };
function View_QuestionslideComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "jf-video jf-static"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-image": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (("url(/assets/mood/" + ((_co.data == null) ? null : _co.data.image)) + ")")); _ck(_v, 1, 0, currVal_0); }, null); }
function View_QuestionslideComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "jf-replay"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["replay"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
function View_QuestionslideComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "jf-play"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["play_arrow"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
function View_QuestionslideComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "jf-loading"]], null, null, null, null, null))], null, null); }
function View_QuestionslideComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "jf-video"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[2, 0], ["video", 1]], null, 1, "video", [["autoplay", ""], ["playsinline", ""]], null, [[null, "waiting"], [null, "ended"], [null, "playing"], [null, "pause"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("waiting" === en)) {
        var pd_0 = ((_co.videoState = "waiting") !== false);
        ad = (pd_0 && ad);
    } if (("ended" === en)) {
        var pd_1 = ((_co.videoState = "ended") !== false);
        ad = (pd_1 && ad);
    } if (("playing" === en)) {
        var pd_2 = ((_co.videoState = "playing") !== false);
        ad = (pd_2 && ad);
    } if (("pause" === en)) {
        var pd_3 = ((_co.videoState = "pause") !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.toggleVideo() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionslideComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionslideComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionslideComponent_6)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.videoState === "ended"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.videoState === "pause"); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.videoState === "waiting"); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "/assets/mood/", ((_co.data == null) ? null : _co.data.video), ""); _ck(_v, 2, 0, currVal_0); }); }
function View_QuestionslideComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionslideComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionslideComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data == null) ? null : _co.data.image); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.data == null) ? null : _co.data.video); _ck(_v, 4, 0, currVal_1); }, null); }
function View_QuestionslideComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["input", 1]], null, 0, "input", [["accept", "video/*"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.check() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_QuestionslideComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "jf-replay"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["replay"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
function View_QuestionslideComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "jf-play"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["play_arrow"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
function View_QuestionslideComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "jf-loading"]], null, null, null, null, null))], null, null); }
function View_QuestionslideComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "jf-video"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[2, 0], ["video", 1]], null, 1, "video", [["autoplay", ""], ["playsinline", ""]], null, [[null, "waiting"], [null, "ended"], [null, "playing"], [null, "pause"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("waiting" === en)) {
        var pd_0 = ((_co.videoState = "waiting") !== false);
        ad = (pd_0 && ad);
    } if (("ended" === en)) {
        var pd_1 = ((_co.videoState = "ended") !== false);
        ad = (pd_1 && ad);
    } if (("playing" === en)) {
        var pd_2 = ((_co.videoState = "playing") !== false);
        ad = (pd_2 && ad);
    } if (("pause" === en)) {
        var pd_3 = ((_co.videoState = "pause") !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.toggleVideo() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionslideComponent_9)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionslideComponent_10)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionslideComponent_11)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.videoState === "ended"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.videoState === "pause"); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.videoState === "waiting"); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.video_url, ""); _ck(_v, 2, 0, currVal_0); }); }
export function View_QuestionslideComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { input: 0 }), i1.ɵqud(671088640, 2, { video: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "jf-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionslideComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionslideComponent_7)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionslideComponent_8)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "jf-slidenav", [], [[8, "className", 0]], [[null, "prev"], [null, "next"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("prev" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } if (("next" === en)) {
        var pd_1 = (_co.forward() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_SlidenavComponent_0, i5.RenderType_SlidenavComponent)), i1.ɵdid(10, 114688, null, 0, i6.SlidenavComponent, [], { data: [0, "data"], loading: [1, "loading"] }, { prev: "prev", next: "next" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.step.value !== 2); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.step.value === 1); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.step.value === 2); _ck(_v, 8, 0, currVal_2); var currVal_4 = _co.navData; var currVal_5 = _co.uploading; _ck(_v, 10, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 10).classes; _ck(_v, 9, 0, currVal_3); }); }
export function View_QuestionslideComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "jf-questionslide", [], [[2, "jf-slide", null]], null, null, View_QuestionslideComponent_0, RenderType_QuestionslideComponent)), i1.ɵdid(1, 4308992, null, 0, i7.QuestionslideComponent, [i8.ActivatedRoute, i8.Router, i9.OrderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).slideClass; _ck(_v, 0, 0, currVal_0); }); }
var QuestionslideComponentNgFactory = i1.ɵccf("jf-questionslide", i7.QuestionslideComponent, View_QuestionslideComponent_Host_0, {}, {}, []);
export { QuestionslideComponentNgFactory as QuestionslideComponentNgFactory };
