import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { animate, style, transition, trigger } from '@angular/animations'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { JFValidators } from 'app/static/validators'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

@Component({
  selector: 'jf-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.sass'],
  animations: [
    trigger('show', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px) scale(.98)' }),
        animate('.25s', style({ opacity: 1, transform: 'translateY(0) scale(1)' })),
      ]),
      transition(':leave', [
        animate('.3s', style({ opacity: 0, transform: 'translateY(30px) scale(.98)' }))
      ])
    ]),
  ]
})
export class PurchaseComponent implements OnInit, OnDestroy {
  @Output() close: EventEmitter<void> = new EventEmitter<void>()
  @Output() done: EventEmitter<void> = new EventEmitter<void>()
  @Output() next: EventEmitter<void> = new EventEmitter<void>()
  @Input() client_id
  public step = 1

  public payment_id: string

  public payer = new FormGroup({
    company: new FormControl(''),
    name: new FormControl(''),
    street: new FormControl(''),
    postcode: new FormControl(''),
    city: new FormControl(''),
    mail: new FormControl(''),
  })
  private destroy: Subject<boolean> = new Subject<boolean>()
  public paymentData
  public ir_showErrors: boolean
  public ir_validators = {
    company: [Validators.required, JFValidators.company],
    name: [Validators.required, JFValidators.person],
    street: [Validators.required],
    postcode: [Validators.required, JFValidators.postcode],
    city: [Validators.required],
    mail: [Validators.required, JFValidators.email],
  }

  constructor () {
    this.payer.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        const val = this.payer.value
        this.paymentData = {
          name: val.name,
          address_line1: val.street,
          address_city: val.city,
          address_zip: val.postcode,
          address_country: 'de',
          currency: 'eur',
        }
      })
  }

  ngOnInit () {

  }

  selectPayment (id: string) {
    this.payment_id = id
    this.step++
  }

  nextStep () {
    if (this.step === 2) {
      if (!this.payer.valid) {
        this.ir_showErrors = true
        return
      }
      this.ir_showErrors = false
    }

    this.step = this.step + 1
  }

  finish () {
    this.done.emit()
  }

  ngOnDestroy () {
    this.destroy.next(true)
  }
}
