

<ng-container *ngIf="client">
  <ng-container *ngIf="!notFound">
    <ng-container *ngIf="!initialized">
      <img class="jf-logo" src="/assets/brand/logo.svg" alt="JOBFLASH Logo">
      <div class="jf-loader" *ngIf="showLoading"></div>
    </ng-container>

    <ng-container *ngIf="initialized">
      <jf-header></jf-header>

      <jf-cookie *ngIf="showCookie"></jf-cookie>

      <div class="jf-slide-content">
        <div class="jf-video">
          <video
            #video
            autoplay
            playsinline
            (loadstart)="onLoad()"
            (load)="onLoad()"
            (waiting)="videoState = 'waiting'"
            (ended)="videoState = 'ended'"
            (playing)="videoState = 'playing'"
            (pause)="videoState = 'pause'"
            (click)="toggleVideo()"
          >
            <source [src]="videoData.paid ? videoData.paid_video_url : videoData.video_url" type="video/mp4">
          </video>
          <div class="jf-replay" *ngIf="videoState === 'ended'" (click)="play()">
            <mat-icon>replay</mat-icon>
          </div>
          <div class="jf-play" *ngIf="videoState === 'pause'" (click)="play()">
            <mat-icon>play_arrow</mat-icon>
          </div>
          <div class="jf-loading" *ngIf="videoState === 'waiting'"></div>
        </div>

        <div class="jf-icons" [ngClass]="{'jf-active': view === 'overview'}">
          <div class="jf-download" (click)="download()" *ngIf="videoData.paid">
            <mat-icon>arrow_downward</mat-icon>
            herunterladen
          </div>
          <div class="jf-unlock" (click)="setView('purchase')" *ngIf="!videoData.paid">
            <mat-icon>payment</mat-icon>
            download nur 24,90&thinsp;€
          </div>
          <div class="jf-share" *ngIf="!desktop" (click)="setView('share')">
            <mat-icon>share</mat-icon>
            teilen <div class="jf-badge" *ngIf="!videoData.paid">free</div>
          </div>

          <jf-share [ngClass]="{'jf-desktop': desktop}" *ngIf="view === 'share' || desktop" [url]="videoData.share_url" (close)="setView('overview')"></jf-share>
          <jf-purchase [ngClass]="{'jf-desktop': desktop}" (done)="unlock()" *ngIf="view === 'purchase'" [client_id]="client_id" (next)="next.emit()" (close)="setView('overview')"></jf-purchase>
        </div>

      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="notFound">
    <img class="jf-logo" src="/assets/brand/logo.svg" alt="JOBFLASH Logo">
    <div class="jf-404">Seite kann nicht geöffnet werden</div>
  </ng-container>
</ng-container>
