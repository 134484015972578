import { Injectable, Inject, PLATFORM_ID } from '@angular/core'
import { environment } from 'environments/environment'
import { Router } from '@angular/router'
import { WINDOW } from 'app/service/window.service'
import { isPlatformBrowser } from '@angular/common'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class HeaderService {
  private show: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private tab: BehaviorSubject<string> = new BehaviorSubject<string>('menu')

  constructor () {

  }

  getTab () {
    return this.tab
  }

  getShow () {
    return this.show
  }

  setTab (next) {
    this.tab.next(next)
  }

  setShow (next) {
    this.show.next(next)
  }
}
