
import { Injectable, Inject } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import modernizr from 'app/service/modernizr.js'
import { WINDOW } from 'app/service/window.service'

@Injectable()
export class BrowserService {

  private webP = true
  private devicemotion
  private deviceorientation
  private touchevents

  constructor (
    @Inject(WINDOW) public window
  ) {
    const that = this

    if (typeof document === 'undefined') {
      return
    }

    const elem = document.createElement('canvas')
    if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      that.webP = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
    } else {
      that.webP = false
    }

    if (modernizr) {
      that.devicemotion = modernizr['devicemotion']
      that.deviceorientation = modernizr['deviceorientation']
      that.touchevents = modernizr['touchevents']
    }
  }

  supports (feature: string) {
    switch (feature) {
      case 'webP':
        return this.webP
      case 'devicemotion':
        return this.devicemotion
      case 'deviceorientation':
        return this.deviceorientation
      case 'touchevents':
        return this.touchevents
    }
    return false
  }
}
