
<video
  #video
  [autoplay]="autoplay ? 'autoplay' : null"
  playsinline
  (loadstart)="init()"
  (waiting)="videoState = 'waiting'"
  (ended)="videoState = 'ended'"
  (playing)="videoState = 'playing'"
  (pause)="videoState = 'pause'"
  (click)="toggleVideo()"
  [poster]="poster ? poster : ''"
  [ngClass]="{'jf-cover': cover}"
>
  <source [src]="video_url" type="video/mp4">
</video>
<div class="jf-replay" *ngIf="videoState === 'ended'" (click)="play()">
  <mat-icon>replay</mat-icon>
</div>
<div class="jf-play" *ngIf="videoState === 'pause'" (click)="play()">
  <mat-icon>play_arrow</mat-icon>
</div>
<div class="jf-loading" *ngIf="videoState === 'waiting'"></div>
