import { Component, HostBinding, HostListener, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { DataInterface } from 'app/api/interfaces/data.interface'
import { throttle } from 'lodash'
import { MetaService } from 'app/service/meta.service'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'jf-publishedvideo',
  templateUrl: './publishedvideo.component.html',
  styleUrls: ['./publishedvideo.component.sass']
})
export class PublishedvideoComponent implements OnInit {
  @ViewChild('video') video
  public videoState = 'pause'
  public notFound: boolean
  public videoData: any
  public view: string
  public client_id: string
  public showCookie: boolean
  public client: boolean
  public showLoading: boolean
  @HostBinding('class.jf-slide') slideClass = true
  @HostBinding('class.jf-loaded') initialized: boolean
  @HostBinding('class.jf-loading') get isLoading () {
    return !this.initialized
  }
  @HostBinding('class.jf-paid') get isPaid () {
    return this.videoData && this.videoData.paid
  }
  private reqSizeCheck = throttle(this.checkSize.bind(this), 250, {leading: false, trailing: true})
  @HostBinding('class.jf-desktop') desktop
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.reqSizeCheck()
  }

  constructor (
    private route: ActivatedRoute,
    private dataInterface: DataInterface,
    private metaService: MetaService,
    private zone: NgZone,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.client = isPlatformBrowser(platformId)
    if (!this.client) {
      return
    }

    const that = this
    that.route.paramMap.subscribe( params => {
      that.initialized = false
      that.notFound = false
      that.client_id = params.get('id')
      that.dataInterface.getVideo(that.client_id)
        .subscribe(data => {
          that.videoData = data
          that.initialized = true
        }, error => {
          that.notFound = true
        })
    })

    that.reqSizeCheck()

    that.metaService.getCookieNoteVisible()
      .subscribe(show => that.showCookie = show)

    setTimeout(() => {
      that.showLoading = true
    }, 1500)
  }

  ngOnInit() {

  }

  play () {
    this.video.nativeElement.play()
  }

  setView (view: string) {
    this.view = view
  }

  private checkSize () {
    this.zone.run(() => {
      if (typeof window !== 'undefined') {
        this.desktop = window.innerWidth > 767
      }
    })
  }

  toggleVideo () {
    switch (this.videoState) {
      case 'playing':
        this.video.nativeElement.pause()
        break
      case 'ended':
      case 'pause':
        this.video.nativeElement.play()
        break
      case 'waiting':
        break
      default:
        this.video.nativeElement.play()
    }
  }
}
