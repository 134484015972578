import { AfterViewInit, EventEmitter, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataInterface } from 'app/api/interfaces/data.interface';
import { throttle } from 'lodash';
import { MetaService } from 'app/service/meta.service';
import { skip } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
var ViewresultComponent = /** @class */ (function () {
    function ViewresultComponent(route, metaService, dataInterface, zone, platformId) {
        this.route = route;
        this.metaService = metaService;
        this.dataInterface = dataInterface;
        this.zone = zone;
        this.prev = new EventEmitter();
        this.next = new EventEmitter();
        this.navData = {
            step: true,
            background: false,
            actions: '',
        };
        this.videoState = 'pause';
        this.reqSizeCheck = throttle(this.checkSize.bind(this), 250, { leading: false, trailing: true });
        this.view = 'overview';
        this.slideClass = true;
        this.client = isPlatformBrowser(platformId);
        if (!this.client) {
            return;
        }
        try {
            localStorage.removeItem('increation');
        }
        catch (e) { }
        var that = this;
        that.route.paramMap.subscribe(function (params) {
            that.initialized = false;
            that.notFound = false;
            that.client_id = params.get('id');
            that.dataInterface.getResult(that.client_id, params.get('key'))
                .subscribe(function (data) {
                that.videoData = data;
                that.initialized = true;
            }, function (error) {
                that.notFound = true;
            });
        });
        that.metaService.getCookieNoteVisible()
            .subscribe(function (show) { return that.showCookie = show; });
        that.reqSizeCheck();
        setTimeout(function () {
            that.showLoading = true;
        }, 1500);
    }
    Object.defineProperty(ViewresultComponent.prototype, "isLoading", {
        get: function () {
            return !this.initialized;
        },
        enumerable: true,
        configurable: true
    });
    ViewresultComponent.prototype.onResize = function () {
        this.reqSizeCheck();
    };
    ViewresultComponent.prototype.checkSize = function () {
        var _this = this;
        this.zone.run(function () {
            if (typeof window !== 'undefined') {
                _this.desktop = window.innerWidth > 767;
            }
        });
    };
    ViewresultComponent.prototype.ngOnInit = function () {
    };
    ViewresultComponent.prototype.ngOnDestroy = function () {
        if (this.cookieSub) {
            this.cookieSub.unsubscribe();
        }
    };
    ViewresultComponent.prototype.ngAfterViewInit = function () {
        this.onLoad();
    };
    ViewresultComponent.prototype.download = function () {
        var that = this;
        var a = document.createElement('a');
        a.href = that.videoData.download_link;
        a.download = 'JOBFLASH-Video.mp4';
        a.click();
    };
    ViewresultComponent.prototype.onLoad = function () {
        var that = this;
        var cookieOverlay = that.metaService.getCookieNoteVisible().value;
        if (cookieOverlay && that.video) {
            that.video.nativeElement.autoplay = false;
            that.video.nativeElement.pause();
            that.videoState = 'pause';
            that.cookieSub = that.metaService.getCookieNoteVisible()
                .pipe(skip(1))
                .subscribe(function (value) {
                if (!value) {
                    that.cookieSub.unsubscribe();
                    that.play();
                }
            });
        }
        else {
            that.play();
        }
    };
    ViewresultComponent.prototype.play = function () {
        if (this.video) {
            this.video.nativeElement.play();
        }
    };
    ViewresultComponent.prototype.setView = function (view) {
        this.view = view;
    };
    ViewresultComponent.prototype.unlock = function () {
        this.videoData.paid = true;
        this.video.nativeElement.load();
        this.setView('overview');
    };
    ViewresultComponent.prototype.toggleVideo = function () {
        switch (this.videoState) {
            case 'playing':
                this.video.nativeElement.pause();
                break;
            case 'ended':
            case 'pause':
                this.video.nativeElement.play();
                break;
            case 'waiting':
                break;
            default:
                this.video.nativeElement.play();
        }
    };
    return ViewresultComponent;
}());
export { ViewresultComponent };
