
import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { JFValidators } from 'app/static/validators'
import { Subject } from 'rxjs'

@Component({
  selector: 'jf-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.sass']
})
export class InputComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: FormControl
  @Input() type: 'textarea' | 'text' | 'email' | 'tel' | 'url' | 'number'
  @Input() showErrors: boolean
  @Input() placeholder: boolean
  @Input() maxLength: number | undefined
  @Input() validators: any[]
  @Input() lowercase = false

  @ViewChild('input') input
  @ViewChild('textarea') textarea

  public inputLength: number
  private destroy: Subject<boolean> = new Subject<boolean>()

  public keys = Object.keys

  constructor () {}

  ngOnInit() {
    if (typeof this.maxLength !== 'undefined') {
      this.validators.push(JFValidators.maxLengthFn(this.maxLength))
      this.control.valueChanges.subscribe(value => {
        this.inputLength = value.length
      })
    }

    if (this.control) {
      this.control.setValidators(this.validators)
      this.control.updateValueAndValidity()
    }
  }

  ngAfterViewInit () {
    const input = this.type === 'textarea' ? this.textarea.nativeElement : this.input.nativeElement
    input.addEventListener('keydown', evt => {
      if (
        input.value.length + 1 > this.maxLength
        && ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].indexOf(evt.key) === -1
      ) {
        evt.preventDefault()
      }
    })
  }

  ngOnDestroy () {
    this.destroy.next(true)
  }
}
