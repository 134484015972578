var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SlideComponent } from 'app/element/slide/slide.component';
var AlmostdoneComponent = /** @class */ (function (_super) {
    __extends(AlmostdoneComponent, _super);
    function AlmostdoneComponent(route, router) {
        var _this = _super.call(this, route, router) || this;
        _this.route = route;
        _this.router = router;
        _this.navData = {
            step: true,
            background: false,
            actions: 'laststep',
        };
        _this.slideClass = true;
        return _this;
    }
    AlmostdoneComponent.prototype.ngOnInit = function () {
    };
    AlmostdoneComponent.prototype.back = function () {
        this.switchPrev();
    };
    AlmostdoneComponent.prototype.forward = function () {
        this.switchNext();
    };
    return AlmostdoneComponent;
}(SlideComponent));
export { AlmostdoneComponent };
