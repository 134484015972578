var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, OnInit } from '@angular/core';
import Glide from '@glidejs/glide';
import { SlideComponent } from 'app/element/slide/slide.component';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'app/service/order.service';
var DesignselectComponent = /** @class */ (function (_super) {
    __extends(DesignselectComponent, _super);
    function DesignselectComponent(route, router, orderService) {
        var _this = _super.call(this, route, router) || this;
        _this.route = route;
        _this.router = router;
        _this.orderService = orderService;
        _this.navData = {
            step: true,
            background: false,
            actions: 'request_fillin',
        };
        _this.slideClass = true;
        _this.index = 0;
        _this.sliderSettings = {
            type: 'carousel',
            // startAt: '', // Start at specific slide number
            perView: 1,
            // focusAt: '', // Focus currently active slide at a specified position
            gap: 0,
        };
        _this.designs = ['black', 'grey', 'white', 'yellow', 'blue'];
        return _this;
    }
    DesignselectComponent.prototype.ngOnInit = function () {
        var current = this.orderService.getFormGroup().get('design_id').value;
        if (current) {
            this.sliderSettings['startAt']
                = this.initial
                    = this.index
                        = this.designs.indexOf(current);
        }
    };
    DesignselectComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var that = this;
        setTimeout(function () {
            var glide = new Glide('.jf-glide', _this.sliderSettings).mount();
            glide.on(['swipe.end'], function () {
                that.index = glide.index;
            });
        }, 400);
    };
    DesignselectComponent.prototype.back = function () {
        this.switchPrev();
    };
    DesignselectComponent.prototype.forward = function () {
        var that = this;
        if (that.initial === that.index) {
            that.switchNext();
            return;
        }
        that.saving = true;
        that.orderService.submitStep(that.data.step_key, that.designs[that.index])
            .then(function () {
            that.saving = false;
            var formGroup = that.orderService.getFormGroup();
            formGroup.patchValue({ design_id: that.designs[that.index] });
            that.switchNext();
        })
            .catch(function () {
            that.saving = false;
            alert('error');
        });
    };
    return DesignselectComponent;
}(SlideComponent));
export { DesignselectComponent };
