import { AfterViewInit, Component, HostBinding, Input, OnInit } from '@angular/core'
import Glide from '@glidejs/glide'
import { SlideComponent } from 'app/element/slide/slide.component'
import { ActivatedRoute, Router } from '@angular/router'
import { OrderService } from 'app/service/order.service'

@Component({
  selector: 'jf-designselect',
  templateUrl: './designselect.component.html',
  styleUrls: ['./designselect.component.sass']
})
export class DesignselectComponent extends SlideComponent implements OnInit, AfterViewInit {

  public navData = {
    step: true,
    background: false,
    actions: 'request_fillin',
  }

  @HostBinding('class.jf-slide') slideClass = true
  @Input() data: any
  public index = 0
  public sliderSettings = {
    type: 'carousel', // Type of the movement
    // startAt: '', // Start at specific slide number
    perView: 1, // A number of visible slides
    // focusAt: '', // Focus currently active slide at a specified position
    gap: 0, // A size of the space between slides
    // autoplay: '', // Change slides after a specified interval
    // hoverpause: '', // Stop autoplay on mouseover
    // keyboard: '', // Change slides with keyboard arrows
    // bound: '', // Stop running perView number of slides from the end
    // swipeThreshold: '', // Minimal swipe distance needed to change the slide
    // dragThreshold: '', // Minimal mousedrag distance needed to change the slide
    // perTouch: '', // A maximum number of slides moved per single swipe or drag
    // touchRatio: '', // Alternate moving distance ratio of swiping and dragging
    // touchAngle: '', // Angle required to activate slides moving
    // animationDuration: '', // Duration of the animation
    // rewind: '', // Allow looping the slider type
    // rewindDuration: '', // Duration of the rewinding animation
    // animationTimingFunc: '', // Easing function for the animation
    // direction: '', // Moving direction mode
    // peek: '', // The value of the future viewports which have to be visible in the current view
    // breakpoints: '', // Collection of options applied at specified media breakpoints
    // classes: '', // Collection of used HTML classes
    // throttle: '', // Throttle costly events
  }

  public designs = ['black', 'grey', 'white', 'yellow', 'blue']
  public initial
  public saving

  constructor (
    public route: ActivatedRoute,
    public router: Router,
    public orderService: OrderService,
  ) {
    super(route, router)
  }

  ngOnInit() {
    const current = this.orderService.getFormGroup().get('design_id').value

    if (current) {
      this.sliderSettings['startAt']
        = this.initial
        = this.index
        = this.designs.indexOf(current)
    }
  }

  ngAfterViewInit () {
    const that = this
    setTimeout(() => {
      const glide = new Glide('.jf-glide', this.sliderSettings).mount()
      glide.on(['swipe.end'], function() {
        that.index = glide.index
      })
    }, 400)
  }

  back () {
    this.switchPrev()
  }

  forward () {
    const that = this

    if (that.initial === that.index) {
      that.switchNext()
      return
    }

    that.saving = true
    that.orderService.submitStep(that.data.step_key, that.designs[that.index])
      .then(() => {
        that.saving = false

        const formGroup = that.orderService.getFormGroup()
        formGroup.patchValue({design_id: that.designs[that.index]})
        that.switchNext()
      })
      .catch(() => {
        that.saving = false
        alert('error')
      })
  }
}
