
<div class="jf-inner">
	<div @show>
		<div class="jf-close" (click)="close.emit()"></div>

		<h2>
			{{ contacts.company }}
			<small>
				Kontakt
			</small>
		</h2>

		<ul>
			<li>
        <mat-icon>account_box</mat-icon>
        <label>Ansprechperson</label>
        {{ contacts.person }}
      </li>
			<li>
        <mat-icon>mail</mat-icon>
        <label>E-Mail</label>
        {{ contacts.mail }}
      </li>
			<li>
        <mat-icon>phone</mat-icon>
        <label>Telefon</label>
        {{ contacts.phone }}
      </li>
			<li>
        <mat-icon>computer</mat-icon>
        <label>Webseite</label>
        {{ contacts.website }}
      </li>
		</ul>
	</div>
</div>
