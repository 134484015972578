import { Component, HostBinding, OnInit, ViewChild } from '@angular/core'
import { SlideComponent } from 'app/element/slide/slide.component'
import { ActivatedRoute, Router } from '@angular/router'
import { AnalyticsService } from 'app/service/analytics.service'

@Component({
  selector: 'jf-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.sass']
})
export class IntroductionComponent extends SlideComponent implements OnInit {

  public navData = {
    step: true,
    background: false,
    actions: 'intro',

  }
  @ViewChild('video') video
  public videoState = 'pause'

  @HostBinding('class.jf-slide') slideClass = true

  constructor (
    public route: ActivatedRoute,
    public router: Router,
    public analytics: AnalyticsService,
  ) {
    super(route, router)
  }

  ngOnInit() {

  }

  back () {
    this.switchPrev()
  }

  forward () {
    if (this.analytics.getState().value) {
      const steps = this.router.config[0].children
      this.router.navigate(['erstellen/' + steps[2].path])
    } else {
      this.switchNext()
    }
  }

  play () {
    this.video.nativeElement.play()
  }

  toggleVideo () {
    switch (this.videoState) {
      case 'playing':
        this.video.nativeElement.pause()
        break
      case 'ended':
      case 'pause':
        this.video.nativeElement.play()
        break
      case 'waiting':
        break
      default:
        this.video.nativeElement.play()
    }
  }
}
