
<img src="/assets/icon/cookie.svg" alt="Cookie Icon">
<div class="jf-text">
  <p>
    Wir verwenden cookies. Durch das Surfen auf
    unserer Seite, stimmst du dieser Nutzung zu.
  </p>
  <label class="jf-checkbox">
    <input type="checkbox" value="1" checked disabled>
    <span class="jf-checkbox-content">
      Cookies <span class="jf-required"></span>

      <small>
        Zur Erleichterung und besseren Zuordnung
        brauchen wir ein paar Cookies.
      </small>
    </span>
  </label>

  <label class="jf-checkbox">
    <input type="checkbox" value="1" [(ngModel)]="anonymous_statistics">
    <span class="jf-checkbox-content">
      Anonyme Datensammlung
      <small>
        Dürfen wir dir beim der Nutzung von <strong style="font-style: italic;">JOBFLASH</strong>
        über die Schulter schauen, um <strong style="font-style: italic;">JOBFLASH</strong> zu verbessern?
      </small>
    </span>
  </label>
</div>
<div class="jf-buttons">
  <div class="jf-btn jf-privacy" (click)="showPrivacy()" target="_blank">Datenschutz</div>
  <div class="jf-btn jf-confirm" (click)="hide()">Speichern</div>
</div>
