import { Component, HostBinding, OnInit, ViewChild } from '@angular/core'
import { SlideComponent } from 'app/element/slide/slide.component'
import { ActivatedRoute, Router } from '@angular/router'
import { OrderInterface } from 'app/api/interfaces/order.interface'
import { OrderService } from 'app/service/order.service'

@Component({
  selector: 'jf-increation',
  templateUrl: './increation.component.html',
  styleUrls: ['./increation.component.sass']
})
export class IncreationComponent extends SlideComponent implements OnInit {
  public done: boolean
  public url: string
  public progress = 0
  public simulatedProgress = 0
  public progressInterval = 0
  public progressStep = 0
  public inqueue = true
  public error: boolean
  public showText = true
  public videoState = 'pause'
  @ViewChild('video') video
  @HostBinding('class.jf-slide') slideClass = true

  constructor (
    public route: ActivatedRoute,
    public router: Router,
    public orderInterface: OrderInterface,
    public orderService: OrderService,
  ) {
    super(route, router)
  }

  ngOnInit() {
    setTimeout(this.check.bind(this), 2000)
    try {
      localStorage.setItem('increation', 'true')
    } catch (e) {}
  }

  check () {
    this.orderInterface
      .getProgress({client_id: this.orderService.getClientID()})
      .subscribe(data => {
        const that = this

        switch (data.status) {
          case 'done':
            window.clearInterval(that.progressInterval)
            that.done = true
            that.url = data.url
            break
          case 'fail':
            window.clearInterval(that.progressInterval)
            that.error = true
            break
          default:
            that.inqueue = data.status === 'queue'
            const newProgress = that.progress !== data.progress
            that.progress = data.progress

            if (newProgress) {
              window.clearInterval(that.progressInterval)
              that.progressStep = 1
              that.progressInterval = window.setInterval(function() {
                that.simulatedProgress = that.progress + (5 - (Math.round(5 / that.progressStep)))
                that.progressStep += 0.02
              }, 250)
            }

            setTimeout(that.check.bind(that), 5000)
        }
      }, () => {
        setTimeout(this.check.bind(this), 5000)
      })
  }

  play () {
    this.video.nativeElement.play()
  }

  toggleVideo () {
    switch (this.videoState) {
      case 'playing':
        this.video.nativeElement.pause()
        break
      case 'ended':
      case 'pause':
        this.video.nativeElement.play()
        break
      case 'waiting':
        break
      default:
        this.video.nativeElement.play()
    }
  }

  forward () {
    this.router.navigateByUrl(this.url)
  }
}
