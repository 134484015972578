var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { SlideComponent } from 'app/element/slide/slide.component';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderInterface } from 'app/api/interfaces/order.interface';
import { OrderService } from 'app/service/order.service';
var IncreationComponent = /** @class */ (function (_super) {
    __extends(IncreationComponent, _super);
    function IncreationComponent(route, router, orderInterface, orderService) {
        var _this = _super.call(this, route, router) || this;
        _this.route = route;
        _this.router = router;
        _this.orderInterface = orderInterface;
        _this.orderService = orderService;
        _this.progress = 0;
        _this.simulatedProgress = 0;
        _this.progressInterval = 0;
        _this.progressStep = 0;
        _this.inqueue = true;
        _this.showText = true;
        _this.videoState = 'pause';
        _this.slideClass = true;
        return _this;
    }
    IncreationComponent.prototype.ngOnInit = function () {
        setTimeout(this.check.bind(this), 2000);
        try {
            localStorage.setItem('increation', 'true');
        }
        catch (e) { }
    };
    IncreationComponent.prototype.check = function () {
        var _this = this;
        this.orderInterface
            .getProgress({ client_id: this.orderService.getClientID() })
            .subscribe(function (data) {
            var that = _this;
            switch (data.status) {
                case 'done':
                    window.clearInterval(that.progressInterval);
                    that.done = true;
                    that.url = data.url;
                    break;
                case 'fail':
                    window.clearInterval(that.progressInterval);
                    that.error = true;
                    break;
                default:
                    that.inqueue = data.status === 'queue';
                    var newProgress = that.progress !== data.progress;
                    that.progress = data.progress;
                    if (newProgress) {
                        window.clearInterval(that.progressInterval);
                        that.progressStep = 1;
                        that.progressInterval = window.setInterval(function () {
                            that.simulatedProgress = that.progress + (5 - (Math.round(5 / that.progressStep)));
                            that.progressStep += 0.02;
                        }, 250);
                    }
                    setTimeout(that.check.bind(that), 5000);
            }
        }, function () {
            setTimeout(_this.check.bind(_this), 5000);
        });
    };
    IncreationComponent.prototype.play = function () {
        this.video.nativeElement.play();
    };
    IncreationComponent.prototype.toggleVideo = function () {
        switch (this.videoState) {
            case 'playing':
                this.video.nativeElement.pause();
                break;
            case 'ended':
            case 'pause':
                this.video.nativeElement.play();
                break;
            case 'waiting':
                break;
            default:
                this.video.nativeElement.play();
        }
    };
    IncreationComponent.prototype.forward = function () {
        this.router.navigateByUrl(this.url);
    };
    return IncreationComponent;
}(SlideComponent));
export { IncreationComponent };
