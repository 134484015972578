
import { FormControl } from '@angular/forms'

export const JFValidators = {
  maxLengthFn: length => {
    return (control: FormControl) => {
      const input = control.value

      if (input.length > length) {
        return {
          company: {
            message: 'Eingabe zu lang'
          }
        }
      }

      return null
    }
  },
  company: (control: FormControl) => {
    const input = control.value

    if (input.length < 2) {
      return {
        company: {
          message: 'zu kurz'
        }
      }
    }

    return null
  },
  website: (control: FormControl) => {
    const input = control.value

    const regex = new RegExp ('^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$')

    if (!input || regex.test(input)) {
      return null
    }

    return {
      company: {
        message: 'ungültig'
      }
    }
  },
  postcode: (control: FormControl) => {
    const input = control.value

    const regex = /^[0-9][0-9][0-9][0-9][0-9]$/

    if (!input || regex.test(input)) {
      return null
    }

    return {
      company: {
        message: 'ungültig'
      }
    }
  },
  phone: (control: FormControl) => {
    const input = control.value

    if (!input || (input.length > 5 && input.match(/^[0-9\-\(\)\+\s]+$/))) {
      return null
    }

    return {
      phone: {
        message: ''
      }
    }
  },
  email: (control: FormControl) => {
    const input = control.value

    if (!input || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input)) {
      return null
    }
    return {
      email: {
        message: 'invalid'
      }
    }
  },
  person: (control: FormControl) => {
    const input = control.value

    if (input && input.length < 3) {
      return {
        person: {
          message: 'zu kurz'
        }
      }
    }

    return null
  },
  attribute: (control: FormControl) => {
    const input = control.value

    if (input && input.length < 3) {
      return {
        attribute: {
          message: 'zu kurz'
        }
      }
    }

    return null
  },
  benefit: (control: FormControl) => {
    const input = control.value

    if (input && input.length < 3) {
      return {
        benefit: {
          message: 'zu kurz'
        }
      }
    }

    return null
  },
}
