
<div>
  <h1 class="jf-headline-l">
    o.O
  </h1>
  <h3>
    Diese Seite konnte nicht gefunden werden
  </h3>

  <a class="jf-anchor" [routerLink]="['/']">
    zur Startseite
  </a>
</div>
