import { Component, HostBinding, OnInit } from '@angular/core'
import { SlideComponent } from 'app/element/slide/slide.component'
import { ActivatedRoute, Router } from '@angular/router'
import { FormControl, FormGroup } from '@angular/forms'
import { OrderService } from 'app/service/order.service'
import { AnalyticsService } from 'app/service/analytics.service'
import { MetaService } from 'app/service/meta.service'

@Component({
  selector: 'jf-legalchecks',
  templateUrl: './legalchecks.component.html',
  styleUrls: ['./legalchecks.component.sass']
})
export class LegalchecksComponent extends SlideComponent implements OnInit {
  public formData: FormGroup
  public saving: boolean

  public navData = {
    step: true,
    background: false,
    actions: 'request_fillin',
  }
  private inital: any

  @HostBinding('class.jf-slide') slideClass = true

  constructor (
    public route: ActivatedRoute,
    public router: Router,
    public analytics: AnalyticsService,
    public metaService: MetaService,
    public orderService: OrderService,
  ) {
    super(route, router)

    const formGroup = this.orderService.getFormGroup()
    this.formData = new FormGroup({
      anonymous_statistics: new FormControl(formGroup.get('anonymous_statistics').value, []),
    })

    this.inital = this.formData.value
  }

  ngOnInit() {

  }

  back () {
    this.switchPrev()
  }

  isValid () {
    return this.formData.valid
  }

  submit (data: any) {
    return this.orderService.submitStep('legalchecks', data)
  }

  forward () {
    const that = this
    const data = that.formData.value

    if (that.isValid()) {
      that.analytics.setState(this.formData.get('anonymous_statistics').value)
      that.metaService.setCookieNoteVisible(false)

      if (JSON.stringify(that.inital) === JSON.stringify(that.formData.value) ) {
        that.switchNext()
        return
      }

      that.saving = true
      that.submit(data)
        .then(() => {
          that.saving = false

          const formGroup = that.orderService.getFormGroup()
          formGroup.patchValue(data)

          that.switchNext()
        })
        .catch(() => {
          that.saving = false
          alert('error')
        })
    }
  }
}
