/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./adbanner.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./adbanner.component";
var styles_AdbannerComponent = [i0.styles];
var RenderType_AdbannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdbannerComponent, data: {} });
export { RenderType_AdbannerComponent as RenderType_AdbannerComponent };
function View_AdbannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Advertisment"], ["src", "/assets/ad/image.jpg"]], null, null, null, null, null))], null, null); }
export function View_AdbannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdbannerComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.format === "small-hori"); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AdbannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "jf-adbanner", [], [[8, "className", 0]], null, null, View_AdbannerComponent_0, RenderType_AdbannerComponent)), i1.ɵdid(1, 49152, null, 0, i3.AdbannerComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).hostClass; _ck(_v, 0, 0, currVal_0); }); }
var AdbannerComponentNgFactory = i1.ɵccf("jf-adbanner", i3.AdbannerComponent, View_AdbannerComponent_Host_0, { format: "format" }, {}, []);
export { AdbannerComponentNgFactory as AdbannerComponentNgFactory };
