
<div class="jf-inner">
	<div @show>
		<div class="jf-close" (click)="close.emit()"></div>

		<h2>Nutze dein Netzwerk</h2>

		<div class="jf-platforms">
			<a class="jf-platform" target="_blank" *ngFor="let platform of platforms" [href]="platform.link">
				<img src="/assets/icon/social/color/{{ platform.icon }}">
				<p>{{ platform.label }}</p>
			</a>
		</div>

		<div class="jf-btn" (click)="clipboard()">
      <ng-container *ngIf="!copied">Link kopieren</ng-container>
      <ng-container *ngIf="copied"><mat-icon>check</mat-icon> Kopiert</ng-container>
    </div>
	</div>
</div>
