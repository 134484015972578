var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { SlideComponent } from 'app/element/slide/slide.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from 'app/service/analytics.service';
var IntroductionComponent = /** @class */ (function (_super) {
    __extends(IntroductionComponent, _super);
    function IntroductionComponent(route, router, analytics) {
        var _this = _super.call(this, route, router) || this;
        _this.route = route;
        _this.router = router;
        _this.analytics = analytics;
        _this.navData = {
            step: true,
            background: false,
            actions: 'intro',
        };
        _this.videoState = 'pause';
        _this.slideClass = true;
        return _this;
    }
    IntroductionComponent.prototype.ngOnInit = function () {
    };
    IntroductionComponent.prototype.back = function () {
        this.switchPrev();
    };
    IntroductionComponent.prototype.forward = function () {
        if (this.analytics.getState().value) {
            var steps = this.router.config[0].children;
            this.router.navigate(['erstellen/' + steps[2].path]);
        }
        else {
            this.switchNext();
        }
    };
    IntroductionComponent.prototype.play = function () {
        this.video.nativeElement.play();
    };
    IntroductionComponent.prototype.toggleVideo = function () {
        switch (this.videoState) {
            case 'playing':
                this.video.nativeElement.pause();
                break;
            case 'ended':
            case 'pause':
                this.video.nativeElement.play();
                break;
            case 'waiting':
                break;
            default:
                this.video.nativeElement.play();
        }
    };
    return IntroductionComponent;
}(SlideComponent));
export { IntroductionComponent };
