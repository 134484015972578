
<p class="jf-step" *ngIf="data?.steps">Video 16</p>

<div class="jf-video-number" *ngIf="data?.video_number">
  {{ data?.video_number }}
</div>
<h2 *ngIf="data?.headline">
  {{ data?.headline }}
</h2>
<h2 *ngIf="data?.actions === 'confirm'">
  Bist du zufrieden?
</h2>
<h2 *ngIf="data?.actions === 'intro'">
  Was ist <strong style="font-style: italic;">JOBFLASH</strong>?
</h2>

<p class="jf-desc" *ngIf="data?.text">
  {{ data?.text }}
</p>
<p class="jf-desc" *ngIf="data?.actions === 'intro'">
  Die Recruiting-Innovation.<br>
  Per Video-Botschaft die richtigen Mitarbeiter/innen für dein Unternehmen gewinnen!
</p>

<div class="jf-btns jf-request-record" *ngIf="data?.actions === 'request_record'">
  <div class="jf-btn jf-btn-prev" (click)="reqPrev()"></div>
  <div class="jf-btn jf-btn-next" [ngClass]="{'jf-btn-disabled': disableNext, 'jf-btn-loading': loading}" (click)="reqNext()">
    Aufnehmen!
  </div>
</div>

<div class="jf-btns jf-confirm" *ngIf="data?.actions === 'confirm'">
  <div class="jf-btn jf-btn-prev" (click)="reqPrev()"></div>
  <div class="jf-btn jf-btn-next" [ngClass]="{'jf-btn-disabled': disableNext, 'jf-btn-loading': loading}" (click)="reqNext()">
    Gefällt mir!
  </div>
</div>

<div class="jf-btns jf-laststep" *ngIf="data?.actions === 'laststep'">
  <div class="jf-btn jf-btn-prev" (click)="reqPrev()"></div>
  <div class="jf-btn jf-btn-next" [ngClass]="{'jf-btn-disabled': disableNext, 'jf-btn-loading': loading}" (click)="reqNext()">
    Zum letzten Schritt
  </div>
</div>

<div class="jf-btns jf-confirm" *ngIf="data?.actions === 'request_fillin'">
  <div class="jf-btn jf-btn-prev" (click)="reqPrev()"></div>
  <div class="jf-btn jf-btn-next" *ngIf="!skip" [ngClass]="{'jf-btn-disabled': disableNext, 'jf-btn-loading': loading}" (click)="reqNext()">
    Speichern
  </div>
  <div class="jf-btn jf-btn-next" *ngIf="skip"  [ngClass]="{'jf-btn-disabled': disableNext, 'jf-btn-loading': loading}" (click)="reqNext()">
    Überspringen
  </div>
</div>

<div class="jf-btns jf-confirm" *ngIf="data?.actions === 'createvideo'">
  <div class="jf-btn jf-btn-prev" (click)="reqPrev()"></div>
  <div class="jf-btn jf-btn-next" [ngClass]="{'jf-btn-disabled': disableNext, 'jf-btn-loading': loading}" (click)="reqNext()">
    Video erstellen
  </div>
</div>

<div class="jf-btns jf-back" *ngIf="data?.actions === 'back'">
  <div class="jf-btn jf-btn-prev" (click)="reqPrev()"></div>
</div>

<div class="jf-btns jf-start" *ngIf="data?.actions === 'intro'">
  <div class="jf-btn jf-btn-next" (click)="reqNext()">
    Los geht's
  </div>
</div>
