import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { JFValidators } from 'app/static/validators'
import { DataInterface } from 'app/api/interfaces/data.interface'
import { HeaderService } from 'app/service/header.service'
import { Router } from '@angular/router'
import { priceList } from 'app/static/prices'

@Component({
  selector: 'jf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

  public show: boolean
  public view = 'menu'
  public formData: FormGroup
  public validators: any
  public showErrors: boolean
  public sending: boolean
  public send: boolean

  public packages = priceList
  public currPack

  constructor (
    private headerService: HeaderService,
    private router: Router,
    private dataInterface: DataInterface,
  ) {
    this.headerService.getTab()
      .subscribe(view => this.view = view)
    this.headerService.getShow()
      .subscribe(show => this.show = show)

    this.formData = new FormGroup({
      name: new FormControl(''),
      mail: new FormControl(''),
      message: new FormControl(''),
    })
    this.validators = {
      name: [Validators.required, JFValidators.person],
      mail: [Validators.required, JFValidators.email],
      message: [Validators.required],
    }
  }

  ngOnInit () {
    this.currPack = this.packages[0]
  }

  setPackage (key: string) {
    this.currPack =  this.packages.filter(pack => {
      return pack.key === key
    })[0]
  }

  toggleShow (state = !this.show) {
    this.headerService.setShow(state)

    if (state) {return}
    setTimeout(() =>  {
      this.setView('menu')
    }, 1000)
  }

  setView (view: string) {
    this.headerService.setTab(view)
  }

  navCreate () {
    this.toggleShow(false)
    this.router.navigate(['/erstellen'])
  }

  submit () {
    this.showErrors = true
    if (!this.formData.valid || this.sending) {return}

    this.sending = true
    this.dataInterface.submitContact(this.formData.value)
      .subscribe(() => {
        this.sending = false
        this.send = true
        this.formData.reset()
      }, () => {
        this.sending = false
        // TODO error handling
      })
  }
}
