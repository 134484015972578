var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'app/service/order.service';
import { SlideComponent } from 'app/element/slide/slide.component';
var LogoselectComponent = /** @class */ (function (_super) {
    __extends(LogoselectComponent, _super);
    function LogoselectComponent(route, router, orderService) {
        var _this = _super.call(this, route, router) || this;
        _this.route = route;
        _this.router = router;
        _this.orderService = orderService;
        _this.navData = {
            step: true,
            background: false,
            actions: 'request_fillin',
        };
        _this.rotation = {
            1: 'rotate(0deg)',
            3: 'rotate(180deg)',
            6: 'rotate(90deg)',
            8: 'rotate(270deg)',
        };
        _this.slideClass = true;
        return _this;
    }
    LogoselectComponent.prototype.ngOnInit = function () {
        var current = this.orderService.getFormGroup().get('company_logo').value;
        if (current) {
            this.logoData = current;
            this.validLogo = true;
            this.keep = true;
        }
    };
    LogoselectComponent.prototype.ngOnDestroy = function () {
    };
    LogoselectComponent.prototype.check = function () {
        var that = this;
        if (!that.input) {
            return;
        }
        that.logoData = undefined;
        that.logoFile = undefined;
        that.keep = false;
        var file = that.input.nativeElement.files[0];
        if (['image/png', 'image/jpeg', 'image/gif'].indexOf(file.type) === -1) {
            alert('Bitte lade eine Bilddatei hoch (keine ' + file.type + ')');
            that.validLogo = false;
            return;
        }
        that.preview(file);
        that.logoFile = file;
        that.validLogo = true;
    };
    LogoselectComponent.prototype.clear = function (evt) {
        this.logoData = undefined;
        this.keep = false;
        this.validLogo = false;
        evt.stopPropagation();
    };
    LogoselectComponent.prototype.preview = function (image) {
        var that = this;
        if (!image) {
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = function () {
            that.logoData = reader.result;
        };
        that.show(image);
    };
    LogoselectComponent.prototype.show = function (image) {
        var that = this;
        that.orientation(image, function (base64img, value) {
            if (value) {
                that.currRotation = that.rotation[value];
            }
        });
    };
    LogoselectComponent.prototype.upload = function () {
        var that = this;
        that.uploading = true;
        that.uploading = true;
        that.orderService.submitImage(that.data.step_key, this.logoFile)
            .then(function (resp) {
            that.uploading = false;
            that.orderService.getFormGroup().patchValue({ 'company_logo': resp.path });
            that.switchNext();
        })
            .catch(function () {
            that.uploading = false;
            console.error('file upload error');
        });
    };
    LogoselectComponent.prototype.remove = function () {
        var that = this;
        that.uploading = true;
        that.orderService.removeImage('company_logo')
            .then(function (resp) {
            that.uploading = false;
            that.orderService.getFormGroup().patchValue({ 'company_logo': undefined });
            that.switchNext();
        })
            .catch(function () {
            that.uploading = false;
            console.error('file upload error');
        });
    };
    LogoselectComponent.prototype.back = function () {
        this.switchPrev();
    };
    LogoselectComponent.prototype.forward = function () {
        if (this.keep) {
            this.switchNext();
        }
        else if (!this.validLogo) {
            this.remove();
        }
        else {
            this.upload();
        }
    };
    LogoselectComponent.prototype.reqUpload = function () {
        this.input.nativeElement.click();
    };
    LogoselectComponent.prototype._arrayBufferToBase64 = function (buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };
    LogoselectComponent.prototype.orientation = function (file, callback) {
        var that = this;
        var fileReader = new FileReader();
        fileReader.onloadend = function () {
            var base64img = 'data:' + file.type + 'base64,' + that._arrayBufferToBase64(fileReader.result);
            var scanner = new DataView(fileReader.result);
            var idx = 0;
            var value = 1; // Non-rotated is the default
            if (fileReader.result['length'] < 2 || scanner.getUint16(idx) !== 0xFFD8) {
                if (callback) {
                    callback(base64img, value);
                }
                return;
            }
            idx += 2;
            var maxBytes = scanner.byteLength;
            while (idx < maxBytes - 2) {
                var uint16 = scanner.getUint16(idx);
                idx += 2;
                switch (uint16) {
                    case 0xFFE1:
                        var exifLength = scanner.getUint16(idx);
                        maxBytes = exifLength - idx;
                        idx += 2;
                        break;
                    case 0x0112:
                        value = scanner.getUint16(idx + 6, false);
                        maxBytes = 0;
                        break;
                }
            }
            if (callback) {
                callback(base64img, value);
            }
        };
        fileReader.readAsArrayBuffer(file);
    };
    return LogoselectComponent;
}(SlideComponent));
export { LogoselectComponent };
