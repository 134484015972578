import { EventEmitter, OnInit } from '@angular/core';
var SlidenavComponent = /** @class */ (function () {
    function SlidenavComponent() {
        this.prev = new EventEmitter();
        this.next = new EventEmitter();
        this.skip = false;
    }
    Object.defineProperty(SlidenavComponent.prototype, "classes", {
        get: function () {
            var classes = [];
            if (this.data.background) {
                classes.push('jf-background');
            }
            return classes.join(' ');
        },
        enumerable: true,
        configurable: true
    });
    SlidenavComponent.prototype.ngOnInit = function () {
    };
    SlidenavComponent.prototype.reqPrev = function () {
        if (this.loading) {
            return;
        }
        this.prev.emit();
    };
    SlidenavComponent.prototype.reqNext = function () {
        if (this.loading) {
            return;
        }
        this.next.emit();
    };
    return SlidenavComponent;
}());
export { SlidenavComponent };
