
<div class="jf-content" [formGroup]="formData">
	<h2>
		Beantworte noch folgende Fragen…

	</h2>

	<label>
    Welche/n Kollegin/Kollegen suchst du?
	</label>

	<label class="jf-radio">
		<input type="radio" value="trainee" formControlName="job_position">
		<span class="jf-radio-content">Azubi</span>
	</label>
	<label class="jf-radio">
		<input type="radio" value="employee" formControlName="job_position">
		<span class="jf-radio-content">Fach-/Mitarbeiter</span>
	</label>
	<label class="jf-radio">
		<input type="radio" value="leader" formControlName="job_position">
		<span class="jf-radio-content">Führungskraft</span>
	</label>

	<label>
		Wie heißt der Job?
	</label>
  <jf-input type="text" placeholder="Industriemechaniker, Marketingleiter" [control]="formData.get('job_description')" [maxLength]="30" [validators]="desc_validators" class="jf-desc"></jf-input>

	<label>
		Jobbranche
	</label>

	<div class="jf-select">
		<select formControlName="job_sector">
			<option [value]="''">Wähle eine Branche</option>
			<option value="H">Handwerk</option>
			<option value="DL">Dienstleistung</option>
			<option value="SGM">Soziales, Gesundheit & Medizin</option>
			<option value="HV">Handel / Vertrieb (kaufmännisch)</option>
			<option value="I">Industrie (technisch)</option>
		</select>
	</div>
</div>

<jf-slidenav [loading]="saving" [disableNext]="!isValid()" [data]="navData" (prev)="back()" (next)="forward()"></jf-slidenav>
