

<jf-header></jf-header>

<img class="jf-logo" src="/assets/brand/logo.svg" alt="JOBFLASH Logo">
<h1>
  <span style="display: none">JOBFLASH</span>
</h1>

<div class="container jf-intro">
  <header>
    <h2>
      <div class="jf-preline">Professionelle und erfolgreiche</div>
      Recruiting-Filme
      <div class="jf-diy">ganz einfach selber produzieren</div>
    </h2>
  </header>

  <jf-videoplayer [autoplay]="false" poster="/assets/index/cover-image.jpg" video_url="/assets/index/startvideo.mp4"></jf-videoplayer>

  <div class="jf-content">
    Heute entscheiden sich qualifizierte Mitarbeiter für die Unternehmen, die das beste
    Gesamtpaket bieten:<br>
    Neben Freiraum, Eigenverantwortung und Entwicklungsmöglichkeiten werden
    gerade für jüngere Bewerber weiche Faktoren wie
    Unternehmenskultur, Authentizität und Sinnhaftigkeit immer wichtiger.
  </div>
</div>

<!--
<div class="container jf-partners">
  <div class="jf-logowall">
    <img src="/assets/index/partners/partner.svg" alt="partner">
    <img src="/assets/index/partners/partner.svg" alt="partner">
    <img src="/assets/index/partners/partner.svg" alt="partner">
    <img src="/assets/index/partners/partner.svg" alt="partner">
    <img src="/assets/index/partners/partner.svg" alt="partner">
  </div>
</div>
-->

<div class="jf-example">
  <img src="/assets/index/polygon-02.png" alt="Polygon" class="jf-left">
  <div class="container">
    <div class="jf-video-example">
      <div class="jf-phone">
        <div class="jf-inner">
          <img src="/assets/designs/phone.png">
          <div style="background-image: url(/assets/index/lotte-cover-01.jpg)"></div>
        </div>
      </div>
      <div class="jf-text">
        <h2>Recruiting per Video</h2>
        <p class="jf-intro-text">
          Unsere innovative Stellenausschreibung via
          Video gibt Ihnen die Möglichkeit, sich in allen
          Facetten als attraktiver Arbeitgeber zu
          präsentieren. Gleichzeitig sind die Videos die
          perfekte Unterstützung für Ihr Recruiting
          während und nach COVID19: Kontaktlos und
          trotzdem sehr authentisch und gewinnend.
          Mit <strong style="font-style: italic;">JOBFLASH</strong> können Sie in nur 20 Minuten Ihren
          persönlichen Recruiting-Film selbst
          produzieren: Ganz einfach, ganz schnell und
          ganz professionell.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="jf-example">
  <img src="/assets/index/polygon-01.png" alt="Polygon" class="jf-right">
  <div class="container">

    <h2>
      Präsentieren Sie sich mit einem Video von Ihren besten Seiten und
      gewinnen Sie so die richtigen Mitarbeiter und „High Potentials“.
    </h2>
    <div class="jf-video-example">
      <div class="jf-phone">
        <div class="jf-inner">
          <img src="/assets/designs/phone.png">
          <div style="background-image: url(/assets/index/lotte-cover-02.jpg)"></div>
        </div>
      </div>
      <div class="jf-text">
        <p class="jf-how-it-works">Und so einfach geht’s:</p>
        <ul>
          <li>Sie starten <strong style="font-style: italic;">JOBFLASH</strong> über ein Smartphone einfach im Browser.</li>
          <li>Sie präsentieren Ihr Unternehmen, den Spirit, das Team und den Job in kurzen Video-Sequenzen.</li>
          <li>Sie können problemlos Ihr Firmenlogo integrieren und eine Farbwelt (z.B. Ihre CI Farbe) wählen.</li>
          <li>Ihr professionelles Video wird durch die von <strong style="font-style: italic;">JOBFLASH</strong> entwickelte Software automatisch erstellt.</li>
          <li>Wir betreuen Sie bei der Video-Produktion von Anfang bis Ende persönlich. Es kann also nichts schief gehen.</li>
        </ul>

        <p class="jf-how-it-works">Ihre Vorteile:</p>
        <ul>
          <li>
            <strong>Sehr schnell</strong>:
            Sie produzieren in nur rund 20 Minuten Ihr eigenes, professionelles und authentisches Recruiting-Video.
          </li>
          <li>
            <strong>Sehr einfach</strong>:
            Durch unsere Prozess-Automatisierung benötigen sie nur ein Smartphone, keine externen Dienstleister und keinen App-Download.
          </li>
          <li>
            <strong>Sehr kostengünstig</strong>:
            Sie können das Video kostenlos in Ihrem Netzwerk teilen oder herunterladen und dann in Ihre Stellenanzeige oder Webseite einbinden.
          </li>
          <li>
            <strong>Sehr erfolgreich</strong>:
            Recruiting Videos sind authentisch, emotional und persönlich – und das bringt den Erfolg!
          </li>
          <li>
            <strong>Sehr sicher</strong>:
            Innovatives, kontaktloses Recruiting während und nach COVID19.
          </li>
        </ul>

      </div>
    </div>

    <div class="jf-platforms-headline">
      <h2>
        Wo Sie das <strong style="font-style: italic;">JOBFLASH</strong> Video überall einsetzen können:
      </h2>
    </div>
    <div class="jf-platforms">
      <div class="jf-platform">
        <img src="/assets/icon/platforms/xing.svg" alt="mail">
        <p>Xing</p>
      </div>
      <div class="jf-platform">
        <img src="/assets/icon/platforms/linkedin.svg" alt="linkedin">
        <p>LinkedIn</p>
      </div>
      <div class="jf-platform">
        <img src="/assets/icon/platforms/job-platform.svg" alt="job-platform">
        <p>Job-Portale</p>
      </div>
      <div class="jf-platform">
        <img src="/assets/icon/platforms/facebook.svg" alt="facebook">
        <p>Facebook</p>
      </div>
      <div class="jf-platform">
        <img src="/assets/icon/platforms/insta.svg" alt="instagram">
        <p>Instagram</p>
      </div>
      <div class="jf-platform">
        <img src="/assets/icon/platforms/mail.svg" alt="mail">
        <p>Mail</p>
      </div>
      <div class="jf-platform">
        <img src="/assets/icon/platforms/etc.svg" alt="etc">
        <p>u.v.m.</p>
      </div>
    </div>
  </div>
</div>


<div class="jf-prices">
  <img src="/assets/index/polygon-04.png" alt="Polygon" class="jf-left">

  <div class="container">
    <h2>Preise</h2>

    <div class="jf-packages">
      <div class="jf-package" *ngFor="let pack of packages">
        <div class="jf-inner">
          <div *ngIf="pack.banner" class="jf-banner" [innerHTML]="pack.banner"></div>

          <div class="jf-name">{{ pack.name }}</div>
          <div class="jf-price" [innerHTML]="pack.price"></div>
          <p class="jf-desc" [innerHTML]="pack.desc"></p>

          <a class="jf-btn" *ngIf="pack.btn?.type === 'create'" [routerLink]="['/erstellen']">
            {{ pack.btn?.text }}
          </a>
          <div class="jf-btn" *ngIf="pack.btn?.type === 'contact'" (click)="showPage('cooperation')">
            {{ pack.btn?.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="jf-cooperation">
  <img src="/assets/index/polygon-05.png" alt="Polygon" class="jf-left">

  <div class="container">
    <h2>Kooperation</h2>

    <p class="jf-large-text">
      Sie haben weitere Ideen zu <strong style="font-style: italic;">JOBFLASH</strong> oder Interesse an einer Zusammenarbeit?
      Dann schreiben Sie uns gerne eine Nachricht.<br>
      <br>
      <a href="mail:kooperation@jobflash.de">kooperation@jobflash.de</a>
    </p>
  </div>
</div>

<div class="container jf-contact">
  <h2>Kontakt</h2>

  <p class="jf-large-text">
    Wir freuen uns auf Ihre Fragen und Anregungen.
  </p>

  <div class="jf-form" *ngIf="!send">
    <jf-input type="text" [showErrors]="showErrors" [control]="formData.get('name')" placeholder="Name*" [validators]="validators.name" class="jf-name"></jf-input>
    <jf-input type="email" [showErrors]="showErrors" [control]="formData.get('mail')" placeholder="E-Mail-Adresse*" [validators]="validators.mail" class="jf-mail"></jf-input>
    <jf-input type="tel" [showErrors]="showErrors" [control]="formData.get('phone')" placeholder="Telefonnummer" [validators]="validators.phone" class="jf-mail"></jf-input>
    <select [formControl]="formData.get('topic')">
      <option value="">Wähle ein Thema*</option>
      <option value="general">Allgemeine Anfrage</option>
      <option value="account">Nutzerkonto</option>
      <option value="cooperation">Kooperation</option>
    </select>
    <jf-input type="textarea" [showErrors]="showErrors" [control]="formData.get('message')" placeholder="Nachricht*" [validators]="validators.message" class="jf-message"></jf-input>
    <button class="jf-btn jf-submit" [ngClass]="{'jf-disabled': !formData.valid, 'jf-sending': sending}" (click)="submit()">Abschicken</button>
  </div>
  <div class="jf-success" *ngIf="send">
    <mat-icon>done</mat-icon>

    <h3>
      Nachricht wurde versandt
    </h3>

    <p>
      Vielen Dank für deine Nachricht an das <strong style="font-style: italic;">JOBFLASH</strong>-Team.
      Wir kümmern uns um dein Anliegen und kontaktieren dich schnellstmöglich.
    </p>

    <a class="jf-btn jf-submit" (click)="send = false; showErrors = false">
      Neue Nachricht
    </a>
  </div>
</div>

<footer>
  <img src="/assets/index/polygon-03.png" alt="Polygon" class="jf-poly">
  <img src="/assets/brand/logo-grey.svg" alt="Logo JOBFLASH" class="jf-logo"><br>
  <div class="jf-text">
    @ Copyright <strong style="font-style: italic;">JOBFLASH</strong> 2020 <span class="jf-minus">-</span>
    <a (click)="showPage('imprint')">Impressum</a> <span class="jf-minus">-</span>
    <a (click)="showPage('terms')">AGB</a> <span class="jf-minus">-</span>
    <a (click)="showPage('privacy')">Datenschutz</a>
  </div>
</footer>
