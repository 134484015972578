import { Component, HostBinding, Input } from '@angular/core'

@Component({
  selector: 'jf-adbanner',
  templateUrl: './adbanner.component.html',
  styleUrls: ['./adbanner.component.sass']
})
export class AdbannerComponent {

  @HostBinding('class') get hostClass () {
    return 'jf-' + this.format
  }
  @Input() format: 'small-hori'

  constructor () {}

}
