
import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgxCaptchaModule } from 'ngx-captcha'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AppRoutesModule } from 'app/app.routes.module'
import { AppComponent } from 'app/app.component'

import { NotfoundComponent } from 'app/page/notfound/notfound.component'
import { HeaderComponent } from 'app/element/header/header.component'
import { EditorComponent } from 'app/page/editor/editor.component'
import { IbanPaymentComponent } from 'app/element/checkout/iban-payment/iban-payment.component'
import { CcPaymentComponent } from 'app/element/checkout/cc-payment/cc-payment.component'

import { WINDOW_PROVIDERS } from 'app/service/window.service'
import { MetaService } from 'app/service/meta.service'
import { BrowserService } from 'app/service/browser.service'
import { AnalyticsService } from 'app/service/analytics.service'
import { ScrollToDirective } from 'lib/scrollto/scrollTo.directive'
import { ScrollToService } from 'lib/scrollto/scrollTo.service'
import { TextareaAutosizeModule } from 'ngx-textarea-autosize'
import { OrderInterface } from 'app/api/interfaces/order.interface'
import { DataInterface } from 'app/api/interfaces/data.interface'
import { HttpClientModule } from '@angular/common/http'
import { SlidenavComponent } from 'app/element/slidenav/slidenav.component'
import { JobdetailsComponent } from 'app/element/slide/jobdetails/jobdetails.component'
import { SelfdescComponent } from 'app/element/slide/selfdesc/selfdesc.component'
import { ContactdetailsComponent } from 'app/element/slide/contactdetails/contactdetails.component'
import { DesignselectComponent } from 'app/element/slide/designselect/designselect.component'
import { LogoselectComponent } from 'app/element/slide/logoselect/logoselect.component'
import { AlmostdoneComponent } from 'app/element/slide/almostdone/almostdone.component'
import { IncreationComponent } from 'app/element/slide/increation/increation.component'
import { ViewresultComponent } from 'app/element/slide/viewresult/viewresult.component'
import { ShareComponent } from 'app/element/slide/viewresult/share/share.component'
import { PurchaseComponent } from 'app/element/slide/viewresult/purchase/purchase.component'
import { PublishedvideoComponent } from 'app/element/slide/publishedvideo/publishedvideo.component'
import { AdbannerComponent } from 'app/element/adbanner/adbanner.component'
import { OrderService } from 'app/service/order.service'
import { QuestionslideComponent } from 'app/element/slide/questionslide/questionslide.component'
import { IntroductionComponent } from 'app/element/slide/introduction/introduction.component'
import { LegalchecksComponent } from 'app/element/slide/legalchecks/legalchecks.component'
import { RecipientComponent } from 'app/element/slide/recipient/recipient.component'
import { MatIconModule } from '@angular/material'
import { InputComponent } from 'app/element/input/input.component'
import { ClientContactsComponent } from 'app/element/slide/publishedvideo/client-contacts/client-contacts.component'
import { HeaderService } from 'app/service/header.service'
import { CookieComponent } from 'app/element/cookie/cookie.component'
import { IndexComponent } from 'app/page/index/index.component'
import { VideoplayerComponent } from 'app/element/videoplayer/videoplayer.component'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NotfoundComponent,
    EditorComponent,
    ScrollToDirective,
    SlidenavComponent,
    QuestionslideComponent,
    JobdetailsComponent,
    SelfdescComponent,
    ContactdetailsComponent,
    DesignselectComponent,
    LogoselectComponent,
    AlmostdoneComponent,
    RecipientComponent,
    IncreationComponent,
    ViewresultComponent,
    ShareComponent,
    CookieComponent,
    PurchaseComponent,
    PublishedvideoComponent,
    ClientContactsComponent,
    AdbannerComponent,
    IntroductionComponent,
    IbanPaymentComponent,
    CcPaymentComponent,
    LegalchecksComponent,
    InputComponent,
    VideoplayerComponent,
    IndexComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'jobflash' }),
    AppRoutesModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    TextareaAutosizeModule,
    MatIconModule,
    HttpClientModule,
  ],
  providers: [
    ScrollToService,
    BrowserService,
    OrderService,
    OrderInterface,
    DataInterface,
    AnalyticsService,
    HeaderService,
    MetaService,
    WINDOW_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
