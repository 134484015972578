
<ng-container *ngIf="client">
  <ng-container *ngIf="!notFound">
    <ng-container *ngIf="!initialized">
      <img class="jf-logo" src="/assets/brand/logo.svg" alt="JOBFLASH Logo">
      <div class="jf-loader" *ngIf="showLoading"></div>
    </ng-container>

    <ng-container *ngIf="initialized">
      <jf-header></jf-header>

      <jf-cookie *ngIf="showCookie"></jf-cookie>

      <div class="jf-slide-content">
        <div class="jf-video-frame">
          <div class="jf-video">
            <video
              #video
              autoplay
              playsinline
              (load)="play()"
              (waiting)="videoState = 'waiting'"
              (ended)="videoState = 'ended'"
              (playing)="videoState = 'playing'"
              (pause)="videoState = 'pause'"
              (click)="toggleVideo()"
            >
              <source [src]="videoData.paid ? videoData.paid_video_url : videoData.video_url" type="video/mp4">
            </video>
            <div class="jf-replay" *ngIf="videoState === 'ended'" (click)="play()">
              <mat-icon>replay</mat-icon>
            </div>
            <div class="jf-play" *ngIf="videoState === 'pause'" (click)="play()">
              <mat-icon>play_arrow</mat-icon>
            </div>
            <div class="jf-loading" *ngIf="videoState === 'waiting'"></div>
          </div>

          <jf-adbanner class="jf-desktop" *ngIf="!videoData.paid && desktop" [format]="'small-hori'"></jf-adbanner>
        </div>

        <div class="jf-icons" [ngClass]="{'jf-active': view === 'overview'}">
          <div class="jf-share" (click)="setView('share')" *ngIf="!desktop">
            <mat-icon>share</mat-icon>
            teilen
          </div>
          <div class="jf-contact" (click)="setView('contacts')" *ngIf="!desktop">
            <mat-icon>perm_phone_msg</mat-icon>
            kontakt
          </div>

          <jf-adbanner class="jf-desktop" *ngIf="!videoData.paid && !desktop" [format]="'small-hori'"></jf-adbanner>
          <jf-client-contacts [ngClass]="{'jf-desktop': desktop}" [contacts]="videoData.contacts" *ngIf="view === 'contacts' || desktop" (close)="setView('overview')"></jf-client-contacts>
          <jf-share [ngClass]="{'jf-desktop': desktop}" *ngIf="view === 'share' || desktop" [url]="videoData.share_url" (close)="setView('overview')"></jf-share>
        </div>

      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="notFound">
    <img class="jf-logo" src="/assets/brand/logo.svg" alt="JOBFLASH Logo">
    <div class="jf-404">Seite kann nicht geöffnet werden</div>
  </ng-container>

</ng-container>
