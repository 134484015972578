var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'app/service/order.service';
import { SlideComponent } from 'app/element/slide/slide.component';
var JobdetailsComponent = /** @class */ (function (_super) {
    __extends(JobdetailsComponent, _super);
    function JobdetailsComponent(route, router, orderService) {
        var _this = _super.call(this, route, router) || this;
        _this.route = route;
        _this.router = router;
        _this.orderService = orderService;
        _this.navData = {
            step: true,
            background: false,
            actions: 'request_fillin',
        };
        _this.desc_validators = [Validators.required];
        _this.slideClass = true;
        var formGroup = _this.orderService.getFormGroup();
        _this.formData = new FormGroup({
            job_position: new FormControl(formGroup.get('job_position').value, [Validators.required]),
            job_sector: new FormControl(formGroup.get('job_sector').value, [Validators.required]),
            job_description: new FormControl(formGroup.get('job_description').value),
        });
        _this.inital = _this.formData.value;
        return _this;
    }
    JobdetailsComponent.prototype.ngOnInit = function () {
    };
    JobdetailsComponent.prototype.back = function () {
        this.switchPrev();
    };
    JobdetailsComponent.prototype.isValid = function () {
        return this.formData.valid;
    };
    JobdetailsComponent.prototype.submit = function (data) {
        return this.orderService.submitStep(this.data.step_key, data);
    };
    JobdetailsComponent.prototype.forward = function () {
        var that = this;
        var data = that.formData.value;
        if (that.isValid()) {
            if (JSON.stringify(that.inital) === JSON.stringify(that.formData.value)) {
                that.switchNext();
                return;
            }
            that.saving = true;
            that.submit(data)
                .then(function () {
                that.saving = false;
                var formGroup = that.orderService.getFormGroup();
                formGroup.patchValue(data);
                that.switchNext();
            })
                .catch(function () {
                that.saving = false;
                alert('error');
            });
        }
    };
    return JobdetailsComponent;
}(SlideComponent));
export { JobdetailsComponent };
