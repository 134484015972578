
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core'
import { MetaService } from 'app/service/meta.service'

@Component({
  selector: 'jf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor (
    // import to activate
    private metaService: MetaService,
  ) {}

  ngOnInit() {

  }

  ngAfterViewInit () {

  }

  ngOnDestroy () {

  }
}
