import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'jf-slidenav',
  templateUrl: './slidenav.component.html',
  styleUrls: ['./slidenav.component.sass']
})
export class SlidenavComponent implements OnInit {

  @Output() prev: EventEmitter<void> = new EventEmitter<void>()
  @Output() next: EventEmitter<void> = new EventEmitter<void>()

  @Input() data: any
  @Input() loading: boolean
  @Input() disableNext: boolean
  @Input() skip = false

  @HostBinding('class') get classes(): string {
    const classes = []

    if (this.data.background) {
      classes.push('jf-background')
    }

    return classes.join(' ')
  }

  constructor () {}

  ngOnInit() {

  }

  reqPrev () {
    if (this.loading) {return}
    this.prev.emit()
  }

  reqNext () {
    if (this.loading) {return}
    this.next.emit()
  }
}
