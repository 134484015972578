import { Component, HostBinding, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { OrderService } from 'app/service/order.service'
import { SlideComponent } from 'app/element/slide/slide.component'

@Component({
  selector: 'jf-jobdetails',
  templateUrl: './jobdetails.component.html',
  styleUrls: ['./jobdetails.component.sass']
})
export class JobdetailsComponent extends SlideComponent implements OnInit {
  public formData: FormGroup
  public saving: boolean

  public navData = {
    step: true,
    background: false,
    actions: 'request_fillin',
  }
  private inital: any
  public desc_validators = [Validators.required]

  @HostBinding('class.jf-slide') slideClass = true

  constructor (
    public route: ActivatedRoute,
    public router: Router,
    public orderService: OrderService,
  ) {
    super(route, router)

    const formGroup = this.orderService.getFormGroup()
    this.formData = new FormGroup({
      job_position: new FormControl(formGroup.get('job_position').value, [Validators.required]),
      job_sector: new FormControl(formGroup.get('job_sector').value, [Validators.required]),
      job_description: new FormControl(formGroup.get('job_description').value),
    })

    this.inital = this.formData.value
  }

  ngOnInit() {

  }

  back () {
    this.switchPrev()
  }

  isValid () {
    return this.formData.valid
  }

  submit (data: any) {
    return this.orderService.submitStep(this.data.step_key, data)
  }

  forward () {
    const that = this
    const data = that.formData.value

    if (that.isValid()) {
      if (JSON.stringify(that.inital) === JSON.stringify(that.formData.value) ) {
        that.switchNext()
        return
      }

      that.saving = true
      that.submit(data)
        .then(() => {
          that.saving = false

          const formGroup = that.orderService.getFormGroup()
          formGroup.patchValue(data)

          that.switchNext()
        })
        .catch(() => {
          that.saving = false
          alert('error')
        })
    }
  }
}
