/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notfound.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./notfound.component";
var styles_NotfoundComponent = [i0.styles];
var RenderType_NotfoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotfoundComponent, data: {} });
export { RenderType_NotfoundComponent as RenderType_NotfoundComponent };
export function View_NotfoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "jf-headline-l"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" o.O "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Diese Seite konnte nicht gefunden werden "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["class", "jf-anchor"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(7, 1), (_l()(), i1.ɵted(-1, null, [" zur Startseite "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 7, 0, "/"); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).target; var currVal_1 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_0, currVal_1); }); }
export function View_NotfoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "jf-notfound", [], null, null, null, View_NotfoundComponent_0, RenderType_NotfoundComponent)), i1.ɵdid(1, 114688, null, 0, i4.NotfoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotfoundComponentNgFactory = i1.ɵccf("jf-notfound", i4.NotfoundComponent, View_NotfoundComponent_Host_0, {}, {}, []);
export { NotfoundComponentNgFactory as NotfoundComponentNgFactory };
