var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, OnDestroy, AfterViewInit, ElementRef, EventEmitter } from '@angular/core';
import { OrderService } from 'app/service/order.service';
import { environment } from 'environments/environment';
import { FormControl, Validators } from '@angular/forms';
import { JFValidators } from 'app/static/validators';
import { HeaderService } from 'app/service/header.service';
var IbanPaymentComponent = /** @class */ (function () {
    function IbanPaymentComponent(cd, orderService, headerService) {
        this.cd = cd;
        this.orderService = orderService;
        this.headerService = headerService;
        this.paid = new EventEmitter();
        this.cardHandler = this.onChange.bind(this);
        this.card_holder = new FormControl('');
        this.card_holder_validators = [Validators.required, JFValidators.person];
        // @ts-ignore
        this.stripe = Stripe(environment.stripe_token);
    }
    IbanPaymentComponent.prototype.ngAfterViewInit = function () {
        var style = {
            base: {
                lineHeight: '30px',
                backgroundColor: '#efefef',
                color: '#181818',
                fontSize: '16px',
            }
        };
        var options = {
            supportedCountries: ['SEPA'],
            placeholderCountry: 'DE',
            style: style,
        };
        this.iban = this.stripe.elements().create('iban', options);
        this.iban.mount(this.cardInfo.nativeElement);
        this.iban.addEventListener('change', this.cardHandler);
    };
    IbanPaymentComponent.prototype.ngOnDestroy = function () {
        this.iban.removeEventListener('change', this.cardHandler);
        this.iban.destroy();
    };
    IbanPaymentComponent.prototype.onChange = function (_a) {
        var error = _a.error;
        this.iban_valid = !error;
        this.cd.detectChanges();
    };
    IbanPaymentComponent.prototype.canSubmit = function () {
        return this.terms && this.iban_valid && this.card_holder.valid;
    };
    IbanPaymentComponent.prototype.showTerms = function () {
        this.headerService.setShow(true);
        this.headerService.setTab('terms');
    };
    IbanPaymentComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var that, sourceData;
            return __generator(this, function (_a) {
                that = this;
                that.loading = true;
                that.error = '';
                sourceData = {
                    type: 'sepa_debit',
                    currency: 'eur',
                    owner: {
                        name: that.card_holder.value,
                        email: that.email,
                    },
                    mandate: {
                        notification_method: 'email',
                    }
                };
                // @ts-ignore
                that.stripe.createSource(that.iban, sourceData).then(function (result) {
                    that.orderService.purchase('iban', {
                        id: result.source.id,
                        name: that.card_holder.value,
                        email: that.email,
                        invoice_recipient: that.invoice_recipient,
                        payer: that.data.payer,
                    }, that.client_id)
                        .subscribe(function () {
                        that.paid.emit();
                    }, function (err) {
                        console.log('Something is wrong (api):', err);
                        that.error = err.error.error;
                        that.loading = false;
                    });
                });
                return [2 /*return*/];
            });
        });
    };
    return IbanPaymentComponent;
}());
export { IbanPaymentComponent };
