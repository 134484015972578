/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logoselect.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "../../slidenav/slidenav.component.ngfactory";
import * as i6 from "../../slidenav/slidenav.component";
import * as i7 from "./logoselect.component";
import * as i8 from "@angular/router";
import * as i9 from "../../../service/order.service";
var styles_LogoselectComponent = [i0.styles];
var RenderType_LogoselectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoselectComponent, data: {} });
export { RenderType_LogoselectComponent as RenderType_LogoselectComponent };
function View_LogoselectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "jf-upload-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["arrow_upward"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Logo hochladen "]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
function View_LogoselectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "jf-btn jf-remove"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clear($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["delete"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
export function View_LogoselectComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { input: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "jf-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "jf-preview-frame"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reqUpload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LogoselectComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "jf-preview"]], null, null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i4.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(7, { "transform": 0, "background-image": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LogoselectComponent_2)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Lade dein Logo hoch"])), (_l()(), i1.ɵeld(12, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Personalisiere deine Suche und lade dein Logo hoch. Achte dabei auf eine gute Aufl\u00F6sung und das passende Format."])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" M\u00F6gliche Bildformate: jpg, png, gif"])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Bevorzugte Gr\u00F6\u00DFe: 750x300px "])), (_l()(), i1.ɵeld(18, 0, [[1, 0], ["input", 1]], null, 0, "input", [["accept", "image/x-png,image/jpeg"], ["style", "left: -99999px; position: absolute; visibility: hidden;"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.check() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "jf-slidenav", [], [[8, "className", 0]], [[null, "prev"], [null, "next"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("prev" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } if (("next" === en)) {
        var pd_1 = (_co.forward() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_SlidenavComponent_0, i5.RenderType_SlidenavComponent)), i1.ɵdid(20, 114688, null, 0, i6.SlidenavComponent, [], { data: [0, "data"], loading: [1, "loading"], disableNext: [2, "disableNext"], skip: [3, "skip"] }, { prev: "prev", next: "next" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.validLogo; _ck(_v, 4, 0, currVal_0); var currVal_1 = _ck(_v, 7, 0, _co.currRotation, (("url(" + _co.logoData) + ")")); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.validLogo; _ck(_v, 9, 0, currVal_2); var currVal_4 = _co.navData; var currVal_5 = _co.uploading; var currVal_6 = false; var currVal_7 = !_co.validLogo; _ck(_v, 20, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 20).classes; _ck(_v, 19, 0, currVal_3); }); }
export function View_LogoselectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "jf-logoselect", [], [[2, "jf-slide", null]], null, null, View_LogoselectComponent_0, RenderType_LogoselectComponent)), i1.ɵdid(1, 245760, null, 0, i7.LogoselectComponent, [i8.ActivatedRoute, i8.Router, i9.OrderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).slideClass; _ck(_v, 0, 0, currVal_0); }); }
var LogoselectComponentNgFactory = i1.ɵccf("jf-logoselect", i7.LogoselectComponent, View_LogoselectComponent_Host_0, {}, {}, []);
export { LogoselectComponentNgFactory as LogoselectComponentNgFactory };
