
<div class="jf-icon" (click)="toggleShow()"><mat-icon>menu</mat-icon></div>

<div class="jf-navigation" (click)="toggleShow()" [ngClass]="{'jf-show': show}">
  <div class="jf-inner" (click)="$event.stopPropagation()">
    <div class="jf-close" (click)="toggleShow(false)"></div>
    <div class="jf-back" (click)="setView('menu')" *ngIf="view !== 'menu'"></div>

    <div class="jf-contentpage jf-links" *ngIf="view === 'menu'">
      <div class="jf-link" (click)="navCreate()">Video erstellen</div>
      <div class="jf-link" (click)="setView('cooperation')">Kooperation</div>
      <div class="jf-link" (click)="setView('contact')">Kontakt</div>
      <div class="jf-link" (click)="setView('prices')">Preise</div>
      <div class="jf-link" (click)="setView('terms')">AGB</div>
      <div class="jf-link" (click)="setView('privacy')">Datenschutz</div>
      <div class="jf-link" (click)="setView('imprint')">Impressum</div>
    </div>

    <div class="jf-contentpage jf-terms" *ngIf="view === 'terms'">
      <h2>
        Allgemeinen Geschäftsbedinungen
      </h2>
      <h3>
        www.jobflash.de<br>
        (Stand 11.02.2020)
      </h3>
      <h4>§ 1 Geltungsbereich und Anbieter</h4>
      <p>
        (1) Diese Allgemeinen Geschäftsbedingungen gelten für alle Aufträge, die Sie
        über den Online-Auftritt www.jobflash.de der<br>
        <br>
        WorkLife Media GmbH<br>
        Kaufbeurer Straße 8 1/2<br>
        87437 Kempten<br>
        Tel: 0831-52720445<br>
        E-Mail: info@jobflash.de<br>
        Geschäftsführer: Johannes Hoch<br>
        <br>
        tätigen.<br>
        <br>
        Hierbei handelt es sich um folgende Leistung:<br>
        <br>
        <strong style="font-style: italic;">JOBFLASH</strong> erstellt für die Nutzer/Kunden über die eigene Software einen Videoclip.
        Die Inhalte hierzu liefert der Auftraggeber/Kunde. Nach Fertigstellung kann der Auftraggeber/Kunde
        den VideoClip kostenfrei teilen oder kostenpflichtig bestellen und per Download erwerben.
      </p>
      <p>
        (2) Das Angebot auf unserer Online-Plattform richtet sich ausschließlich an
        beauftragte Personen, die das 18. Lebensjahr vollendet haben und als Unternehmer im Sinne des § 14 Abs. 1 BGB anzusehen sind.
      </p>
      <p>
        (3) Unsere Leistungen und Angebote erfolgen ausschließlich auf der
        Grundlage dieser Allgemeinen Geschäftsbedingungen. Die Allgemeinen
        Geschäftsbedingungen gelten gegenüber Unternehmen somit auch für alle
        künftigen Geschäftsbeziehungen, auch wenn sie nicht nochmals ausdrücklich
        vereinbart werden. Der Einbeziehung von Allgemeinen Geschäftsbedingungen
        eines Kunden, die unseren Allgemeinen Geschäftsbedingungen
        entgegenstehen, wird schon jetzt widersprochen.
      </p>
      <p>
        (4) Vertragssprache ist ausschließlich deutsch.
      </p>
      <p>
        (5) Sie können die derzeit gültigen Allgemeinen Geschäftsbedingungen auf der
        Website www.jobflash.de/AGB
        abrufen und ausdrucken.
      </p>

      <h4>§ 2 Vertragsschluss</h4>
      <p>
        (1) Die Angebote der Online-Plattform stellen keinen verbindlichen Antrag auf
        den Abschluss eines Vertrages dar. Vielmehr handelt es sich um eine
        unverbindliche Aufforderung zum Vertragsabschluss.
      </p>
      <p>
        (2) Mit Anklicken des Buttons [„Jetzt kaufen“] nehmen Sie das Angebot verbindlich an.
      </p>
      <p>
        (3) Nach Eingang des Auftrages erhalten Sie eine automatisch erzeugte E-Mail,
        mit der wir bestätigen, dass die Bestellung getätigt wurde. Die E-Mail enthält ebenso den Link zum Download des Videos.
        (Eingangsbestätigung).
      </p>

      <h4>§ 3 Preise</h4>
      <p>
        Die im Webauftritt genannten Preise sind brutto und verstehen sich inkl. der
        jeweiligen gesetzlichen Umsatzsteuer.
      </p>

      <h4>§ 4 Zahlungsbedingungen; Verzug</h4>
      <p>
        (1) Die Zahlung erfolgt wahlweise per:<br>
        Kreditkarte oder Lastschrift.
      </p>
      <p>
        (2) Die Auswahl der jeweils verfügbaren Bezahlmethoden obliegt uns.
      </p>
      <p>
        (3) Bei Zahlung per Kreditkarte erfolgt die tatsächliche Belastung
        Ihres Kreditkartenkontos frühestens zu dem Zeitpunkt der Auftragsbestätigung.
      </p>
      <p>
        (4) Bei Zahlung per Lastschrift haben Sie ggf. jene Kosten zu tragen, die infolge
        einer Rückbuchung einer Zahlungstransaktion mangels Kontodeckung oder
        aufgrund von Ihnen falsch übermittelter Daten der Bankverbindung entstehen.
      </p>

      <h4>§ 5 Gewährleistung</h4>
      <p>
        Soweit nicht ausdrücklich etwas anderes vereinbart ist, richten sich Ihre
        Gewährleistungsansprüche nach den gesetzlichen Bestimmungen des
        Schuldrechts mit folgenden Modifikationen:
      </p>
      <ul>
        <li>Für die Qualität der Dienstleistung sind nur unsere eigenen Angaben verbindlich.</li>
        <li>
          Mit der Abnahme des von Ihnen mithilfe unserer Software selbst
          erstellten Videoclips sind nachfolgende
          Gewährleistungsausschlüsse ausgeschlossen.
        </li>
      </ul>

      <h4>§ 6 Haftung</h4>
      <p>
        (1) Unbeschränkte Haftung: Wir haften unbeschränkt für Vorsatz und grobe
        Fahrlässigkeit.
      </p>
      <p>
        (2) Im Übrigen gilt folgende beschränkte Haftung: Bei leichter Fahrlässigkeit
        haften wir nur im Falle der Verletzung einer wesentlichen Vertragspflicht,
        deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt
        erst ermöglicht und auf deren Einhaltung Sie regelmäßig vertrauen dürfen
        (Kardinalpflicht). Die Haftung für leichte Fahrlässigkeit ist der Höhe nach
        beschränkt auf die bei Vertragsschluss vorhersehbaren Schäden, mit deren
        Entstehung typischerweise gerechnet werden muss. Diese
        Haftungsbeschränkung gilt auch zugunsten unserer Erfüllungsgehilfen.
      </p>

      <h4>§ 7 Schlussbestimmungen</h4>
      <p>
        (1) Sollten eine oder mehrere Bestimmungen dieser AGB unwirksam sein oder
        werden, wird dadurch die Wirksamkeit der anderen Bestimmungen im
        Übrigen nicht berührt.
      </p>
      <p>
        (2) Auf Verträge zwischen uns und Ihnen ist ausschließlich deutsches Recht
        anwendbar unter Ausschluss der Bestimmungen der United Nations
        Convention on Contracts for the International Sale of Goods (CISG, „UNKaufrecht“).
      </p>
      <p>
        (3) Als Gerichtsstand gilt der Ort Kempten als vereinbart.
      </p>
    </div>

    <div class="jf-contentpage jf-privacy" *ngIf="view === 'privacy'">
      <h2>Datenschutz</h2>
      <p>
        DATENSCHUTZERKLÄRUNG<br>
        www.jobflash.de<br>
        (Stand 11.02.2020)
      </p>

      <h3>Datenschutz- und Sicherheitsbestimmungen von jobflash.de, Stand Februar 2020</h3>
      <p>
        Wir bedanken uns für Ihren Besuch auf unserer Webseite. Der sichere Umgang mit Ihren Daten ist uns besonders
        wichtig. Wir möchten Sie daher ausführlich über die Verwendung Ihrer Daten bei dem Besuch unseres
        Webauftritts informieren.
      </p>

      <h4>1. Verantwortlicher und Kontakt</h4>
      <p>
        Für Auskünfte, Berichtigung, Sperrung oder Löschung Ihrer Daten, Widerspruch gegen Verwendung Ihrer Daten für
        Zwecke der Werbung oder der Markt- oder Meinungsforschung, Widerruf erteilter Einwilligungen oder sonstigen
        Fragen bzw. Anliegen zum Thema Datenschutz finden Sie unsere Kontaktdaten in unserem Impressum oder können
        Sie sich auch direkt an uns unter info@jobflash.de wenden.
        <br>
        WorkLife Media GmbH<br>
        Kaufbeurer Straße 8 1/2<br>
        87437 Kempten<br>
        Tel: 0831-52720445<br>
        E-Mail: info@jobflash.de<br>
        Geschäftsführer: Johannes Hoch<br>
        <br>
        Für den Inhalt verantwortlich i.S.d. § 55 RStV: Johannes Hoch
        <strong>Sie haben Fragen zum Thema Datenschutz?</strong> Gerne wenden Sie sich hierzu direkt an unseren Datenschutzbeauftragten:
        Herrn Ludwig von Jagow (Rechtsanwalt)<br>
        LASCAR Rechtsanwälte<br>
        Kurfürstendamm 62<br>
        10707 Berlin<br>
        Kontakt: <a href="mailto:datenschutz@jobflash.de">datenschutz@jobflash.de</a><br>
      </p>

      <h4>Rechtsgrundlage der Verarbeitung</h4>
      <p>
        Art. 6 Abs. S. 1 lit. a DSGVO dient unserem Unternehmen als Rechtsgrundlage für Verarbeitungsvorgänge,
        bei denen wir eine Einwilligung für einen bestimmten Verarbeitungszweck einholen. Ist die Verarbeitung
        personenbezogener Daten zur Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist,
        erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine Lieferung
        von Waren oder die Erbringung einer sonstigen Leistung oder Gegenleistung notwendig sind, so beruht die
        Verarbeitung auf Art. 6 Abs. S. 1 lit. b DSGVO. Gleiches gilt für solche Verarbeitungsvorgänge, die zur
        Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zu unseren
        Leistungen. Unterliegt unser Unternehmen einer rechtlichen Verpflichtung, durch welche eine Verarbeitung
        von personenbezogenen Daten erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher Pflichten,
        so basiert die Verarbeitung auf Art. 6 Abs. S. 1 lit. c DSGVO. In seltenen Fällen könnte die Verarbeitung
        von personenbezogenen Daten erforderlich werden, um lebenswichtige Interessen der betroffenen Person oder
        einer anderen natürlichen Person zu schützen, Art. 6 Abs. S. 1 lit. d DSGVO. Letztlich könnten
        Verarbeitungsvorgänge auch auf Art. 6 Abs. S. 1 lit. f DSGVO beruhen. Auf dieser Rechtsgrundlage
        basieren Verarbeitungsvorgänge, die von keiner der vorgenannten Rechtsgrundlagen erfasst werden,
        wenn die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines
        Dritten erforderlich ist, sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen
        nicht überwiegen.
      </p>
       
      <h4>Weitergabe von Daten</h4> 
      <p>
        Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken
        findet nicht statt. Wir geben Ihre persönlichen Daten an Dritte nur weiter, wenn:
      </p>
      <ul>
        <li>
          Sie Ihren nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,
        </li>
        <li>
          die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges
          Interesse an der Nichtweitergabe Ihrer Daten haben,
        </li>
        <li>
          für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung
          besteht, sowie
        </li>
        <li>
          dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von
          Vertragsverhältnissen mit Ihnen erforderlich ist.
        </li>
      </ul>
      <p>
        Mit folgenden Dienstleistern bzw. Zahlungsanbietern arbeiten wir zusammen:<br>
        Stripe, Inc. 510 Townsend Street San Francisco, CA 94103, USA<br>
        support@stripe.com
      </p>

      <p>
        Ihre personenbezogenen Daten geben wir nur dann an Dritte weiter, soweit eine gesetzliche Verpflichtung besteht
        oder Sie zuvor eingewilligt haben. Insbesondere werden wir Ihre personenbezogenen Daten ohne Ihre gesonderte
        Einwilligung nicht verkaufen, vermieten oder sonst zu Vermarktungs- oder Werbezwecken an Dritte übermitteln.
      </p>

      <h4>Betroffenenrechte</h4>
      <p>Sie haben das Recht:</p>
      <ul>
        <li>
          gemäß Art. 15 DSGVO Auskunft über Ihre von mir verarbeiteten personenbezogenen Daten zu verlangen.
          Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten,
          die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante
          Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder
          Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei mir
          erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling
          und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;
        </li>
        <li>
          gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei mir
          gespeicherten personenbezogenen Daten zu verlangen;
        </li>
        <li>
          gemäß Art. 17 DSGVO die Löschung Ihrer bei mir gespeicherten personenbezogenen Daten zu verlangen,
          soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur
          Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung,
          Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
        </li>
        <li>
          gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit
          die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren
          Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung
          oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die
          Verarbeitung eingelegt haben;
        </li>
        <li>
          gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie bereitgestellt haben, in einem strukturierten,
          gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;
        </li>
        <li>
          gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit  zu widerrufen. Dies hat zur Folge,
          dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen
          dürfen und
        </li>
        <li>
          gemäß Art. 77 DSGVO haben Sie unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
          Rechtsbehelfs das Recht, sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür
          an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder den Ort des Orts des
          mutmaßlichen Verstoßes wenden, wenn die betroffene Person der Ansicht ist, dass die Verarbeitung der sie
          betreffenden personenbezogenen Daten gegen die Datenschutzgrundverordnung der EU (DS-GVO) verstößt.
        </li>
        <li>
          <strong>Auskunftsrecht, Recht auf Berichtigung, Sperrung, Löschung, Widerspruch</strong><br>
          Auf schriftliche Anfrage informieren wir Sie über die zu Ihrer Person durch uns gespeicherten Daten.
          Sie haben auch ein Recht auf Berichtigung, Sperrung, Widerspruch oder Löschung dieser Daten. Die Anfrage
          ist an unsere im Impressum der Webseite angegebene Adresse zu richten.
        </li>
      </ul>

      <h4>Widerrufsrecht</h4> Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen bearbeitet
      <p>
        werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten
        einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der
        Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht,
        das ohne Angabe einer besonderen Situation von uns umgesetzt wird. Möchten Sie von Ihrem Widerrufs- oder
        Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an unsere im Impressum der Webseite angegebene Adresse.
      </p>

      <h4>Allgemeine Erhebung von Daten</h4>
      <p>
        Wenn Sie auf unsere Webseite zugreifen oder eine Datei abrufen, werden Daten über diesen Vorgang in einer
        Protokolldatei auf unserem Webserver gespeichert. Im Einzelnen können folgende Daten gespeichert werden:
      </p>
      <ul>
        <li>IP-Adresse (wenn möglich, wird diese anonymisiert gespeichert)</li>
        <li>Domain-Name der Webseite, von der Sie kamen</li>
        <li>Namen der abgerufenen Dateien</li>
        <li>Datum und Uhrzeit eines Abrufs</li>
        <li>Name Ihres Internet Service Providers</li>
        <li>sowie ggf. Betriebssystem und Browserversion Ihres Endgeräts</li>
      </ul>
      <p>
        IP-Adressen speichern wir nur aus Datensicherheitsgründen, um die Stabilität und die Sicherheit unseres Systems
        zu gewährleisten (Rechtsgrundlage: Art. 6 Abs. 1 lit. f DSGVO). Die statistische Auswertung anonymisierter
        Datensätze bleibt vorbehalten.
      </p>

      <h4>Cookies</h4>
      <h4>Einsatz von Cookies</h4>
      <p>
        Wir verwenden auf verschiedenen unserer Seiten so genannte Cookies, die dazu dienen, unser Angebot nutzerfreundlicher,
        effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Computer abgelegt bzw.
        gespeichert werden und die verschlüsselte Informationen über den Inhalt Ihres Besuchs sowie von Ihnen
        eingegebene Daten enthalten. Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”.
        Sie werden nach dem Ende Ihres Besuchs automatisch gelöscht. Einige Cookies verbleiben als so genannte
        “dauerhafte Cookies” auf Ihrem Rechner. Diese können bei Ihren nächsten Besuchen aufgerufen werden und
        ermöglichen die Erkennung Ihres Browsers sowie verschiedene Servicefunktionen. Cookies richten auf Ihrem
        Rechner keinen Schaden an und enthalten keine Viren.
      </p>
      <p>
        Sie können jederzeit die Einstellungen Ihres Browsers so konfigurieren, dass Cookies abgelehnt oder direkt
        gelöscht werden. Bitte beachten Sie dazu die entsprechenden Anleitungen Ihres Browserherstellers. Wir weisen
        jedoch darauf hin, dass dadurch die Funktionen unserer Webseiten nicht mehr vollständig nutzbar sein könnten
        und empfehlen daher, die Cookie-Funktionen eingeschaltet zu lassen.
      </p>
       

      <h4>Verwendung von Google Analytics</h4>
      <p>
        Um sowohl die Benutzerfreundlichkeit unserer Seiten als auch die Qualität unseres Web-Angebots zu verbessern,
        nutzen wir sogenannte Tracking-Technologien von Google Analytics (Google Inc., 1600 Amphitheatre Parkway,
        Mountain View, CA 94043, USA [“Google”]).
      </p>
      <p>
        Um diese Analysen durchzuführen, werden unter anderem Cookies eingesetzt. Cookies werden in Form von
        Textdateien auf dem Computer des Besuchers der Website gespeichert. Sie ermöglichen die Identifikation
        eines Nutzers bei einem wiederholten Besuch der Website. Internetnutzer können jederzeit die Einstellungen
        ihres Browsers konfigurieren, um die Cookies abzulehnen oder zu löschen. Dies könnte jedoch dazu führen,
        dass die Funktionen unserer Website nicht mehr vollständig nutzbar sind.
      </p>
      <p>
        Einige technische Daten werden gesammelt und dann für eine begrenzte Zeit gespeichert. Ihre IP Adresse wird
        vor der Speicherung anonymisiert. Eine Identifizierung der hinter den Analysen stehenden Personen ist für uns
        dabei ausgeschlossen. Die Daten für die Analysen werden nicht mit sonstigen von Nutzern (durch Anmeldung oder
        Bestellung) bekannt gegebenen persönlichen Daten in Verbindung gebracht.
      </p>
      <p>
        Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics
        verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der
        Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung
        dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
        Im Falle der Aktivierung der IPAnonymisierung auf dieser Webseite, wird Ihre IP-Adresse von Google jedoch
        innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
        Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server
        von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
        Informat ionen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten
        zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen
        gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
        IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Sie können die Speicherung der Cookies
        durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin,
        dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
      </p>
      <p>
        Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen
        Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie
        das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und
        installieren: http://tools.google.com/dlpage/gaoptout?hl=de
      </p>
      <p>
        Wir werten die Daten insbesondere für folgende Zwecke aus:
      </p>
      <ul>
        <li>Durchführung von Performance- oder Rentabilitäts-Vergleichen unserer Websites,</li>
        <li>Zählung von Besuchern,</li>
        <li>
          Tracking der Kenntnisnahme von bspw. auf der Website geschalteter Online-Werbung, Partner- und Affiliate-Programmen,
          Rich-Media-Inhalten oder besonderen Kampagnen,
        </li>
        <li>Messung der für Sie besonders attraktiven Bereiche der Website,</li>
        <li>Auswertung der Herkunft der Online-Nutzer zur lokalen Optimierung unseres Angebots.</li>
      </ul>

      <h4>Kontaktformular</h4>
      <p>
        Unsere Internetseite verwendet ein Kontaktformular.
      </p>
      <p>
        Die personenbezogenen Daten, die Sie uns im Rahmen Ihrer Kontaktanfrage zur Verfügung stellen, werden nur
        für die Beantwortung Ihrer Anfrage bzw. Kontaktaufnahme und für die damit verbundene technische Administration
        verwendet. Die Weitergabe an Dritte findet nicht statt.   Sie haben das Recht, eine erteilte Einwilligung mit
        Wirkung für die Zukunft jederzeit zu widerrufen. In diesem Fall werden Ihre personenbezogenen Daten umgehend gelöscht.
        Ihre personenbezogenen Daten werden auch ohne Ihren Widerruf gelöscht, wenn wir Ihre Anfrage bearbeitet haben
        oder Sie die hier erteilte Einwilligung zur Speicherung widerrufen. Dies geschieht auch, wenn die Speicherung
        aus sonstigen gesetzlichen Gründen unzulässig ist.   Sie können sich jederzeit über die zu Ihrer Person
        gespeicherten Daten informieren.
      </p>
       
      <h4>Newsletter</h4>
      <p>
        Auf unserer Webseite haben Sie die Möglichkeit, sich für den <strong style="font-style: italic;">JOBFLASH</strong>-Newsletter anzumelden. Der Newsletter
        informiert über aktuelle Angebote. Wenn Sie uns Ihren Namen mitteilen, nutzen wir diesen, um Sie ggf.
        persönlich anzusprechen. Teilen Sie uns darüber hinaus weitere Daten mit, verwenden wir diese, um die Inhalte
        des Newsletters auf Sie und Ihre Interessen zuzuschneiden. Nach Absenden des Anmeldeformulars erhalten Sie
        eine Bestätigungs-E-Mail. Die Anmeldung wird erst wirksam, wenn Sie auf den Link in der Bestätigungs-E-Mail
        geklickt haben. Sie können sich jederzeit vom Newsletter abmelden. Nutzen Sie hierfür einfach den Link zur
        Abbestellung am Ende jeder E-Mail. Rechtsgrundlage für die beschriebene Datenverarbeitung ist Art. 6 Abs. 1
        Buchstabe a Datenschutz-Grundverordnung (Einwilligung).
      </p>
       
      <h4>Links zu anderen Webseiten</h4>
      <p>
        Unsere Website/App kann von Zeit zu Zeit Links zu Websites Dritter oder zu anderen Websites von uns selbst
        enthalten. Wenn Sie einem Link zu einer dieser Websites folgen, weisen wir Sie darauf hin, dass diese Websites
        ihre eigenen Datenschutzrichtlinien haben und dass wir für diese Richtlinien keine Verantwortung oder Haftung
        übernehmen. Bitte überprüfen Sie diese Datenschutzrichtlinien, bevor Sie personenbezogenen Daten an
        diese Websites weitergeben.
      </p>
       
      <h4>Recht auf Datenübertragbarkeit</h4>
      <p>
        Sie haben das Recht die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben,
        in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
      </p>

      <h4>Vertraulichkeit Ihres Kundenzugangs</h4>
      <p>
        Wenn Sie Zugang zu einem geschützten Bereich auf unserer Webseite erhalten haben, der durch ein Passwort
        gesichert ist, sind Sie dafür verantwortlich, dieses Passwort vertraulich zu behandeln. Wir bitten Sie,
        das Passwort niemandem mitzuteilen.
      </p>
       
      <h4>SSL-Verschlüsselung</h4>
      <p>
        Diese Website nutzt die SSL-Verschlüsselung (Secure Socket Layer) für die Übertragung von Daten Ihres
        Browsers zu unserem Server und zu Servern, die Dateien bereitstellen, welche wir auf unserer Webseite
        einbinden. Mit SSL werden Daten verschlüsselt übertragen. Die Daten sind nicht veränderbar und der
        Absender kann identifiziert werden. Sie erkennen das Vorhandensein der SSL-Verschlüsselung anhand des
        vorangestellten Textes „https“ vor der Adresse der Webseite, die sie im Browser aufrufen.
      </p>
       
      <h4>Verweise auf Webseiten Dritter</h4>
      <p>
        Auf dieser Webseite werden Verweise auf Webseiten Dritter in Form sogenannter Links bzw.
        Verlinkungen angeboten. Erst wenn Sie auf einen solchen Link klicken, werden Daten zum Linkziel
        übertragen. Dies ist technisch notwendig. Die übertragenen Daten sind insbesondere: Ihre IP-Adresse,
        der Zeitpunkt zu dem Sie den Link angeklickt haben, die Seite auf der Sie den Link angeklickt haben,
        Angaben zu Ihrem Internet-Browser. Wenn Sie nicht möchten, dass diese Daten zum Linkziel übertragen werden,
        klicken Sie den Link nicht an.
      </p>

      <h4>Recht auf Beschwerde bei einer Aufsichtsbehörde</h4>
      <p>
        Jede betroffene Person hat unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
        Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat
        ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn die betroffene
        Person der Ansicht ist, dass die Verarbeitung der sie betreffenden personenbezogenen Daten gegen die
        Datenschutzgrundverordnung der EU (DS-GVO) verstößt.
      </p>

      <h4>Externe Schriftarten</h4>
      <p>
        Diese Seite nutzt zur Darstellung keine externen Schriftarten wie z.B. von Google.
      </p>

      <h4>Sicherheitshinweis</h4>
      <p>
        Wir sichern unsere Webseite und sonstige IT-Systeme durch geeignete technische und
        organisatorische Maßnahmen gegen Verlust, Zerstörung, unberechtigten Zugriff,
        unberechtigte Veränderung oder unberechtigte Verbreitung Ihrer Daten ab. Ein vollständiger
        Schutz gegen alle Gefahren ist praktisch trotz aller Sorgfalt jedoch nicht in jedem Fall möglich.
        Weil bei der Kommunikation per E-Mail die vollständige Datensicherheit von uns nicht gewährleistet werden kann,
        empfehlen wir zur Übermittlung von vertraulichen Informationen den Postweg.
      </p>
      <h4>Änderungen dieser Datenschutzerklärung</h4>
      <p>
        Wir behalten uns vor, diese Datenschutzerklärung zu ändern, wenn sich die Rechtslage oder dieses
        Online-Angebot oder die Art der Datenerfassung ändert. Dies gilt jedoch nur bezüglich Erklärungen zur
        Datenverarbeitung. Sofern eine Einwilligung des Nutzers notwendig ist oder Bestandteile der
        Datenschutzerklärung eine Regelung des Vertragsverhältnisses mit Nutzern enthalten, erfolgt die Änderung
        der Datenschutzerklärung nur nach Zustimmung des Nutzers. Bitte informieren Sie sich daher bei Bedarf
        über diese Datenschutzerklärung, insbesondere, wenn Sie personenbezogene Daten mitteilen.
      </p>
      <h4>Treu und Glauben</h4>
      <p>
        Diese Website wird nach dem Datenschutz-Grundsatz von Treu und Glauben betrieben. Dieser unbestimmte
        Rechtsbegriff der Rechtswissenschaft bezeichnet das Verhalten eines redlich und anständig handelnden
        Menschen. Auf den Grundsatz von Treu und Glauben wird in der aktuellen Rechtsordnung häufig Bezug genommen.
      </p>

    </div>

    <div class="jf-contentpage" *ngIf="view === 'imprint'">
      <h2>Impressum</h2>

      <p>
        WorkLife Media GmbH<br>
        Kaufbeurer Straße 8 1/2<br>
        87437 Kempten<br><br>
        Tel: +49 831 - 52720445<br>
        E-Mail: info@jobflash.de<br><br>
        Geschäftsführer: Johannes Hoch<br>
        HRB Nummer Gericht: 15057 beim Amtsgericht Kempten (Allgäu)<br>
        USt.-Idnr.: DE328548933<br><br>
      </p>

      <p>
        Redaktionelle Verantwortung:<br>
        Johannes Hoch<br><br>
      </p>

      <p>
        Hinweis zu Bildquellen:<br>
        jobflash.de nutzt folgende Bildquellen und Bilderdatenbanken: ENVATO ELEMENTS<br><br>
      </p>

      <p>
        Haftungsausschluss:<br>
        Wir übernehmen keine Haftung für die Inhalte externer Links. Die Verantwortung für die Inhalte
        der verlinkten Seiten obliegt ausschließlich den Betreibern dieser Seiten.
      </p>
    </div>

    <div class="jf-contentpage jf-contact" *ngIf="view === 'contact'">
      <h2>Kontakt</h2>
      <p>
        So erreichen Sie uns, wir haben immer ein offenes Ohr für Sie.
      </p>

      <ng-container *ngIf="!send">
        <jf-input type="text" [showErrors]="showErrors" [control]="formData.get('name')" placeholder="Name" [validators]="validators.name" class="jf-name"></jf-input>
        <jf-input type="email" [showErrors]="showErrors" [control]="formData.get('mail')" placeholder="E-Mail-Adresse" [validators]="validators.mail" class="jf-mail"></jf-input>
        <jf-input type="textarea" [showErrors]="showErrors" [control]="formData.get('message')" placeholder="Nachricht" [validators]="validators.message" class="jf-message"></jf-input>

        <button class="jf-btn jf-submit" [ngClass]="{'jf-disabled': !formData.valid, 'jf-sending': sending}" (click)="submit()">Abschicken</button>
      </ng-container>
      <div class="jf-success" *ngIf="send">
        <mat-icon>done</mat-icon>

        <h3>
          Nachricht wurde versandt
        </h3>

        <p>
          Vielen Dank für deine Nachricht an das <strong style="font-style: italic;">JOBFLASH</strong>-Team.
          Wir kümmern uns um dein Anliegen und kontaktieren dich schnellstmöglich.
        </p>

        <a class="jf-btn jf-submit" (click)="send = false; showErrors = false">
          Neue Nachricht
        </a>
      </div>
    </div>

    <div class="jf-contentpage jf-prices" *ngIf="view === 'prices'">
      <h2>Preise</h2>

      <div class="jf-tabs">
        <div class="jf-tab" *ngFor="let pack of packages" [ngClass]="{'jf-active': currPack.key === pack.key}" (click)="setPackage(pack.key)">
          {{ pack.name }}
        </div>
      </div>

      <div class="jf-tab-body">
        <div class="jf-package jf-key-{{ currPack.key }}">
          <div class="jf-pack-inner">
            <div *ngIf="currPack.banner" class="jf-banner" [innerHTML]="currPack.banner"></div>

            <div class="jf-name">{{ currPack.name }}</div>
            <div class="jf-price" [innerHTML]="currPack.price"></div>
            <p class="jf-desc" [innerHTML]="currPack.desc"></p>

            <div class="jf-btn" *ngIf="currPack.btn?.type === 'contact'" (click)="setView('cooperation')">
              {{ currPack.btn?.text }}
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="jf-contentpage" *ngIf="view === 'cooperation'">
      <h2>Kooperation</h2>
      <p>
        Sie finden <strong style="font-style: italic;">JOBFLASH</strong> gut? Sie möchten <strong style="font-style: italic;">JOBFLASH</strong> in Ihrem eigenen Branding haben?
        Wir haben viel spannende Möglichkeiten.
        Schreiben Sie uns gerne an.
      </p>

      <a class="jf-link" href="mailto:kooperation@jobflash.de">kooperation@jobflash.de</a>
    </div>


  </div>
</div>
