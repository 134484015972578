import { Component, HostBinding, OnInit, } from '@angular/core'
import { OrderService } from 'app/service/order.service'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { SlideComponent } from 'app/element/slide/slide.component'
import { ActivatedRoute, Router } from '@angular/router'
import { JFValidators } from 'app/static/validators'

@Component({
  selector: 'jf-contactdetails',
  templateUrl: './contactdetails.component.html',
  styleUrls: ['./contactdetails.component.sass'],
})
export class ContactdetailsComponent extends SlideComponent implements OnInit {
  public formData: FormGroup
  public saving: boolean

  public navData = {
    step: true,
    background: false,
    actions: 'request_fillin',
  }
  private inital: any
  public validators: any
  public showErrors = false

  @HostBinding('class.jf-slide') slideClass = true

  constructor (
    public route: ActivatedRoute,
    public router: Router,
    public orderService: OrderService,
  ) {
    super(route, router)

    const formGroup = this.orderService.getFormGroup()
    this.formData = new FormGroup({
      company_person: new FormControl(formGroup.get('company_person').value),
      company_mail: new FormControl(formGroup.get('company_mail').value),
      company_phone: new FormControl(formGroup.get('company_phone').value),
      company_website: new FormControl(formGroup.get('company_website').value),
    })
    this.validators = {
      company_person: [Validators.required, JFValidators.person],
      company_mail: [Validators.required, JFValidators.email],
      company_phone: [Validators.required, JFValidators.phone],
      company_website: [Validators.required, JFValidators.website],
    }

    this.inital = this.formData.value
  }

  ngOnInit() {

  }

  back () {
    this.switchPrev()
  }

  isValid () {
    return this.formData.valid
  }

  submit (data: any) {
    return this.orderService.submitStep(this.data.step_key, data)
  }

  forward () {
    const that = this
    const data = that.formData.value
    data.company_website = data.company_website.toLowerCase()

    if (that.isValid()) {
      if (JSON.stringify(that.inital) === JSON.stringify(data) ) {
        that.switchNext()
        return
      }

      that.saving = true
      that.submit(data)
        .then(() => {
          that.saving = false

          const formGroup = that.orderService.getFormGroup()
          formGroup.patchValue(data)

          that.switchNext()
        })
        .catch(() => {
          that.saving = false
          alert('error')
        })
    } else {
      this.showErrors = true
    }
  }
}
