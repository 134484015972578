import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { animate, animateChild, style, transition, trigger } from '@angular/animations'
import { copyToClipboard } from 'app/tools/javascript.tools'

@Component({
  selector: 'jf-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.sass'],
  animations: [
    trigger('parent', [
      transition(':leave', [
        animateChild(),
      ]),
    ]),
    trigger('show', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px) scale(.98)' }),
        animate('.25s', style({ opacity: 1, transform: 'translateY(0) scale(1)' })),
      ]),
      transition(':leave', [
        animate('.3s', style({ opacity: 0, transform: 'translateY(30px) scale(.98)' }))
      ])
    ]),
  ]
})
export class ShareComponent implements OnInit, OnDestroy {
  @HostBinding('@parent')
  @Output() close: EventEmitter<void> = new EventEmitter<void>()
  public _url: string
  @Input() set url (url: string) {
    this._url = url
    this.genereatePlatforms()
  }
  public copied: boolean

  platforms: any[]
  shareLinks = [{
    'label': 'Facebook',
    'icon': 'facebook.svg',
    'link': 'https://facebook.com/sharer/sharer.php?u={url}',
  }, {
    'label': 'Twitter',
    'icon': 'twitter.svg',
    'link': 'https://twitter.com/intent/tweet/?text={text};url={url}',
  }, {
    'label': 'Pinterest',
    'icon': 'pinterest.svg',
    'link': 'https://pinterest.com/pin/create/button/?url={url}&amp;media={url}&amp;description={text}',
  }, {
    'label': 'LinkedIn',
    'icon': 'linkedin.svg',
    'link': 'https://www.linkedin.com/shareArticle?mini=true&url={url}',
  }, {
    'label': 'Xing',
    'icon': 'xing.svg',
    'link': 'https://www.xing.com/app/user?op=share;url={url};title={text}',
  }, {
    'label': 'Reddit',
    'icon': 'reddit.svg',
    'link': 'https://reddit.com/submit/?url={url}&amp;resubmit=true&amp;title={text}',
  }, {
    'label': 'WhatsApp',
    'icon': 'whatsapp.svg',
    'link': 'https://api.whatsapp.com/send?text={text}%20{url}',
  }, {
    'label': 'Telegram',
    'icon': 'telegram.svg',
    'link': 'https://telegram.me/share/url?text={text}&url={url}',
  }, {
    'label': 'Mail',
    'icon': 'mail.svg',
    'link': 'mailto:?subject=Mein%20JOBFLASH%20Recruitment%20Video&body={text}{url}',
  }]
  private shareText = 'Hey, ich habe grade mein eigenes Recruitment Video mit JOBFLASH erstellt. '

  constructor () {}

  ngOnInit () {

  }

  ngOnDestroy () {

  }

  genereatePlatforms () {
    const that = this
    that.platforms = []
    that.shareLinks.forEach(elem => {
      let link: any = elem.link
      link = link.replace(new RegExp('{text}', 'g'), encodeURI(this.shareText))
      link = link.replace(new RegExp('{url}', 'g'), encodeURI(this._url))

      that.platforms.push({
        'label': elem.label,
        'icon': elem.icon,
        'link': link,
      })
    })
  }

  clipboard () {
    copyToClipboard(this._url)

    this.copied = true
    setTimeout(() => {
      this.copied = false
    }, 750)
  }
}
