/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./introduction.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "../../slidenav/slidenav.component.ngfactory";
import * as i6 from "../../slidenav/slidenav.component";
import * as i7 from "./introduction.component";
import * as i8 from "@angular/router";
import * as i9 from "../../../service/analytics.service";
var styles_IntroductionComponent = [i0.styles];
var RenderType_IntroductionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntroductionComponent, data: {} });
export { RenderType_IntroductionComponent as RenderType_IntroductionComponent };
function View_IntroductionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "jf-replay"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["replay"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
function View_IntroductionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "jf-play"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["play_arrow"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
function View_IntroductionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "jf-loading"]], null, null, null, null, null))], null, null); }
export function View_IntroductionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { video: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "jf-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "jf-video"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["video", 1]], null, 1, "video", [["autoplay", ""], ["playsinline", ""], ["poster", "/assets/mood/intro-cover.jpg"]], null, [[null, "waiting"], [null, "ended"], [null, "playing"], [null, "pause"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("waiting" === en)) {
        var pd_0 = ((_co.videoState = "waiting") !== false);
        ad = (pd_0 && ad);
    } if (("ended" === en)) {
        var pd_1 = ((_co.videoState = "ended") !== false);
        ad = (pd_1 && ad);
    } if (("playing" === en)) {
        var pd_2 = ((_co.videoState = "playing") !== false);
        ad = (pd_2 && ad);
    } if (("pause" === en)) {
        var pd_3 = ((_co.videoState = "pause") !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.toggleVideo() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "source", [["src", "/assets/mood/intro.mp4"], ["type", "video/mp4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IntroductionComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IntroductionComponent_2)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IntroductionComponent_3)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "jf-slidenav", [], [[8, "className", 0]], [[null, "prev"], [null, "next"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("prev" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } if (("next" === en)) {
        var pd_1 = (_co.forward() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_SlidenavComponent_0, i5.RenderType_SlidenavComponent)), i1.ɵdid(12, 114688, null, 0, i6.SlidenavComponent, [], { data: [0, "data"] }, { prev: "prev", next: "next" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.videoState === "ended"); _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.videoState === "pause"); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_co.videoState === "waiting"); _ck(_v, 10, 0, currVal_2); var currVal_4 = _co.navData; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 12).classes; _ck(_v, 11, 0, currVal_3); }); }
export function View_IntroductionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "jf-introduction", [], [[2, "jf-slide", null]], null, null, View_IntroductionComponent_0, RenderType_IntroductionComponent)), i1.ɵdid(1, 114688, null, 0, i7.IntroductionComponent, [i8.ActivatedRoute, i8.Router, i9.AnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).slideClass; _ck(_v, 0, 0, currVal_0); }); }
var IntroductionComponentNgFactory = i1.ɵccf("jf-introduction", i7.IntroductionComponent, View_IntroductionComponent_Host_0, {}, {}, []);
export { IntroductionComponentNgFactory as IntroductionComponentNgFactory };
