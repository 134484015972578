/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./videoplayer.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./videoplayer.component";
var styles_VideoplayerComponent = [i0.styles];
var RenderType_VideoplayerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VideoplayerComponent, data: {} });
export { RenderType_VideoplayerComponent as RenderType_VideoplayerComponent };
function View_VideoplayerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "jf-replay"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["replay"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
function View_VideoplayerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "jf-play"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["play_arrow"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
function View_VideoplayerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "jf-loading"]], null, null, null, null, null))], null, null); }
export function View_VideoplayerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { video: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["video", 1]], null, 3, "video", [["playsinline", ""]], [[8, "autoplay", 0], [8, "poster", 4]], [[null, "loadstart"], [null, "waiting"], [null, "ended"], [null, "playing"], [null, "pause"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadstart" === en)) {
        var pd_0 = (_co.init() !== false);
        ad = (pd_0 && ad);
    } if (("waiting" === en)) {
        var pd_1 = ((_co.videoState = "waiting") !== false);
        ad = (pd_1 && ad);
    } if (("ended" === en)) {
        var pd_2 = ((_co.videoState = "ended") !== false);
        ad = (pd_2 && ad);
    } if (("playing" === en)) {
        var pd_3 = ((_co.videoState = "playing") !== false);
        ad = (pd_3 && ad);
    } if (("pause" === en)) {
        var pd_4 = ((_co.videoState = "pause") !== false);
        ad = (pd_4 && ad);
    } if (("click" === en)) {
        var pd_5 = (_co.toggleVideo() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "jf-cover": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideoplayerComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideoplayerComponent_2)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideoplayerComponent_3)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, _co.cover); _ck(_v, 2, 0, currVal_2); var currVal_4 = (_co.videoState === "ended"); _ck(_v, 6, 0, currVal_4); var currVal_5 = (_co.videoState === "pause"); _ck(_v, 8, 0, currVal_5); var currVal_6 = (_co.videoState === "waiting"); _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.autoplay ? "autoplay" : null); var currVal_1 = (_co.poster ? _co.poster : ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.video_url; _ck(_v, 4, 0, currVal_3); }); }
export function View_VideoplayerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "jf-videoplayer", [], null, null, null, View_VideoplayerComponent_0, RenderType_VideoplayerComponent)), i1.ɵdid(1, 4440064, null, 0, i5.VideoplayerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoplayerComponentNgFactory = i1.ɵccf("jf-videoplayer", i5.VideoplayerComponent, View_VideoplayerComponent_Host_0, { video_url: "video_url", autoplay: "autoplay", poster: "poster", cover: "cover" }, {}, []);
export { VideoplayerComponentNgFactory as VideoplayerComponentNgFactory };
