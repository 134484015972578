/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./iban-payment.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../input/input.component.ngfactory";
import * as i3 from "../../input/input.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./iban-payment.component";
import * as i7 from "../../../service/order.service";
import * as i8 from "../../../service/header.service";
var styles_IbanPaymentComponent = [i0.styles];
var RenderType_IbanPaymentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IbanPaymentComponent, data: {} });
export { RenderType_IbanPaymentComponent as RenderType_IbanPaymentComponent };
function View_IbanPaymentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "jf-error-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Deine Karte wurde von der Bank abgelehnt (", ").\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 1, 0, currVal_0); }); }
export function View_IbanPaymentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { cardInfo: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Karteninhaber"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "jf-input", [["class", "jf-company"], ["type", "text"]], null, null, null, i2.View_InputComponent_0, i2.RenderType_InputComponent)), i1.ɵdid(4, 4440064, null, 0, i3.InputComponent, [], { control: [0, "control"], type: [1, "type"], validators: [2, "validators"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "label", [["for", "iban-element"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" IBAN\n"])), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["ibanInput", 1]], null, 0, "div", [["id", "iban-element"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 11, "label", [["class", "jf-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "input", [["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.terms = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i4.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.CheckboxControlValueAccessor]), i1.ɵdid(12, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(14, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 4, "span", [["class", "jf-checkbox-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ich best\u00E4tige hiermit die "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "a", [["class", "jf-terms-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showTerms() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["AGB"])), (_l()(), i1.ɵted(-1, null, [". "])), (_l()(), i1.ɵeld(20, 0, null, null, 3, "p", [["class", "jf-price"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 24,90 \u20AC "])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["inkl. 3,98 \u20AC MwSt."])), (_l()(), i1.ɵeld(24, 0, null, null, 1, "p", [["class", "jf-note"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Im Anschluss erh\u00E4ltst du eine E-Mail mit dem Downloadlink zu deinem Video.\n"])), (_l()(), i1.ɵeld(26, 0, null, null, 3, "div", [["class", "jf-submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(27, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(28, { "jf-disabled": 0, "jf-loading": 1 }), (_l()(), i1.ɵted(-1, null, ["Jetzt kaufen"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IbanPaymentComponent_1)), i1.ɵdid(31, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.card_holder; var currVal_1 = "text"; var currVal_2 = _co.card_holder_validators; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_10 = _co.terms; _ck(_v, 12, 0, currVal_10); var currVal_11 = "jf-submit"; var currVal_12 = _ck(_v, 28, 0, !_co.canSubmit(), _co.loading); _ck(_v, 27, 0, currVal_11, currVal_12); var currVal_13 = _co.error; _ck(_v, 31, 0, currVal_13); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 14).ngClassValid; var currVal_8 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_IbanPaymentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "jf-iban-payment", [], null, null, null, View_IbanPaymentComponent_0, RenderType_IbanPaymentComponent)), i1.ɵdid(1, 4374528, null, 0, i6.IbanPaymentComponent, [i1.ChangeDetectorRef, i7.OrderService, i8.HeaderService], null, null)], null, null); }
var IbanPaymentComponentNgFactory = i1.ɵccf("jf-iban-payment", i6.IbanPaymentComponent, View_IbanPaymentComponent_Host_0, { invoice_recipient: "invoice_recipient", client_id: "client_id", email: "email", data: "data" }, { paid: "paid" }, []);
export { IbanPaymentComponentNgFactory as IbanPaymentComponentNgFactory };
