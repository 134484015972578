/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./share.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./share.component";
var styles_ShareComponent = [i0.styles];
var RenderType_ShareComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShareComponent, data: { "animation": [{ type: 7, name: "parent", definitions: [{ type: 1, expr: ":leave", animation: [{ type: 9, options: null }], options: null }], options: {} }, { type: 7, name: "show", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0, transform: "translateY(20px) scale(.98)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translateY(0) scale(1)" }, offset: null }, timings: ".25s" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0, transform: "translateY(30px) scale(.98)" }, offset: null }, timings: ".3s" }], options: null }], options: {} }] } });
export { RenderType_ShareComponent as RenderType_ShareComponent };
function View_ShareComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "jf-platform"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.link; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "/assets/icon/social/color/", _v.context.$implicit.icon, ""); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.label; _ck(_v, 3, 0, currVal_2); }); }
function View_ShareComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Link kopieren"]))], null, null); }
function View_ShareComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["check"])), (_l()(), i1.ɵted(-1, null, [" Kopiert"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
export function View_ShareComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "jf-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [], [[24, "@show", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "jf-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nutze dein Netzwerk"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "jf-platforms"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShareComponent_1)), i1.ɵdid(7, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "jf-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clipboard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShareComponent_2)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShareComponent_3)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.platforms; _ck(_v, 7, 0, currVal_1); var currVal_2 = !_co.copied; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.copied; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 1, 0, currVal_0); }); }
export function View_ShareComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "jf-share", [], [[40, "@parent", 0]], null, null, View_ShareComponent_0, RenderType_ShareComponent)), i1.ɵdid(1, 245760, null, 0, i5.ShareComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).close; _ck(_v, 0, 0, currVal_0); }); }
var ShareComponentNgFactory = i1.ɵccf("jf-share", i5.ShareComponent, View_ShareComponent_Host_0, { url: "url" }, { close: "close" }, []);
export { ShareComponentNgFactory as ShareComponentNgFactory };
