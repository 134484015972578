
<label>Karteninhaber</label>
<jf-input
  type="text"
  [control]="card_holder"
  [validators]="card_holder_validators"
  class="jf-company">
</jf-input>

<label for="iban-element">
  IBAN
</label>
<div id="iban-element" #ibanInput></div>

<label class="jf-checkbox">
  <input [(ngModel)]="terms" type="checkbox">
  <span class="jf-checkbox-content">Ich bestätige hiermit die <a class="jf-terms-link" (click)="showTerms()">AGB</a>. </span>
</label>

<p class="jf-price">
  24,90 €
  <small>inkl. 3,98 € MwSt.</small>
</p>
<p class="jf-note">
  Im Anschluss erhältst du eine E-Mail mit dem Downloadlink zu deinem Video.
</p>

<div class="jf-submit" (click)="onSubmit()" [ngClass]="{'jf-disabled': !canSubmit(), 'jf-loading': loading}">Jetzt kaufen</div>

<div *ngIf="error" class="jf-error-message">
  Deine Karte wurde von der Bank abgelehnt ({{error}}).
</div>
