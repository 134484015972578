
interface MetaKeys {
  url: any[]
  title: any[]
  release: any[]
  desc: any[]
  image: any[]
}

export const metaKeys: MetaKeys = {
  url: [{property: 'og:url'}],
  title: [{property: 'og:title'}, {property: 'twitter:title'}],
  release: [{property: 'article:published_time'}, {property: 'article:modified_time'}, {property: 'og:updated_time'}],
  desc: [{name: 'description'}, {name: 'og:description'}, {name: 'twitter:description'}],
  image: [{property: 'og:image'}, {name: 'twitter:image'}],
}
