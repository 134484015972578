import { Routes } from '@angular/router';
import { EditorComponent } from 'app/page/editor/editor.component';
import { NotfoundComponent } from 'app/page/notfound/notfound.component';
import { PublishedvideoComponent } from 'app/element/slide/publishedvideo/publishedvideo.component';
import { steps } from 'app/static/steps';
import { ViewresultComponent } from 'app/element/slide/viewresult/viewresult.component';
import { IndexComponent } from 'app/page/index/index.component';
var ɵ0 = {
    meta: {
        title: 'Finde die besten Mitarbeiterinnen und Mitarbeitern für dein Unternehmen!',
        desc: 'Der Arbeitsmarkt ist in stetigem Wandel und es wird für Unternehmen immer schwieriger gute Talente zu finden. ' +
            'Dadurch ändert sich auch die Richtung des Recruitments!',
    },
}, ɵ1 = {
    meta: {
        title: 'Kostenloses JOBFLASH-Video auf Social Media oder Webseite teilen',
        desc: 'Fertiges Recruitment Video kostenlos teilen. Keine Fremdkosten. Reichweite nutzen und Stellenausschreibung bekannt machen.'
    }
}, ɵ2 = {
    meta: {
        title: 'Schnell & einfach eigenes Recruitment Video erstellen - JOBFLASH mit Tutorials',
        desc: 'Mit Step-by-step-Begleitung in nur wenigen Schritten zum eigenen, individuellen Recruitment Video.'
    },
}, ɵ3 = {
    meta: {
        title: 'Seite nicht gefunden',
        desc: 'Es liegt wirklich nicht an dir, wir sind das Problem. Aber vielleicht können wir trotzdem Freunde bleiben?',
    }
};
var routes = [
    { path: 'erstellen',
        component: EditorComponent,
        children: steps
    }, {
        path: '',
        component: IndexComponent,
        data: ɵ0,
    }, {
        path: 'ansehen/:id',
        component: PublishedvideoComponent,
        data: ɵ1,
    }, {
        path: 'mein-video/:id/:key',
        component: ViewresultComponent,
        data: ɵ2,
    }, {
        path: '**',
        component: NotfoundComponent,
        data: ɵ3,
    }
];
var AppRoutesModule = /** @class */ (function () {
    function AppRoutesModule() {
    }
    return AppRoutesModule;
}());
export { AppRoutesModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
