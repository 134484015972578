import { AfterViewInit, Component, HostBinding, OnInit, ViewChild } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { OrderService } from 'app/service/order.service'
import { SlideComponent } from 'app/element/slide/slide.component'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'jf-questionslide',
  templateUrl: './questionslide.component.html',
  styleUrls: ['./questionslide.component.sass']
})
export class QuestionslideComponent extends SlideComponent implements OnInit, AfterViewInit {
  public step = new BehaviorSubject<number>(0)

  @ViewChild('input') input
  @ViewChild('video') video
  @HostBinding('class.jf-slide') slideClass = true
  public uploading: boolean
  public videoState = 'waiting'

  public video_url: string
  public navData
  public navDataList: any[] = [{
    step: false,
    background: true,
    actions: 'request_record',
  }, {}, {
    step: false,
    background: false,
    actions: 'confirm',
  }]

  constructor (
    public route: ActivatedRoute,
    public router: Router,
    public orderService: OrderService,
  ) {
    super(route, router)

    const that = this
    that.step.subscribe(n => {
      that.navData = that.navDataList[n === 2 ? 2 : 0]

      if (n === 1) {
        setTimeout(() => {
          // tslint:disable-next-line:no-unused-expression
          that.input && that.input.nativeElement.click()
        }, 150)
      }
    })
  }

  ngOnInit() {
    const that = this
    if (that.data.video_number) {
      that.navDataList[0].video_number = 'Video ' + that.data.video_number + '/7'
    } else {
      that.navDataList[0].video_number = false
    }

    that.navDataList[0].headline = that.data.headline
    that.navDataList[0].text = that.data.text

    that.video_url = that.orderService.getFormGroup().get(that.data.step_key).value
    if (that.video_url) {
      that.step.next(2)
    }
  }

  ngAfterViewInit () {
    if (this.video) {
      this.video.nativeElement.play()
    }
  }


  check () {
    const that = this
    if (that.uploading) {return}
    if (!that.input) {return}

    const file = that.input.nativeElement.files[0]

    if (['video/mp4', 'video/quicktime'].indexOf(file.type) === -1) {
      alert('Bitte lade eine videodatei hoch (keine ' + file.type + ')')
      return
    }

    that.upload(file)
  }

  upload (file) {
    const that = this
    that.uploading = true
    that.orderService.submitVideo(that.data.step_key, file)
      .then(resp => {
        that.uploading = false
        that.video_url = resp.path
        that.forward()
        that.step.next(2)
        setTimeout(() => {
          if (that.video) {
            that.video.nativeElement.play()
          }
        }, 150)
      })
      .catch(() => {
        that.uploading = false
        console.error('file upload error')
      })

    return
  }

  back () {
    const that = this
    const s = this.step

    switch (s.value) {
      case 0:
        that.switchPrev()
        break
      default:
        s.next(0)
    }
  }

  play () {
    // tslint:disable-next-line:no-unused-expression
    this.video && this.video.nativeElement.play()
  }

  toggleVideo () {
    if (!this.video) {return}

    switch (this.videoState) {
      case 'playing':
        this.video.nativeElement.pause()
        break
      case 'ended':
      case 'pause':
        this.video.nativeElement.play()
        break
      case 'waiting':
        break
      default:
        this.video.nativeElement.play()
    }
  }

  forward () {
    const that = this
    const s = this.step

    if (this.uploading) {return}

    switch (s.value) {
      case 2:
        const patch = {}
        patch[that.data.step_key] = that.video_url
        that.orderService.getFormGroup().patchValue(patch)
        that.switchNext()
        break
      default:
        if (this.video) {
          this.video.nativeElement.pause()
        }
        s.next(1)
    }
  }
}
