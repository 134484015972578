
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable()
export class DataInterface {
  private readonly apiUrl = environment.apiUrl
  private headers: HttpHeaders

  constructor(private http: HttpClient) {
    const that = this

    that.headers = new HttpHeaders({
      'Content-Type':  'application/json',
    })
  }

  static pack (data) {
    return JSON.stringify(data)
  }

  submitContact (data) {
    return this.http.post<any>(this.apiUrl + '/contact/submit', DataInterface.pack(data), { headers: this.headers })
  }

  getResult (client_id: string, key: string) {
    return this.http.get<any>(this.apiUrl + '/result/get/' + client_id + '/' + key, { headers: this.headers })
  }

  getVideo (client_id: string) {
    return this.http.get<any>(this.apiUrl + '/video/get/' + client_id, { headers: this.headers })
  }
}
