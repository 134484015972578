/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./editor.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "../../element/header/header.component.ngfactory";
import * as i6 from "../../element/header/header.component";
import * as i7 from "../../service/header.service";
import * as i8 from "@angular/router";
import * as i9 from "../../api/interfaces/data.interface";
import * as i10 from "./editor.component";
import * as i11 from "../../service/order.service";
var styles_EditorComponent = [i0.styles];
var RenderType_EditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditorComponent, data: {} });
export { RenderType_EditorComponent as RenderType_EditorComponent };
function View_EditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "jf-rotate-device"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["sync"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Halte dein Handy im Hochformat "]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_0); }); }
function View_EditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "jf-block-desktop"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["smartphone"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00D6ffne "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "strong", [["style", "font-style: italic;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["JOBFLASH"])), (_l()(), i1.ɵted(-1, null, [" auf deinem Handy "]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_0); }); }
function View_EditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditorComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditorComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "jf-header", [], null, null, null, i5.View_HeaderComponent_0, i5.RenderType_HeaderComponent)), i1.ɵdid(6, 114688, null, 0, i6.HeaderComponent, [i7.HeaderService, i8.Router, i9.DataInterface], null, null), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.requestRotate; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.blockDesktop; _ck(_v, 4, 0, currVal_1); _ck(_v, 6, 0); _ck(_v, 8, 0); }, null); }
function View_EditorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "jf-loader"]], null, null, null, null, null))], null, null); }
function View_EditorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "JOBFLASH Logo"], ["src", "/assets/brand/logo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditorComponent_5)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLoading; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_EditorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditorComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditorComponent_4)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.initialized; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.initialized; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_EditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "jf-editor", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_EditorComponent_0, RenderType_EditorComponent)), i1.ɵdid(1, 245760, null, 0, i10.EditorComponent, [i11.OrderService, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditorComponentNgFactory = i1.ɵccf("jf-editor", i10.EditorComponent, View_EditorComponent_Host_0, {}, {}, []);
export { EditorComponentNgFactory as EditorComponentNgFactory };
