var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, } from '@angular/core';
import { OrderService } from 'app/service/order.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SlideComponent } from 'app/element/slide/slide.component';
import { ActivatedRoute, Router } from '@angular/router';
import { JFValidators } from 'app/static/validators';
var ContactdetailsComponent = /** @class */ (function (_super) {
    __extends(ContactdetailsComponent, _super);
    function ContactdetailsComponent(route, router, orderService) {
        var _this = _super.call(this, route, router) || this;
        _this.route = route;
        _this.router = router;
        _this.orderService = orderService;
        _this.navData = {
            step: true,
            background: false,
            actions: 'request_fillin',
        };
        _this.showErrors = false;
        _this.slideClass = true;
        var formGroup = _this.orderService.getFormGroup();
        _this.formData = new FormGroup({
            company_person: new FormControl(formGroup.get('company_person').value),
            company_mail: new FormControl(formGroup.get('company_mail').value),
            company_phone: new FormControl(formGroup.get('company_phone').value),
            company_website: new FormControl(formGroup.get('company_website').value),
        });
        _this.validators = {
            company_person: [Validators.required, JFValidators.person],
            company_mail: [Validators.required, JFValidators.email],
            company_phone: [Validators.required, JFValidators.phone],
            company_website: [Validators.required, JFValidators.website],
        };
        _this.inital = _this.formData.value;
        return _this;
    }
    ContactdetailsComponent.prototype.ngOnInit = function () {
    };
    ContactdetailsComponent.prototype.back = function () {
        this.switchPrev();
    };
    ContactdetailsComponent.prototype.isValid = function () {
        return this.formData.valid;
    };
    ContactdetailsComponent.prototype.submit = function (data) {
        return this.orderService.submitStep(this.data.step_key, data);
    };
    ContactdetailsComponent.prototype.forward = function () {
        var that = this;
        var data = that.formData.value;
        data.company_website = data.company_website.toLowerCase();
        if (that.isValid()) {
            if (JSON.stringify(that.inital) === JSON.stringify(data)) {
                that.switchNext();
                return;
            }
            that.saving = true;
            that.submit(data)
                .then(function () {
                that.saving = false;
                var formGroup = that.orderService.getFormGroup();
                formGroup.patchValue(data);
                that.switchNext();
            })
                .catch(function () {
                that.saving = false;
                alert('error');
            });
        }
        else {
            this.showErrors = true;
        }
    };
    return ContactdetailsComponent;
}(SlideComponent));
export { ContactdetailsComponent };
