
import { Injectable, Inject } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { BehaviorSubject } from 'rxjs'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { distinctUntilChanged, filter, map, mergeMap, pairwise } from 'rxjs/operators'
import { StructuredDataTag } from 'app/service/structured_data.tag'
import { metaKeys } from 'app/service/meta/meta.keys'
import { AnalyticsService } from 'app/service/analytics.service'
import { WINDOW } from 'app/service/window.service'

interface TitleObjectModel {
  headline: string
  addition: string
}

@Injectable()
export class MetaService {
  private pageTitle: BehaviorSubject<TitleObjectModel> = new BehaviorSubject<TitleObjectModel>({
    headline: '',
    addition: '',
  })

  private metaStacks = {}
  public cookieVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor(
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private meta: Meta,
    @Inject(WINDOW) public window,
  ) {
    const that = this

    let showCookieNotice

    if (typeof document !== 'undefined' && typeof localStorage !== 'undefined') {
      try {
        showCookieNotice = !Boolean(localStorage.getItem('hidecookienotify'))
      } catch (e) {
        showCookieNotice = true
      }
    } else {
      showCookieNotice = false
    }

    this.setCookieNoteVisible(showCookieNotice)

    that.pageTitle.subscribe(title => {
      const newTitle = [
        title.headline,
        title.addition,
      ].join('')
      that.titleService.setTitle(newTitle)
    })

    that.init()
    let pageSD: StructuredDataTag

    that.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => that.route),
        map(obj => {
          while (obj.firstChild) {
            obj = obj.firstChild
          }
          return obj
        }),
        mergeMap(obj => obj.data),
      )
      .subscribe(routeData => {
        that.clearMetaScope('page', false)
        that.addMeta('url', 'https://www.jobflash.de' + this.router.url, 'page')
        const _meta = routeData.meta
        const newPageSD = {
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          'url': 'https://www.jobflash.de' + this.router.url,
          'name': _meta && _meta['title'] ? _meta['title'] : 'jobflash',
          'description': _meta && _meta['desc'] ? _meta['desc'] : 'JOBFLASH ist dein individuelles ' +
            'Recruitment Video − Die Recruiting-Revolution',
        }

        if (pageSD) {
          pageSD.update(newPageSD)
        } else {
          pageSD = new StructuredDataTag(newPageSD)
        }

        that.analyticsService.sendPageView()

        if (_meta) {
          Object.keys(_meta).forEach(key => {
            that.addMeta(
              key,
              _meta[key],
              'page'
            )
          })
        }
      })
  }

  private init () {
    // set static tags
    this.meta.updateTag({ property: 'og:type', content: 'website' })
    this.meta.updateTag({ name: 'twitter:iban', content: 'summary' })
    this.meta.updateTag({ property: 'og:site_name', content: 'jobflash' })
    this.meta.updateTag({ property: 'og:locale', content: 'de_DE' })

    this.addMeta(
      'url',
      'https://www.jobflash.de',
      'core'
    )
    this.addMeta(
      'title',
      'JOBFLASH',
      'core'
    )
    this.addMeta(
      'desc',
      'JOBFLASH ist dein individuelles Recruitment Video − Die Recruiting-Revolution',
      'core'
    )
    this.addMeta(
      'image',
      'https://www.jobflash.de/assets/meta/jfimage.jpg',
      'core'
    )

    /* tslint:disable-next-line:no-unused-expression */
    new StructuredDataTag({
      '@context': 'http://schema.org',
      '@type': 'Organization',
      'name': 'WorkLife Media GmbH',
      'telephone': '083152720445',
      'email': 'info@jobflash.de',
      'address': {
        '@type': 'PostalAddress',
        'addressLocality': 'Kempten',
        'addressRegion': 'DE',
        'postalCode': '87437',
        'streetAddress': 'Kaufbeurer Straße 8 1/2'
      },
      'image': 'https://jobflash.de/assets/meta/jfimage.jpg',
      'url': 'https://jobflash.de/',
      'contactPoint': [{
        '@type': 'ContactPoint',
        'contactType': 'Kundenservice',
        'areaServed': 'DE',
        'name': 'JOBFLASH',
        'telephone': '083152720445',
      }],
      'sameAs': []
    })
  }

  setCookieNoteVisible (state: boolean) {
    this.cookieVisible.next(state)

    if (!this.window || typeof localStorage === 'undefined') {
      return
    }

    if (!state) {
      this.window['localStorage'].setItem('hidecookienotify', 'true')
    } else {
      this.window['localStorage'].removeItem('hidecookienotify')
    }
  }

  getCookieNoteVisible () {
    return this.cookieVisible
  }

  addMeta (id, content, scope) {
    const newMeta = {content: content, scope: scope}
    const metaStack = this.metaStacks[id]

    if (metaStack) {
      const index = metaStack.findIndex(element => element.scope === scope)
      if (index !== -1) {
        metaStack[index] = newMeta
      } else {
        metaStack.push(newMeta)
      }
    } else {
      this.metaStacks[id] = [newMeta]
    }

    this.setMeta(id)
  }

  private setMeta (id) {
    const metaStack = this.metaStacks[id]
    const metaData = metaKeys[id]
    if (!metaData) {
      console.log('Unrecongnised util key', id)
      return
    }

    if (metaStack && metaStack.length) {
      const content = metaStack[metaStack.length - 1].content
      metaData.forEach(proto => {
        const tag = JSON.parse(JSON.stringify(proto))
        tag.content = content

        const key = Object.keys(proto)[0]
        const sel = [key, '="', proto[key], '"'].join('')
        this.meta.updateTag(tag, sel)
      })
      if (id === 'title') {
        this.setTitle(content)
      }
    } else {
      metaData.forEach(proto => {
        const key = Object.keys(proto)[0]
        const sel = [key, '="', proto[key], '"'].join('')

        this.meta.removeTag(sel)
      })
    }
  }

  addMetas (tags, scope) {
    const that = this
    tags.forEach(tag => {
      that.addMeta(tag.id, tag.content, scope)
    })
  }

  clearMeta (id, scope, clear = true) {
    const metaStack = this.metaStacks[id]
    if (metaStack) {
      const index = metaStack.findIndex(element => element.scope === scope)
      if (index !== -1) {
        if (clear) {
          metaStack.splice(index, 1)
        } else {
          metaStack[index].content = ''
        }
      }
      this.setMeta(id)
    }
  }

  clearMetaStack (id) {
    this.metaStacks[id] = []
    this.setMeta(id)
  }

  clearMetaScope (scope, clear = true) {
    const that = this
    Object.keys(metaKeys).forEach(key => {
      that.clearMeta(key, scope, clear)
    })
  }

  private setTitle (newTitle: string): void {
    const object = this.pageTitle.value
    object.headline = newTitle

    this.pageTitle.next(object)
  }

  setScrollBlocked (state: boolean) {
    if (typeof document !== 'undefined') {
      if (state) {
        document.body.classList.add('jf-scrollblocked')
      } else {
        document.body.classList.remove('jf-scrollblocked')
      }
    }
  }
}
