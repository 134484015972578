/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cookie.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./cookie.component";
import * as i4 from "../../service/header.service";
import * as i5 from "../../service/meta.service";
import * as i6 from "../../service/analytics.service";
import * as i7 from "../../service/window.service";
var styles_CookieComponent = [i0.styles];
var RenderType_CookieComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CookieComponent, data: {} });
export { RenderType_CookieComponent as RenderType_CookieComponent };
export function View_CookieComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Cookie Icon"], ["src", "/assets/icon/cookie.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 26, "div", [["class", "jf-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Wir verwenden cookies. Durch das Surfen auf unserer Seite, stimmst du dieser Nutzung zu. "])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "label", [["class", "jf-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "input", [["checked", ""], ["disabled", ""], ["type", "checkbox"], ["value", "1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "span", [["class", "jf-checkbox-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Cookies "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "jf-required"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Zur Erleichterung und besseren Zuordnung brauchen wir ein paar Cookies. "])), (_l()(), i1.ɵeld(11, 0, null, null, 16, "label", [["class", "jf-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 5, "input", [["type", "checkbox"], ["value", "1"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.anonymous_statistics = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(15, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(17, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 9, "span", [["class", "jf-checkbox-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Anonyme Datensammlung "])), (_l()(), i1.ɵeld(20, 0, null, null, 7, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" D\u00FCrfen wir dir beim der Nutzung von "])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "strong", [["style", "font-style: italic;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["JOBFLASH"])), (_l()(), i1.ɵted(-1, null, [" \u00FCber die Schulter schauen, um "])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "strong", [["style", "font-style: italic;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["JOBFLASH"])), (_l()(), i1.ɵted(-1, null, [" zu verbessern? "])), (_l()(), i1.ɵeld(28, 0, null, null, 4, "div", [["class", "jf-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 1, "div", [["class", "jf-btn jf-privacy"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showPrivacy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Datenschutz"])), (_l()(), i1.ɵeld(31, 0, null, null, 1, "div", [["class", "jf-btn jf-confirm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Speichern"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.anonymous_statistics; _ck(_v, 15, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 17).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 17).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 17).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 17).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 17).ngClassValid; var currVal_5 = i1.ɵnov(_v, 17).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 17).ngClassPending; _ck(_v, 12, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_CookieComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "jf-cookie", [], null, null, null, View_CookieComponent_0, RenderType_CookieComponent)), i1.ɵdid(1, 114688, null, 0, i3.CookieComponent, [i4.HeaderService, i5.MetaService, i6.AnalyticsService, i7.WINDOW], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CookieComponentNgFactory = i1.ɵccf("jf-cookie", i3.CookieComponent, View_CookieComponent_Host_0, {}, {}, []);
export { CookieComponentNgFactory as CookieComponentNgFactory };
