/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./increation.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./increation.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../api/interfaces/order.interface";
import * as i8 from "../../../service/order.service";
var styles_IncreationComponent = [i0.styles];
var RenderType_IncreationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IncreationComponent, data: {} });
export { RenderType_IncreationComponent as RenderType_IncreationComponent };
function View_IncreationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "jf-replay"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["replay"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
function View_IncreationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "jf-play"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["play_arrow"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
function View_IncreationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "jf-loading"]], null, null, null, null, null))], null, null); }
function View_IncreationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "jf-text-frame"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please fasten your seatbelts. We\u2019re flying. "])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Das Video wird gerade erstellt"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Ab jetzt geht nichts mehr verloren, auch, wenn du den Browser verl\u00E4sst. "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "jf-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showText = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Verstanden "]))], null, null); }
function View_IncreationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "jf-state jf-error-frame"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "jf-error-indicator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Es ist ein Fehler aufgetreten ..."])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Wir schauen uns das Problem an und melden uns bei dir. "]))], null, null); }
function View_IncreationComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "jf-state"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "jf-queue-loader"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" In der Warteschlange ... "]))], null, null); }
function View_IncreationComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "jf-state"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "jf-loader"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" Wird erstellt ... ", "%"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.simulatedProgress; _ck(_v, 2, 0, currVal_0); }); }
function View_IncreationComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncreationComponent_8)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncreationComponent_9)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inqueue; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.inqueue; _ck(_v, 4, 0, currVal_1); }, null); }
function View_IncreationComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "jf-progress"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { width: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_co.simulatedProgress + "%")); _ck(_v, 1, 0, currVal_0); }, null); }
function View_IncreationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "jf-text-panel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncreationComponent_5)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "jf-render-frame"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncreationComponent_6)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncreationComponent_7)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "jf-progress-frame"]], null, null, null, null, null)), i1.ɵdid(9, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { "jf-error": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncreationComponent_10)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showText; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.error; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.error; _ck(_v, 7, 0, currVal_2); var currVal_3 = "jf-progress-frame"; var currVal_4 = _ck(_v, 10, 0, _co.error); _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_5 = !_co.inqueue; _ck(_v, 12, 0, currVal_5); }, null); }
function View_IncreationComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "jf-next"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.forward() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Video ansehen "]))], null, null); }
export function View_IncreationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { video: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "jf-loading-frame"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "jf-video"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["video", 1]], null, 1, "video", [["autoplay", ""], ["loop", ""], ["playsinline", ""]], null, [[null, "load"], [null, "waiting"], [null, "playing"], [null, "pause"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } if (("waiting" === en)) {
        var pd_1 = ((_co.videoState = "waiting") !== false);
        ad = (pd_1 && ad);
    } if (("playing" === en)) {
        var pd_2 = ((_co.videoState = "playing") !== false);
        ad = (pd_2 && ad);
    } if (("pause" === en)) {
        var pd_3 = ((_co.videoState = "pause") !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.toggleVideo() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "source", [["src", "/assets/mood/loading.mp4"], ["type", "video/mp4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncreationComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncreationComponent_2)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncreationComponent_3)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncreationComponent_4)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncreationComponent_11)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.videoState === "ended"); _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.videoState === "pause"); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_co.videoState === "waiting"); _ck(_v, 10, 0, currVal_2); var currVal_3 = !_co.done; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.done; _ck(_v, 14, 0, currVal_4); }, null); }
export function View_IncreationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "jf-increation", [], [[2, "jf-slide", null]], null, null, View_IncreationComponent_0, RenderType_IncreationComponent)), i1.ɵdid(1, 114688, null, 0, i5.IncreationComponent, [i6.ActivatedRoute, i6.Router, i7.OrderInterface, i8.OrderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).slideClass; _ck(_v, 0, 0, currVal_0); }); }
var IncreationComponentNgFactory = i1.ɵccf("jf-increation", i5.IncreationComponent, View_IncreationComponent_Host_0, {}, {}, []);
export { IncreationComponentNgFactory as IncreationComponentNgFactory };
