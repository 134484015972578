import { QuestionslideComponent } from 'app/element/slide/questionslide/questionslide.component';
import { ContactdetailsComponent } from 'app/element/slide/contactdetails/contactdetails.component';
import { LogoselectComponent } from 'app/element/slide/logoselect/logoselect.component';
import { DesignselectComponent } from 'app/element/slide/designselect/designselect.component';
import { JobdetailsComponent } from 'app/element/slide/jobdetails/jobdetails.component';
import { SelfdescComponent } from 'app/element/slide/selfdesc/selfdesc.component';
import { IntroductionComponent } from 'app/element/slide/introduction/introduction.component';
import { LegalchecksComponent } from 'app/element/slide/legalchecks/legalchecks.component';
import { AlmostdoneComponent } from 'app/element/slide/almostdone/almostdone.component';
import { RecipientComponent } from 'app/element/slide/recipient/recipient.component';
import { IncreationComponent } from 'app/element/slide/increation/increation.component';
var ɵ0 = {
    meta: {
        title: 'JOBFLASH ist dein individuelles Recruitment Video − Die Recruiting-Revolution',
        desc: 'Erstelle dein eigenes Stellenangebot nur mit deinem Smartphone. Einfach, schnell & günstig. Mit Step-by-step Tutorials.',
    }
}, ɵ1 = {
    step_key: 'legalchecks',
    meta: {
        title: 'Videos sicher erstellen, sicher neue Mitarbeiter finden - JOBFLASH Datenschutz',
        desc: 'Datenschutz hat bei JOBFLASH Priorität. Sichere Daten, sichere Recruitment Videos.',
    },
}, ɵ2 = {
    video_number: 1,
    video: 'TT2.mp4',
    step_key: 'video_who_are_you',
    headline: 'Wer bist du?',
    text: 'Stelle dich und deinen Arbeitsplatz in wenigen Worten vor. Nur das und ganz kurz. Der Rest kommt gleich dazu…',
    meta: {
        title: 'Videos sicher erstellen, sicher neue Mitarbeiter finden - JOBFLASH Datenschutz',
        desc: 'Datenschutz hat bei JOBFLASH Priorität. Sichere Daten, sichere Recruitment Videos.',
    },
}, ɵ3 = {
    video_number: 2,
    meta: {
        title: 'Einfach neue Mitarbeiter finden - zeig im JOBFLASH-Video wer du bist!',
        desc: 'Stelle dich in einem kurzen Clip im JOBFLASH-Video vor. So lernt man dich gleich kennen ' +
            'und ihr spart viel Zeit. Schnelle Vertrauensbasis. '
    },
    video: 'TT3.mp4',
    step_key: 'video_which_position',
    headline: 'Welche Position?',
    text: 'Was genau wird die Position deines/deiner neuen Kollege/in sein und mit wem wird er/sie zusammen arbeiten?',
}, ɵ4 = {
    video_number: 3,
    meta: {
        title: 'Einfache Kommunikation im Recruitment. Welche Position suchst du? | JOBFLASH',
        desc: 'Welche vakante Position bzw. Stelle wird der/dem neuen MitarbeiterIn angeboten. Kurzes Video dazu - fertig.'
    },
    video: 'TT4.mp4',
    step_key: 'video_requirements',
    headline: 'Welche Skills?',
    text: 'Welche Fähigkeiten soll dein/e neue/r Arbeitskollege/in mitbringen?',
}, ɵ5 = {
    video_number: 4,
    meta: {
        title: 'Talente einfach finden - welche Skills sind notwenig?',
        desc: 'Welche Fähigkeiten und Skills soll die/der neue Mitarbeiter mitbringen? Was wird gefordert? Kurzes Video dazu - fertig.'
    },
    video: 'TT5.mp4',
    step_key: 'video_benefits',
    headline: 'Warum zu uns?',
    text: 'Warum sollte dein/e neue/r Kollege/in genau bei deinem Unternehmen arbeiten? Was sind die Besonderheiten deines Unternehmens?',
}, ɵ6 = {
    video_number: 5,
    meta: {
        title: 'Positionierung der Arbeitgebermarke richtig darstellen. Warum zu uns?',
        desc: 'Mehrwert für zukünftige/neue MitarbeiterInnen vorstellen. Zeige, was euch besonders macht. Nutze dazu das JOBFLASH-Video.'
    },
    video: 'TT6.mp4',
    step_key: 'video_contacts',
    headline: 'Wie erreicht man dich?',
    text: 'Wie kann der/die neue Kollege/in in Kontakt zu dir treten?',
}, ɵ7 = {
    video_number: 6,
    meta: {
        title: 'Erreichbarkeit ist für Bewerber das A & O. Wie erreicht man dich? | JOBFLASH',
        desc: 'Attraktive Kontaktaufnahme für neue ArbeitnehmerInnen. Sei einfach und überall erreichbar. Ob WhatsApp oder Email. Zeige es im Video.'
    },
    image: 'firstscene.jpg',
    step_key: 'video_intro',
    headline: 'Startsequenz',
    text: 'Erstelle die Startsequenz deines Videos. Z.B. ein kurzes „Hallo“ oder „Hey, heute bewerben WIR uns bei DIR.',
}, ɵ8 = {
    meta: {
        title: 'So schnell entsteht ein JOBFLASH-Video. Du bist fast fertig!',
        desc: 'Erstelle die Startsequenz deines Videos. Z.B. mit einer kurzen Begrüßung „Hallo“ oder „Hey, heute bewerben WIR und bei DIR.“'
    },
}, ɵ9 = {
    video_number: 7,
    meta: {
        title: 'Transparenz im Recruitment. Zeige dein Unternehmen! | JobFalsh',
        desc: 'Durch Transparenz Vertrauen und Nahbarkeit schaffen. Unternehmen zeigen und KandidatInnen eine Backstage-Führung geben.'
    },
    video: 'TT7.mp4',
    step_key: 'video_look_inside',
    headline: 'Stell dein Unternehmen vor',
    text: 'Wie sieht es bei euch aus? Wie sieht der Arbeitsplatz aus? Mit welchen Kollegen/innen arbeitet er/sie zusammen?',
}, ɵ10 = {
    meta: {
        title: 'Branding im Recruitment-Prozess einsetzen - Logo upload in JOBFLASH-Video',
        desc: 'Recruitment Video mit deinem Unternehmens-CI und deiner Arbeitgebermarke versehen. Einfach Logo integrieren.'
    },
    step_key: 'company_logo',
}, ɵ11 = {
    meta: {
        title: 'Corporate Identity (CI) im JOBFLASH-Video - die einfache Designauswahl',
        desc: 'Video-Stellenanzeige bzw. -Bewerbung mit einem passenden Design optimieren. Individualität auf Arbeitsmarkt.'
    },
    step_key: 'designselect',
}, ɵ12 = {
    meta: {
        title: 'Jobdiscription im JOBFLASH-Video - Details zum vakanten Job',
        desc: 'Einfache, stichpunktartige Beschreibung des Jobprofils eintragen. Im Video werden die Details automatisch integriert.'
    },
    step_key: 'jobdetails'
}, ɵ13 = {
    meta: {
        title: 'Eigenschaften von Bewerber einfach in JOBFLASH-Video integrieren',
        desc: 'Textliche Beschreibung der gewünschten Bewerbereigenschaften eintragen. Im Video werden' +
            'die Stichpunkte automatisch integriert.'
    },
    step_key: 'selfdesc'
}, ɵ14 = {
    meta: {
        title: 'Schnelle und einfach Kontaktaufnahme zum Arbeitgeber | Kontaktdaten',
        desc: 'Direkte und schnellstmögliche Bewerber-Kommunikation. Automatische Integration im JOBFLASH-Video.'
    },
    step_key: 'contactdetails'
}, ɵ15 = {
    meta: {
        title: 'Freigabe der JOBFLASH-Videos durch Vorgesetzte oder selbst | Video-Empfänger',
        desc: 'Recruitment Video schnell und einfach per Downloadlink an Vorgesetzte zur Freigabe senden lassen. Kein extra Versand nötig.'
    },
    step_key: 'recipient'
}, ɵ16 = {
    meta: {
        title: 'In 20 Minuten zum eigenen Recruitment Video - "JOBFLASH wird erstellt“',
        desc: 'Durch einfache und schnelle Erstellung der Stellenanzeige als Video mehr Zeit, Ressourcen & Budget sparen.'
    },
    step_key: 'increation'
};
var stepList = [{
        path: '',
        component: IntroductionComponent,
        data: ɵ0,
    }, {
        path: 'datenschutz',
        component: LegalchecksComponent,
        data: ɵ1,
    }, {
        path: 'wer-bist-du',
        component: QuestionslideComponent,
        data: ɵ2,
    }, {
        path: 'welche-position',
        component: QuestionslideComponent,
        data: ɵ3,
    }, {
        path: 'welche-skills',
        component: QuestionslideComponent,
        data: ɵ4,
    }, {
        path: 'warum-zu-uns',
        component: QuestionslideComponent,
        data: ɵ5,
    }, {
        path: 'wie-errecht-man-dich',
        component: QuestionslideComponent,
        data: ɵ6,
    }, {
        path: 'startsequenz',
        component: QuestionslideComponent,
        data: ɵ7,
    }, {
        path: 'fast-geschafft',
        component: AlmostdoneComponent,
        data: ɵ8,
    }, {
        path: 'zeige-dein-unternehmen',
        component: QuestionslideComponent,
        data: ɵ9,
    }, {
        path: 'fuege-dein-logo-hinzu',
        component: LogoselectComponent,
        data: ɵ10,
    }, {
        path: 'waehle-dein-design',
        component: DesignselectComponent,
        data: ɵ11,
    }, {
        path: 'details-zur-stelle',
        component: JobdetailsComponent,
        data: ɵ12,
    }, {
        path: 'was-suchst-du-was-bietest-du',
        component: SelfdescComponent,
        data: ɵ13,
    }, {
        path: 'kontaktperson',
        component: ContactdetailsComponent,
        data: ɵ14,
    }, {
        path: 'wer-soll-das-video-bekommen',
        component: RecipientComponent,
        data: ɵ15,
    }, {
        path: 'video-wird-erstellt',
        component: IncreationComponent,
        data: ɵ16,
    }];
stepList.forEach(function (step, n) {
    if (!step.data) {
        step.data = {};
    }
    var data = step.data;
    data.prevPath = '';
    if (n > 0) {
        data.prevPath = '/erstellen/' + stepList[n - 1].path;
    }
    data.nextPath = '';
    if (n < stepList.length - 1) {
        data.nextPath = '/erstellen/' + stepList[n + 1].path;
    }
    return step;
});
stepList.push({
    path: '**',
    redirectTo: stepList[0].path,
});
export var steps = stepList;
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16 };
