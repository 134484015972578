import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  HostListener, Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Output, PLATFORM_ID,
  ViewChild
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { DataInterface } from 'app/api/interfaces/data.interface'
import { throttle } from 'lodash'
import { MetaService } from 'app/service/meta.service'
import { Subscription } from 'rxjs'
import { skip } from 'rxjs/operators'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'jf-viewresult',
  templateUrl: './viewresult.component.html',
  styleUrls: ['./viewresult.component.sass']
})
export class ViewresultComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('video') video
  @Output() prev: EventEmitter<void> = new EventEmitter<void>()
  @Output() next: EventEmitter<void> = new EventEmitter<void>()

  public navData = {
    step: true,
    background: false,
    actions: '',
  }
  public videoData:  any
  public videoState = 'pause'
  public showLoading: boolean
  public showCookie: boolean
  public client_id: string
  public client: boolean
  public notFound: boolean
  private cookieSub: Subscription
  private reqSizeCheck = throttle(this.checkSize.bind(this), 250, {leading: false, trailing: true})
  public view = 'overview'
  @HostBinding('class.jf-slide') slideClass = true
  @HostBinding('class.jf-desktop') desktop
  @HostBinding('class.jf-loaded') initialized: boolean
  @HostBinding('class.jf-loading') get isLoading () {
    return !this.initialized
  }

  @HostListener('window:resize', ['$event'])
    onResize() {
      this.reqSizeCheck()
    }

  constructor (
    private route: ActivatedRoute,
    private metaService: MetaService,
    private dataInterface: DataInterface,
    private zone: NgZone,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.client = isPlatformBrowser(platformId)
    if (!this.client) {
      return
    }

    try {
      localStorage.removeItem('increation')
    } catch (e) {}

    const that = this
    that.route.paramMap.subscribe( params => {
      that.initialized = false
      that.notFound = false
      that.client_id = params.get('id')
      that.dataInterface.getResult(that.client_id, params.get('key'))
        .subscribe(data => {
          that.videoData = data
          that.initialized = true
        }, error => {
          that.notFound = true
        })
    })

    that.metaService.getCookieNoteVisible()
      .subscribe(show => that.showCookie = show)

    that.reqSizeCheck()

    setTimeout(() => {
      that.showLoading = true
    }, 1500)
  }

  private checkSize () {
    this.zone.run(() => {
      if (typeof window !== 'undefined') {
        this.desktop = window.innerWidth > 767
      }
    })
  }

  ngOnInit () {

  }

  ngOnDestroy () {
    if (this.cookieSub) {
      this.cookieSub.unsubscribe()
    }
  }

  ngAfterViewInit() {
    this.onLoad()
  }

  download () {
    const that = this
    const a = document.createElement('a')
    a.href = that.videoData.download_link
    a.download = 'JOBFLASH-Video.mp4'
    a.click()
  }

  onLoad () {
    const that = this
    const cookieOverlay = that.metaService.getCookieNoteVisible().value
    if (cookieOverlay && that.video) {
      that.video.nativeElement.autoplay = false
      that.video.nativeElement.pause()
      that.videoState = 'pause'

      that.cookieSub = that.metaService.getCookieNoteVisible()
        .pipe(skip(1))
        .subscribe(value => {
          if (!value) {
            that.cookieSub.unsubscribe()
            that.play()
          }
        })
    } else {
      that.play()
    }
  }

  play () {
    if (this.video) {
      this.video.nativeElement.play()
    }
  }

  setView (view: string) {
    this.view = view
  }

  unlock () {
    this.videoData.paid = true
    this.video.nativeElement.load()
    this.setView('overview')
  }

  toggleVideo () {
    switch (this.videoState) {
      case 'playing':
        this.video.nativeElement.pause()
        break
      case 'ended':
      case 'pause':
        this.video.nativeElement.play()
        break
      case 'waiting':
        break
      default:
        this.video.nativeElement.play()
    }
  }
}
