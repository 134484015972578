
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable()
export class OrderInterface {
  private readonly apiUrl = environment.apiUrl
  private headers: HttpHeaders

  constructor(private http: HttpClient) {
    const that = this

    that.headers = new HttpHeaders({
      'Content-Type':  'application/json',
    })
  }

  static pack (data) {
    return JSON.stringify(data)
  }

  initClient (client_id: string, increation: boolean) {
    const request = {
      client_id: client_id,
      increation: increation,
    }

    return this.http.post<any>(this.apiUrl + '/user/grab', OrderInterface.pack(request), { headers: this.headers })
  }

  saveStep (data: any) {
    return this.http.post<any>(this.apiUrl + '/step/save', OrderInterface.pack(data), { headers: this.headers })
  }

  saveVideo (data: any) {
    return this.http.post<any>(this.apiUrl + '/video/save', data)
  }

  saveImage (data: any) {
    return this.http.post<any>(this.apiUrl + '/image/save', data)
  }

  removeImage (data: any) {
    return this.http.post<any>(this.apiUrl + '/image/remove', OrderInterface.pack(data), { headers: this.headers })
  }

  placeOrder (data: any) {
    return this.http.post<any>(this.apiUrl + '/order/place', OrderInterface.pack(data), { headers: this.headers })
  }

  getProgress (data: any) {
    return this.http.post<any>(this.apiUrl + '/video/progress', OrderInterface.pack(data), { headers: this.headers })
  }
}
