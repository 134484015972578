

<div class="jf-loading-frame">
  <div class="jf-video">
    <video
      #video
      autoplay
      playsinline
      loop
      (load)="play()"
      (waiting)="videoState = 'waiting'"
      (playing)="videoState = 'playing'"
      (pause)="videoState = 'pause'"
      (click)="toggleVideo()"
    >
      <source src="/assets/mood/loading.mp4" type="video/mp4">
    </video>
    <div class="jf-replay" *ngIf="videoState === 'ended'" (click)="play()">
      <mat-icon>replay</mat-icon>
    </div>
    <div class="jf-play" *ngIf="videoState === 'pause'" (click)="play()">
      <mat-icon>play_arrow</mat-icon>
    </div>
    <div class="jf-loading" *ngIf="videoState === 'waiting'"></div>
  </div>

  <div class="jf-text-panel" *ngIf="!done">
    <div class="jf-text-frame" *ngIf="showText">
      <h2>
        Please fasten your seatbelts. We’re flying.
      </h2>

      <p>
        <strong>Das Video wird gerade erstellt</strong><br>
        Ab jetzt geht nichts mehr verloren, auch, wenn du den Browser verlässt.
      </p>

      <div class="jf-btn" (click)="showText = false">
        Verstanden
      </div>
    </div>

    <div class="jf-render-frame">
      <div class="jf-state jf-error-frame" *ngIf="error">
        <div class="jf-error-indicator"></div>
        <p>
          Es ist ein Fehler aufgetreten ...<br>
          Wir schauen uns das Problem an und melden uns bei dir.
        </p>
      </div>
      <ng-container *ngIf="!error">
        <div class="jf-state" *ngIf="inqueue">
          <div class="jf-queue-loader"></div>
          In der Warteschlange ...
        </div>
        <div class="jf-state" *ngIf="!inqueue">
          <div class="jf-loader"></div>
          Wird erstellt ... {{ simulatedProgress }}%<br>
        </div>
      </ng-container>

      <div class="jf-progress-frame" [ngClass]="{'jf-error': error}">
        <div
          class="jf-progress"
          [ngStyle]="{width: simulatedProgress + '%'}"
          *ngIf="!inqueue">
        </div>
      </div>
    </div>
  </div>

  <div class="jf-next" *ngIf="done" (click)="forward()">
    Video ansehen
  </div>
</div>

