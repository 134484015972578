
<div class="jf-content" [formGroup]="formData">
  <h2>Eigenschaften</h2>
  <p>
    Wer ist bei euch im Unternehmen für die Bewerbung zuständig?
    Wo kann sich dein neuer Kollege informieren?
  </p>

  <label>Ansprechperson</label>
  <jf-input type="text" placeholder="Lotte Leicht" [showErrors]="showErrors" [control]="formData.get('company_person')" [maxLength]="20" [validators]="validators.company_person"></jf-input>

  <label>E-Mail-Adresse</label>
  <jf-input type="email" placeholder="jobs@innovativefirma.com" [showErrors]="showErrors" [control]="formData.get('company_mail')" [validators]="validators.company_mail"></jf-input>

  <label>Telefonnummer</label>
  <jf-input type="tel" placeholder="0123456789" [showErrors]="showErrors" [control]="formData.get('company_phone')" [maxLength]="20" [validators]="validators.company_phone"></jf-input>

  <label>Webseite</label>
  <jf-input type="url" placeholder="www.innovativefirma.com" [showErrors]="showErrors" [control]="formData.get('company_website')" [validators]="validators.company_website" [lowercase]="true"></jf-input>
</div>

<jf-slidenav [loading]="saving" [disableNext]="!isValid()" [data]="navData" (prev)="back()" (next)="forward()"></jf-slidenav>
