import { Injectable, Inject, PLATFORM_ID } from '@angular/core'
import { environment } from 'environments/environment'
import { Router } from '@angular/router'
import { WINDOW } from 'app/service/window.service'
import { isPlatformBrowser } from '@angular/common'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class AnalyticsService {

  private dataLayer: any[]
  private active: boolean
  private enabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor (
    private router: Router,
    @Inject(WINDOW) public window,
    @Inject(PLATFORM_ID) private _platformId: Object,
  ) {
    this.dataLayer = this.window['dataLayer'] = []

    if (isPlatformBrowser(this._platformId)) {
      this.setState(this.window['localStorage'].getItem('analytical_cookies') === 'true')
    }

    this.enabled.subscribe(next => {
      if (next) {
        this.enable()
      }
    })
  }

  setState (state: boolean) {
    if (isPlatformBrowser(this._platformId)) {
      this.enabled.next(state)
      if (state) {
        this.window['localStorage'].setItem('analytical_cookies', 'true')
      } else {
        this.window['localStorage'].removeItem('analytical_cookies')
      }
    }
  }

  enable () {
    if (
      isPlatformBrowser(this._platformId)
      && environment.tagmanagerId
      && document
      && document.body
      && typeof document.body.appendChild === 'function'
    ) {
      this.active = true

      const gtmanager = document.createElement('script')

      gtmanager.innerHTML = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\': ' +
        'new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0], ' +
        'j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src= ' +
        '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f); ' +
        '})(window,document,\'script\',\'dataLayer\',\'' + environment.tagmanagerId + '\');'

      document.body.appendChild(gtmanager)
    }
  }

  getState () {
    return this.enabled
  }

  sendPageView () {
    let page = this.router.url
    if (!page.startsWith('/')) {
      page = `/${page}`
    }
    this.pushLayer({
      event: 'jfPageView',
    })
  }

  sendEvent (id: string) {
    this.pushLayer({'event': id})
  }

  addDataLayer (layer: any) {
    this.pushLayer(layer)
  }

  private pushLayer (layer) {
    this.dataLayer.push(layer)
  }
}
