
<div class="jf-content" [formGroup]="formData">
  <h2>
    Glückwunsch!
  </h2>
  <p>
    Du hast es geschafft.<br><br>
    Nun haben wir alles – es kann losgehen.
    Im nächsten Schritt wird dein Video gerendert.
    Gleich im Anschluss bekommst du einen Downloadlink via E-Mail zugeschickt.
  </p>

  <label>Name</label>
  <jf-input placeholder="Dein Name" type="text" [showErrors]="showErrors" [control]="formData.get('recipient_name')" [maxLength]="30" [validators]="validators.recipient_name"></jf-input>

  <label>E-Mail-Adresse</label>
  <jf-input placeholder="du@innovativefirma.com" type="email" [showErrors]="showErrors" [control]="formData.get('recipient_mail')" [validators]="validators.recipient_mail"></jf-input>
</div>

<jf-slidenav [loading]="saving" [disableNext]="!isValid()" [data]="navData" (prev)="back()" (next)="forward()"></jf-slidenav>

