
import { Component, OnInit, Inject, HostBinding } from '@angular/core'
import { MetaService } from 'app/service/meta.service'
import { WINDOW } from 'app/service/window.service'
import { trigger, state, style, transition, animate } from '@angular/animations'
import { HeaderService } from 'app/service/header.service'
import { AnalyticsService } from 'app/service/analytics.service'

@Component({
  selector: 'jf-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.sass'],
})
export class CookieComponent implements OnInit {
  public anonymous_statistics: boolean

  constructor (
    private headerService: HeaderService,
    private metaService: MetaService,
    private analyticsService: AnalyticsService,
    @Inject(WINDOW) public window,
  ) { }

  ngOnInit() {

  }

  hide () {
    this.metaService.setCookieNoteVisible(false)
    this.analyticsService.setState(this.anonymous_statistics)
  }

  showPrivacy () {
    this.headerService.setShow(true)
    this.headerService.setTab('privacy')
  }
}
