/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./input.component";
var styles_InputComponent = [i0.styles];
var RenderType_InputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputComponent, data: {} });
export { RenderType_InputComponent as RenderType_InputComponent };
function View_InputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["input", 1]], null, 7, "input", [], [[8, "type", 0], [8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "jf-invalid": 0, "jf-touched": 1, "jf-show-errors": 2, "jf-lowercase": 3 }), i1.ɵdid(3, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(5, 540672, null, 0, i3.FormControlDirective, [[8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlDirective]), i1.ɵdid(7, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = _ck(_v, 2, 0, !((_co.control == null) ? null : _co.control.valid), ((_co.control == null) ? null : _co.control.touched), _co.showErrors, _co.lowercase); _ck(_v, 1, 0, currVal_9); var currVal_10 = _co.control; _ck(_v, 5, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.placeholder, ""); var currVal_2 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 7).ngClassValid; var currVal_7 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_InputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0], ["textarea", 1]], null, 7, "textarea", [], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "jf-invalid": 0, "jf-touched": 1, "jf-show-errors": 2, "jf-lowercase": 3 }), i1.ɵdid(3, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(5, 540672, null, 0, i3.FormControlDirective, [[8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlDirective]), i1.ɵdid(7, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _ck(_v, 2, 0, !((_co.control == null) ? null : _co.control.valid), ((_co.control == null) ? null : _co.control.touched), _co.showErrors, _co.lowercase); _ck(_v, 1, 0, currVal_8); var currVal_9 = _co.control; _ck(_v, 5, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.placeholder, ""); var currVal_1 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 7).ngClassValid; var currVal_6 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_InputComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "jf-maxlength"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "jf-show": 0, "jf-invalid": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "jf-current"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "jf-limit"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" / ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "jf-maxlength"; var currVal_1 = _ck(_v, 2, 0, ((_co.maxLength - _co.inputLength) < 11), (_co.maxLength < _co.inputLength)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.inputLength; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.maxLength; _ck(_v, 6, 0, currVal_3); }); }
export function View_InputComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { input: 0 }), i1.ɵqud(671088640, 2, { textarea: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "jf-inputframe"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "jf-length-limit": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "jf-inputframe"; var currVal_1 = _ck(_v, 4, 0, _co.maxLength); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = (_co.type !== "textarea"); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.type === "textarea"); _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.maxLength; _ck(_v, 10, 0, currVal_4); }, null); }
export function View_InputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "jf-input", [], null, null, null, View_InputComponent_0, RenderType_InputComponent)), i1.ɵdid(1, 4440064, null, 0, i4.InputComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputComponentNgFactory = i1.ɵccf("jf-input", i4.InputComponent, View_InputComponent_Host_0, { control: "control", type: "type", showErrors: "showErrors", placeholder: "placeholder", maxLength: "maxLength", validators: "validators", lowercase: "lowercase" }, {}, []);
export { InputComponentNgFactory as InputComponentNgFactory };
