
<div class="jf-content">
  <div class="jf-inner">
    <div class="jf-slider">
      <img src="/assets/designs/phone.svg" width="230" height="460">

      <!--  [morePairSlides]="1" [angle]="0" [perspective]="0" [margin]="0" -->
      <div class="jf-glide">
        <div class="jf-track glide__track" data-glide-el="track">
          <ul class="jf-glide-slides glide__slides">
            <li class="jf-glide-slide glide__slide" *ngFor="let design of designs" [style.background-image]="'url(/assets/designs/' + design + '.jpg)'">
              <!--<video autoplay loop muted playsinline>-->
              <!--<source src="/assets/mood/videof.mp4" type="video/mp4">-->
              <!--</video>-->
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="jf-text">
      <h2>
        Welcher Look gefällt dir?
      </h2>

      <p>
        Welche Vorlage passt am besten zu deinem Unternehmen? Wähle eine der fünf Möglichkeiten.
      </p>
    </div>
  </div>


  <div class="jf-bar">
    <div class="jf-dots">
      <div class="jf-dot" *ngFor="let design of designs; let i = index" [ngClass]="{'jf-active': index === i}"></div>
    </div>

    <div class="jf-instruction">
      Wähle per Swipe
    </div>
  </div>
</div>

<jf-slidenav [loading]="saving" [data]="navData" (prev)="back()" (next)="forward()"></jf-slidenav>
