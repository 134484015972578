import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { OrderService } from 'app/service/order.service'
import { SlideComponent } from 'app/element/slide/slide.component'

@Component({
  selector: 'jf-logoselect',
  templateUrl: './logoselect.component.html',
  styleUrls: ['./logoselect.component.sass']
})
export class LogoselectComponent extends SlideComponent implements OnInit, OnDestroy {

  @ViewChild('input') input

  public uploading: boolean
  public validLogo: boolean
  public logoData: any
  public logoFile: any
  public keep: boolean
  public currRotation
  public navData = {
    step: true,
    background: false,
    actions: 'request_fillin',
  }
  private rotation = {
    1: 'rotate(0deg)',
    3: 'rotate(180deg)',
    6: 'rotate(90deg)',
    8: 'rotate(270deg)',
  }

  @HostBinding('class.jf-slide') slideClass = true

  constructor (
    public route: ActivatedRoute,
    public router: Router,
    private orderService: OrderService,
  ) {
    super(route, router)
  }

  ngOnInit () {
    const current = this.orderService.getFormGroup().get('company_logo').value

    if (current) {
      this.logoData = current
      this.validLogo = true
      this.keep = true
    }
  }

  ngOnDestroy () {

  }

  check () {
    const that = this
    if (!that.input) {return}
    that.logoData = undefined
    that.logoFile = undefined
    that.keep = false

    const file = that.input.nativeElement.files[0]

    if (['image/png', 'image/jpeg', 'image/gif'].indexOf(file.type) === -1) {
      alert('Bitte lade eine Bilddatei hoch (keine ' + file.type + ')')
      that.validLogo = false
      return
    }

    that.preview(file)

    that.logoFile = file
    that.validLogo = true
  }

  clear (evt) {
    this.logoData = undefined
    this.keep = false
    this.validLogo = false
    evt.stopPropagation()
  }

  preview (image) {
    const that = this
    if (!image) {
      return
    }
    const reader = new FileReader()

    reader.readAsDataURL(image)
    reader.onload = () => {
      that.logoData = reader.result
    }

    that.show(image)
  }

  show (image) {
    const that = this
    that.orientation(image, function(base64img, value) {
      if (value) {
        that.currRotation = that.rotation[value]
      }
    })
  }

  upload () {
    const that = this
    that.uploading = true

    that.uploading = true
    that.orderService.submitImage(that.data.step_key, this.logoFile)
      .then(resp => {
        that.uploading = false
        that.orderService.getFormGroup().patchValue({'company_logo': resp.path})
        that.switchNext()
      })
      .catch(() => {
        that.uploading = false
        console.error('file upload error')
      })
  }

  remove () {
    const that = this
    that.uploading = true
    that.orderService.removeImage('company_logo')
      .then(resp => {
        that.uploading = false
        that.orderService.getFormGroup().patchValue({'company_logo': undefined})
        that.switchNext()
      })
      .catch(() => {
        that.uploading = false
        console.error('file upload error')
      })
  }

  back () {
    this.switchPrev()
  }

  forward () {
    if (this.keep) {
      this.switchNext()
    } else if (!this.validLogo) {
      this.remove()
    } else {
      this.upload()
    }
  }

  reqUpload () {
    this.input.nativeElement.click()
  }

  _arrayBufferToBase64 ( buffer ) {
    let binary = ''
    const bytes = new Uint8Array( buffer )
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] )
    }
    return window.btoa( binary )
  }

  orientation (file, callback) {
    const that = this
    const fileReader = new FileReader()
    fileReader.onloadend = function() {
      const base64img = 'data:' + file.type + 'base64,' + that._arrayBufferToBase64(fileReader.result)
      const scanner = new DataView(fileReader.result as ArrayBuffer)
      let idx = 0
      let value = 1 // Non-rotated is the default
      if (fileReader.result['length'] < 2 || scanner.getUint16(idx) !== 0xFFD8) {
        if (callback) {
          callback(base64img, value)
        }
        return
      }
      idx += 2
      let maxBytes = scanner.byteLength
      while (idx < maxBytes - 2) {
        const uint16 = scanner.getUint16(idx)
        idx += 2
        switch (uint16) {
          case 0xFFE1:
            const exifLength = scanner.getUint16(idx)
            maxBytes = exifLength - idx
            idx += 2
            break
          case 0x0112:
            value = scanner.getUint16(idx + 6, false)
            maxBytes = 0
            break
        }
      }
      if (callback) {
        callback(base64img, value)
      }
    }
    fileReader.readAsArrayBuffer(file)
  }
}
