
import { ActivatedRoute, Router } from '@angular/router'
import { OrderService } from 'app/service/order.service'

export abstract class SlideComponent  {

  public data: any

  constructor (
    public route: ActivatedRoute,
    public router: Router,
  ) {
    this.data = route.snapshot.data
  }

  switchPrev () {
    if (this.data.prevPath) {
      this.router.navigate([this.data.prevPath])
    }
  }

  switchNext () {
    if (this.data.nextPath) {
      this.router.navigate([this.data.nextPath])
    }
  }
}
