import { Component, HostBinding, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { SlideComponent } from 'app/element/slide/slide.component'

@Component({
  selector: 'jf-almostdone',
  templateUrl: './almostdone.component.html',
  styleUrls: ['./almostdone.component.sass']
})
export class AlmostdoneComponent extends SlideComponent implements OnInit {
  public navData = {
    step: true,
    background: false,
    actions: 'laststep',
  }

  @HostBinding('class.jf-slide') slideClass = true

  constructor (
    public route: ActivatedRoute,
    public router: Router
  ) {
    super(route, router)
  }

  ngOnInit() {

  }

  back () {
    this.switchPrev()
  }

  forward () {
    this.switchNext()
  }
}
