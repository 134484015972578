
import { Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation, } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { JFValidators } from 'app/static/validators'
import { DataInterface } from 'app/api/interfaces/data.interface'
import { HeaderService } from 'app/service/header.service'
import { Router } from '@angular/router'
import { priceList } from 'app/static/prices'

@Component({
  selector: 'jf-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class IndexComponent implements OnInit, OnDestroy {
  public formData = new FormGroup({
    name: new FormControl(''),
    mail: new FormControl(''),
    message: new FormControl(''),
    phone: new FormControl(''),
    topic: new FormControl('', Validators.required),
  })

  public validators = {
    name: [Validators.required, JFValidators.person],
    mail: [Validators.required, JFValidators.email],
    phone: [JFValidators.phone],
    message: [Validators.required],
  }

  public sending
  public send
  public showErrors

  public packages = priceList

  constructor (
    private headerService: HeaderService,
    private dataInterface: DataInterface,
    private router: Router,
  ) {
    if (typeof window !== 'undefined') {
      window.document.body.classList.add('port-index')
    }
  }

  submit () {
    this.showErrors = true
    if (!this.formData.valid || this.sending) {return}

    this.sending = true
    this.dataInterface.submitContact(this.formData.value)
      .subscribe(() => {
        this.sending = false
        this.send = true
        this.formData.reset()
        this.formData.patchValue({topic: ''})
      }, () => {
        this.sending = false
        // TODO error handling
      })
  }

  showPage (page: string) {
    this.headerService.setShow(true)
    this.headerService.setTab(page)
  }

  ngOnInit () {
    if (
      (typeof window === 'undefined')
      || (typeof window.innerWidth === 'undefined')
      || window.innerWidth > 840
      || window.innerHeight > 840
    ) {
      // stay here
    } else {
      this.router.navigate(['/erstellen'])
    }
  }

  ngOnDestroy () {
    if (typeof window !== 'undefined') {
      window.document.body.classList.remove('port-index')
    }
  }
}
