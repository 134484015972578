var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { SlideComponent } from 'app/element/slide/slide.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { OrderService } from 'app/service/order.service';
import { AnalyticsService } from 'app/service/analytics.service';
import { MetaService } from 'app/service/meta.service';
var LegalchecksComponent = /** @class */ (function (_super) {
    __extends(LegalchecksComponent, _super);
    function LegalchecksComponent(route, router, analytics, metaService, orderService) {
        var _this = _super.call(this, route, router) || this;
        _this.route = route;
        _this.router = router;
        _this.analytics = analytics;
        _this.metaService = metaService;
        _this.orderService = orderService;
        _this.navData = {
            step: true,
            background: false,
            actions: 'request_fillin',
        };
        _this.slideClass = true;
        var formGroup = _this.orderService.getFormGroup();
        _this.formData = new FormGroup({
            anonymous_statistics: new FormControl(formGroup.get('anonymous_statistics').value, []),
        });
        _this.inital = _this.formData.value;
        return _this;
    }
    LegalchecksComponent.prototype.ngOnInit = function () {
    };
    LegalchecksComponent.prototype.back = function () {
        this.switchPrev();
    };
    LegalchecksComponent.prototype.isValid = function () {
        return this.formData.valid;
    };
    LegalchecksComponent.prototype.submit = function (data) {
        return this.orderService.submitStep('legalchecks', data);
    };
    LegalchecksComponent.prototype.forward = function () {
        var that = this;
        var data = that.formData.value;
        if (that.isValid()) {
            that.analytics.setState(this.formData.get('anonymous_statistics').value);
            that.metaService.setCookieNoteVisible(false);
            if (JSON.stringify(that.inital) === JSON.stringify(that.formData.value)) {
                that.switchNext();
                return;
            }
            that.saving = true;
            that.submit(data)
                .then(function () {
                that.saving = false;
                var formGroup = that.orderService.getFormGroup();
                formGroup.patchValue(data);
                that.switchNext();
            })
                .catch(function () {
                that.saving = false;
                alert('error');
            });
        }
    };
    return LegalchecksComponent;
}(SlideComponent));
export { LegalchecksComponent };
