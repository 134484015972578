import { Component, HostBinding, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { OrderService } from 'app/service/order.service'
import { SlideComponent } from 'app/element/slide/slide.component'
import { JFValidators } from 'app/static/validators'

@Component({
  selector: 'jf-selfdesc',
  templateUrl: './selfdesc.component.html',
  styleUrls: ['./selfdesc.component.sass']
})
export class SelfdescComponent extends SlideComponent implements OnInit {
  public formData: FormGroup
  public validators: any
  public saving: boolean
  public showErrors = false
  @HostBinding('class.jf-slide') slideClass = true

  public navData = {
    step: true,
    background: false,
    actions: 'request_fillin',
  }
  private inital: any

  constructor (
    public route: ActivatedRoute,
    public router: Router,
    public orderService: OrderService,
  ) {
    super(route, router)

    const formGroup = this.orderService.getFormGroup()
    this.formData = new FormGroup({
      job_benefits_1: new FormControl(formGroup.get('job_benefits_1').value, [Validators.required]),
      job_benefits_2: new FormControl(formGroup.get('job_benefits_2').value, [Validators.required]),
      job_benefits_3: new FormControl(formGroup.get('job_benefits_3').value, [Validators.required]),
      wanted_attributes_1: new FormControl(formGroup.get('wanted_attributes_1').value, [Validators.required]),
      wanted_attributes_2: new FormControl(formGroup.get('wanted_attributes_2').value, [Validators.required]),
      wanted_attributes_3: new FormControl(formGroup.get('wanted_attributes_3').value, [Validators.required]),
      company_name: new FormControl(formGroup.get('company_name').value),
    })

    this.validators = {
      job_benefits_1: [Validators.required, JFValidators.benefit],
      job_benefits_2: [Validators.required, JFValidators.benefit],
      job_benefits_3: [Validators.required, JFValidators.benefit],
      wanted_attributes_1: [Validators.required, JFValidators.attribute],
      wanted_attributes_2: [Validators.required, JFValidators.attribute],
      wanted_attributes_3: [Validators.required, JFValidators.attribute],
      company_name: [Validators.required, JFValidators.company],
    }

    this.inital = this.formData.value
  }

  ngOnInit() {

  }

  back () {
    this.switchPrev()
  }

  isValid () {
    return this.formData.valid
  }

  submit (data: any) {
    return this.orderService.submitStep(this.data.step_key, data)
  }

  forward () {
    const that = this
    const data = that.formData.value

    if (that.isValid()) {
      if (JSON.stringify(that.inital) === JSON.stringify(that.formData.value) ) {
        that.switchNext()
        return
      }

      that.saving = true
      that.submit(data)
        .then(() => {
          that.saving = false

          const formGroup = that.orderService.getFormGroup()
          formGroup.patchValue(data)

          that.switchNext()
        })
        .catch(() => {
          that.saving = false
          alert('error')
        })
    } else {
      that.showErrors = true
    }
  }
}
