
export const extend = function(...args: any[]) {
  const extended = {}

  for (const key of Object.keys(arguments)) {
    const argument = arguments[key]
    if (argument) {
      for (const prop of Object.keys(argument)) {
        if (Object.prototype.hasOwnProperty.call(argument, prop)) {
          extended[prop] = argument[prop]
        }
      }
    }
  }

  return extended
}

export const copyToClipboard = str => {
  navigator.userAgent.toLowerCase().indexOf('safari/') > -1 && navigator.userAgent.indexOf('Chrome') === -1
  ? copyToClipboardiOS(str)
  : copyToClipboardDefault(str)
}

export const copyToClipboardDefault = str => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.opacity = '0.1'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const copyToClipboardiOS = str => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.opacity = '0.1'
  el.style.left = '-9999px'
  document.body.appendChild(el)

  const oldContentEditable = el.contentEditable,
    oldReadOnly = el.readOnly,
    range = document.createRange()

  // @ts-ignore
  el.contentEditable = true
  el.readOnly = false
  range.selectNodeContents(el)

  const s = window.getSelection()
  s.removeAllRanges()
  s.addRange(range)

  el.setSelectionRange(0, 999999)

  el.contentEditable = oldContentEditable
  el.readOnly = oldReadOnly

  document.execCommand('copy')
  document.body.removeChild(el)
}
