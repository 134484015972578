
<div class="jf-content" [formGroup]="formData">
  <h2>
    Datenschutz
  </h2>
  <p class="jf-subline">
    Es ist deine Wahl, welche Daten du mit uns teilst.
  </p>

  <label class="jf-checkbox">
    <input type="checkbox" value="1" checked disabled>
    <span class="jf-checkbox-content">
      Cookies <span class="jf-required"></span>

      <small>
        Zur Erleichterung und besseren Zuordnung
        brauchen wir ein paar Cookies.
      </small>
    </span>
  </label>

  <label class="jf-checkbox">
    <input type="checkbox" value="1" formControlName="anonymous_statistics">
    <span class="jf-checkbox-content">
      Anonyme Datensammlung
      <small>
        Dürfen wir dir beim Erstellen deines Videos
        über die Schulter schauen, um es dir in Zukunft noch leichter zu machen?
      </small>
    </span>
  </label>
</div>

<jf-slidenav [loading]="saving" [disableNext]="!isValid()" [data]="navData" (prev)="back()" (next)="forward()"></jf-slidenav>
