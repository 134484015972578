/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./almostdone.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../slidenav/slidenav.component.ngfactory";
import * as i3 from "../../slidenav/slidenav.component";
import * as i4 from "./almostdone.component";
import * as i5 from "@angular/router";
var styles_AlmostdoneComponent = [i0.styles];
var RenderType_AlmostdoneComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlmostdoneComponent, data: {} });
export { RenderType_AlmostdoneComponent as RenderType_AlmostdoneComponent };
export function View_AlmostdoneComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "jf-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Fast geschafft!"], ["src", "/assets/icon/almost-done.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Du bist fast fertig! "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Jetzt gib der/dem \u201ENeuen\u201C schon mal einen kleien Einblick in dein Unternehmen. "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "jf-slidenav", [], [[8, "className", 0]], [[null, "prev"], [null, "next"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("prev" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } if (("next" === en)) {
        var pd_1 = (_co.forward() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SlidenavComponent_0, i2.RenderType_SlidenavComponent)), i1.ɵdid(8, 114688, null, 0, i3.SlidenavComponent, [], { data: [0, "data"] }, { prev: "prev", next: "next" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.navData; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).classes; _ck(_v, 7, 0, currVal_0); }); }
export function View_AlmostdoneComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "jf-almostdone", [], [[2, "jf-slide", null]], null, null, View_AlmostdoneComponent_0, RenderType_AlmostdoneComponent)), i1.ɵdid(1, 114688, null, 0, i4.AlmostdoneComponent, [i5.ActivatedRoute, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).slideClass; _ck(_v, 0, 0, currVal_0); }); }
var AlmostdoneComponentNgFactory = i1.ɵccf("jf-almostdone", i4.AlmostdoneComponent, View_AlmostdoneComponent_Host_0, {}, {}, []);
export { AlmostdoneComponentNgFactory as AlmostdoneComponentNgFactory };
